import { Button, createStyles, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { SupplierCapacityHeader } from '../../components/dashboard/supplierCapacityHeader';
import { CircularProgress } from '../../components/reusable/circularProgress';
import { SpeedDialComponent as SpeedDial } from '../../components/reusable/speedDial';
import { UploadFileButton } from '../../components/reusable/uploadFileButton';
import { FileParameter } from '../../services/generated/ApiClientGenerated';
import { useAppData } from '../../services/hooks';
import { PartDetailWalkthrough as Walkthrough } from './walkthrough';
import { PartDetailFilterModule } from './partDetailFilterModule';
import { downloadPartDetailCSVAction, uploadPartDetailCSVAction } from './partDetailSlice';
import { PartDetailTable } from './partDetailTable';

export const PartDetailsPage: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useAppData((s) => s.user);
    const partDetailData = useAppData((r) => r.partDetail.partDetails);
    const filters = useAppData((r) => r.partDetail.filters);
    const isFileLoading = useAppData((r) => r.partDetail.file.loading);
    const isDownloadLoading = useAppData((r) => r.partDetail.fileDownload.loading);
    const header = 'Supplier Demand & Capacity Planning';

    const onClickDownload = () => {
        dispatch(
            downloadPartDetailCSVAction({
                supplierId: filters.supplierIds![0],
            }),
        );
    };

    const onClickUpload = (file: FileParameter) => {
        dispatch(uploadPartDetailCSVAction(file));
    };


    return (
        <>
            <Walkthrough />
            <Grid container direction={'column'}>
                <Grid item className={classes.headerButtonContainer}>
                    <Grid item className={classes.gridHeader}>
                        <SupplierCapacityHeader header={header} />
                    </Grid>
                    <Grid item className={classes.buttonWrapper}>
                        <Grid item className={classes.buttonContainer}>
                            <Button
                                className={classes.button}
                                data-tut={'reactour__download-Button'}
                                variant="contained"
                                color="primary"
                                disabled={(filters.supplierIds?.length ?? 0) == 0}
                                onClick={onClickDownload}
                            >
                                {isDownloadLoading ? <CircularProgress color={'inherit'} size={'1.5rem'} /> : 'Download'}
                            </Button>
                        </Grid>
                        <Grid item className={classes.buttonContainer}>
                            <UploadFileButton
                                className={classes.button}
                                data-tut={'reactour__upload-Button'}
                                label=""
                                acceptValues={['.csv', '.xlsx']}
                                disabled={(filters.supplierIds?.length ?? 0) == 0}
                                onChangeFile={onClickUpload}
                                filters={filters}
                            >
                                {isFileLoading ? <CircularProgress color={'inherit'} size={'1.5rem'} /> : 'Upload'}
                            </UploadFileButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                data-tut={'reactour__print-Pdf-Button'}
                                aria-label={'print'}
                                color={'primary'}
                                onClick={() => window.print()}
                            >
                                <PrintIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={'row'}>
                <Grid className={classes.filterContainer} item xs={12} md={3} lg={2}>
                    <PartDetailFilterModule />
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    {partDetailData.loading ? (
                        <Grid container direction={'row'} className={classes.loadingContainer} justifyContent={'center'}>
                            <Grid item className={classes.circularProgress}>
                                <CircularProgress color={'primary'} />
                            </Grid>
                            <Grid item>
                                <PartDetailTable partDetailData={partDetailData.value} />
                            </Grid>
                        </Grid>
                    ) : (
                        <PartDetailTable partDetailData={partDetailData.value} />
                    )}
                </Grid>
            </Grid>
            <Grid container justifyContent={'flex-end'} className={classes.linkContainer}>
                <SpeedDial className={classes.fab} partDetail />
            </Grid>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            minWidth: '7.188rem',
        },
        buttonContainer: {
            padding: theme.spacing(0, 0.5),
        },
        buttonWrapper: {
            alignItems: 'center',
            display: 'flex',
        },
        headerButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(1),
        },
        fab: {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        },
        linkContainer: {
            marginTop: theme.spacing(1),
        },
        loadingContainer: {
            width: 'inherit',
        },
        loadingIconContainer: {
            textAlign: 'center',
        },
        circularProgress: {
            zIndex: 9,
            position: 'absolute',
            margin: theme.spacing(12, 0),
            display: 'flex',
        },
        gridHeader: {
            padding: theme.spacing(0),
        },
        filterContainer: {
            marginBottom: theme.spacing(2),
        },
        [`@media print`]: {
            buttonWrapper: {
                display: 'none',
            },
            filterContainer: {
                display: 'none',
            },
        },
    }),
);
