import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

const PercentageFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  // ensures that null or infinity value isn't displayed in grid
  if (value == Infinity) {
    return <></>;
  }
  if (value) {
    return <>{(value * 100).toFixed(1)}%</>;
  } else return <></>;
};

export const PercentageTypeProvider: FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={PercentageFormatter} {...props} />
);
