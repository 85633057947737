import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

type Props = {
  header: string;
};

export const SupplierCapacityHeader: FC<Props> = (props) => {
  const { header } = props;

  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Grid item>
        <Typography variant="h4" component="h1">
          {header}
        </Typography>
      </Grid>
    </Grid>
  );
};
