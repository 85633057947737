import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Tour, { ReactourStep } from 'reactour';
import { useAppData } from '../../services/hooks';
import { isPartDetailTourOpen, setExpandedPartDetailRowIdsAction } from './partDetailSlice';
import { getRowId } from './partDetailTable';

export const PartDetailWalkthrough: FC = () => {
  const dispatch = useDispatch();
  const [rowIdToExpand, setRowIdToExpand] = useState('');
  const isTourOpen = useAppData((r) => r.partDetail.isPartDetailTourOpen);
  const rows = useAppData((r) => r.partDetail.partDetails.value);
  useEffect(() => {
    if (rows && rows.length > 0) setRowIdToExpand(getRowId(rows[0]));
    else setRowIdToExpand('');
  }, [isTourOpen]);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const bodyScrollLock = require('body-scroll-lock');
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  const closeTour = () => {
    dispatch(isPartDetailTourOpen(false));
  };

  const steps: ReactourStep[] = [
    {
      selector: '[data-tut=reactour__filter-Module]',
      content:
        'You can filter by part numbers, key constraints, and mitigation measures or use the toggle to narrow by parts which have monthly variability.',
    },
    {
      selector: '[data-tut=reactour__keyConstraints]',
      content: 'Narrow to parts which have similar constraints.',
    },
    {
      selector: '[data-tut=reactour__mitigationMeasures]',
      content: 'Narrow to parts which have similar mitigation measures in place.',
    },
    {
      selector: '[data-tut=reactour__hasMonthlyVariability]',
      content: 'Narrow by parts that have a monthly variability.',
    },
    {
      selector: '[data-tut=reactour__annualCapacity]',
      content:
        'To sort a column by ascending or descending, click the column name, then click the up or down arrow that appears.',
    },
    {
      selector: '[data-tut=reactour__download-Button]',
      content: () => (
        <div>
          <Typography>
            Click <strong>Download</strong> to receive a CSV file.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut=reactour__upload-Button]',
      content: () => (
        <div>
          <Typography>
            Click <strong>Upload</strong> to upload the file with your data.
          </Typography>
        </div>
      ),
    },
    {
      selector: '[data-tut=reactour__add-Note]',
      content: () => (
        <div>
          <Typography>
            To add contextual information for Polaris, click the arrow to the left of the row to reveal the{' '}
            <strong>Add Note</strong> button. Enter your information then click <strong>Save</strong>. (If there is
            already an existing note, click the pencil icon to edit it.)
          </Typography>
        </div>
      ),
      action: () => {
        dispatch(setExpandedPartDetailRowIdsAction([rowIdToExpand]));
      },
    },
    {
      selector: '[data-tut=reactour__last-Updated]',
      content: 'Information about the last time the row was updated is displayed here.',
    },
    {
      selector: '[data-tut=reactour__annualCapacity]',
      content:
        'For columns where the purpose may not be immediately clear, you can hover over the column name to reveal rollover text containing a description.',
      action: () => {
        dispatch(setExpandedPartDetailRowIdsAction([]));
      },
    },
    {
      selector: '[data-tut=reactour__help-Menu]',
      content:
        'If at any time you require assistance, either with how to use the tool or questions about the data you are entering, use this button to be led to the appropriate help.',
    },
  ];

  return (
    <Tour
      badgeContent={(curr: number, tot: number) => `${curr} of ${tot}`}
      steps={steps}
      rounded={5}
      isOpen={isTourOpen}
      onRequestClose={closeTour}
      accentColor={'#004e97'}
      prevButton={<ArrowBackIcon aria-label={'go to previous step'} />}
      nextButton={<ArrowForwardIcon aria-label={'go to next step'} />}
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
    />
  );
};
