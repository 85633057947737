import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

const keyFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value, row }) => {
  return <Typography>{row.keyConstraints.length > 0 ? 'YES' : 'NO'} </Typography>;
};
export const KeyTypeProvider: FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={keyFormatter} {...props} />
);
