import React, { FC, useEffect, useState } from 'react';
import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography, TextField, } from '@material-ui/core';
import { CircularProgress } from '../components/reusable/circularProgress';
import { useAppData } from '../services/hooks';
import { useDispatch } from 'react-redux';
import { SupplierRecoveryCommentDialog } from './supplierRecoveryCommentDialog';
import { supplierActionRegister } from '../services/generated/ApiClientGenerated';
import { SupplierRecoveryLastUpdated } from './supplierRecoveryLastUpdated';
import Edit from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Comment from '@material-ui/icons/Comment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
    updateSupplierRecoveryAction,
} from './supplierRecoverySlice';


const productionList = [
     'Production' ,
     'Service' ,
     'Shared' ,
];

const lRWImpactLISt = [
    'Line Down' ,
     'Loose Crate',
];


const partCriticalityList = [
    'Extreme',
     'High',
    'Medium',
     'Low',
];
const currentNeededList = [
    'No',
    'Yes',
];

const shippingToMRPlist = [
    'No',
    'Yes',
];


const recommendedList = [
    'Move Part Out Of Supplier',
    'New/ Redundant Tooling (Capacity)',
    'Design or Manufacturing Process Change',
    'Demand Signal Improvments/Changes',
    'Supplemental Labor',
    'Commercial (AP, Allocation, Supplier Willingness)',
    'Process Training/Improvement ',
    'Undefined'

];

const countermeasureList = [
    'Implementation Team',
    'Category Sourcing',
    'Supplier Quality/Engineering',
    'MP&L (Demand Signal Kaizen)',
    'Business Units',
    'Category Sourcing',
    'Supply Chain Systems'
   
];

const rootOptionList = [
    'No Issues; Component Stable',
    'Labor Avalibility',
    'Logistics ',
    'Training/Knowledge',
    'Tooled Capacity',
    'Commercial/Relationship',
    'Demand Signal Varability',
    'Quality/Capability',
    'Sub-Tier Supply',
     'Undefined'
];




export const SupplierRecoveryRow: FC<{ row: supplierActionRegister }> = ({ row }) => {


    const classes = useStyles();
    const dispatch = useDispatch();
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const loading = (false);
    const createTagHandler = (tagType: 'partCriticality' | 'shippingToMRP' | 'production' | 'lrwImpact' | 'currentlyNeededForLRW' | 'qtyRequiredForLRW' | 'rootCause' | 'recommendedCounterMeasure' | 'counterMeasureOwner' | 'estCostChange' | 'anticipatedMRPRecoveryDate' ) => (value: any) => {
        const {icon,...rowdata} =row
        dispatch(
            updateSupplierRecoveryAction({
                baseSupplierId: row.supplierId!,
                partNumber: row.partNumber!,
                body: {
                    ...rowdata,
                    [tagType]: value,
                },
            }),
        );

    };

    const handleProduction = createTagHandler('production');
    const handlePartCriticality = createTagHandler('partCriticality');
    const handleShippingToMRP = createTagHandler('shippingToMRP');
    const handleLRWImpact = createTagHandler('lrwImpact');
    const handleCurrentlyNeededForLRW = createTagHandler('currentlyNeededForLRW');
    const handleEqtyRequiredForLRW = createTagHandler('qtyRequiredForLRW');
    const handleRootCause = createTagHandler('rootCause');
    const handleRecommendedCounterMeasure = createTagHandler('recommendedCounterMeasure');
    const handleCounterMeasureOwner = createTagHandler('counterMeasureOwner');
    const handleEstCostChange = createTagHandler('estCostChange');
    const handleAnticipatedMRPRecoveryDate = createTagHandler('anticipatedMRPRecoveryDate');

    const anticipatedMRPRecoveryDateDate = (row.anticipatedMRPRecoveryDate);
    function formatDate(date: any) {
        const d = new Date(date)
         let  month = '' + (d.getMonth() + 1)
          let  day = '' + d.getDate()
         const  year = d.getFullYear()

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
             day = '0' + day;

        return [year, month, day].join('-');
    }
    const anticipatedFomateDate = formatDate(anticipatedMRPRecoveryDateDate);

  return (
      <Grid container direction={'row'} spacing={4} justifyContent={'center'} className={classes.commentsContainer}>
          <Grid container spacing={isSmall ? 0 : 4}>
              <Grid item xs={12}  lg={6} >
                  <Autocomplete
                      id="production"
                      value={row.production}
                      clearOnBlur
                      fullWidth
                      options={productionList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} label="Production / Service / Shared" variant="outlined" className={classes.textField} />}
                      onChange={(event, option) => {
                          handleProduction(option as string);
                      }}
                        
                      
                  />
                  <Grid container spacing={isSmall ? 0 : 2} className={classes.breakSpacing} >
                      <Grid item xs={12} lg={6}>
                          <Autocomplete
                              value={row.partCriticality}

                              id="partCriticality"
                              options={partCriticalityList}
                              getOptionLabel={(option) => option }
                              renderInput={(params) => <TextField {...params} label="Part Criticality" variant="outlined" className={classes.textField} />}
                              onChange={(event, option) => {
                                  handlePartCriticality( option as string);
                              }}
                          />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                          <Autocomplete
                           value={row.shippingToMRP==='true'? "Yes":"No"}
                              id="shippingToMRP"
                              options={shippingToMRPlist}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Shipping to MRP" variant="outlined" className={classes.textField} />}
                              onChange={(event, option) => {
                                  handleShippingToMRP((option==="No"?"false":"true") as string);
                              }}
                          />
                      </Grid>

                  </Grid>
              </Grid>
              <Grid item xs={12}  lg={6}>
                  <Autocomplete
                      value={row.lrwImpact}
                      id="lrwImpact"
                      options={lRWImpactLISt}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} label="LRW Impact" variant="outlined" className={classes.textField} />}
                      onChange={(event, option) => {
                          handleLRWImpact(option as string);
                      }}
                  />
                  <Grid container spacing={isSmall ? 0 : 2}>
                      <Grid item xs={12} lg={6}>
                          <Autocomplete
                              value={row.currentlyNeededForLRW === 'true' ? "Yes" : "No"}

                              id="currentlyNeededForLRW"
                              options={currentNeededList}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Currently Needed for LRW" variant="outlined" className={classes.textField} />}
                              onChange={(event, option) => {
                                  handleCurrentlyNeededForLRW((option === "No" ? "false" : "true") as string);
                              }}
                          />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                              {/*defaultValue={parseFloat(row.qtyRequiredForLRW).toFixed(2)}*/}
                          <TextField label="Qty Required for LRW"
                              defaultValue={row.qtyRequiredForLRW}
                              variant="outlined" fullWidth className={classes.textField}
                              onChange={(e) => {
                                   const onChangeData = ( e.target.value)
                                  handleEqtyRequiredForLRW(onChangeData  as string)
                              }}
                          />
                         
                      </Grid>

                  </Grid>
              </Grid>
          </Grid>
          <div className={classes.dividerLine}/>
          <Grid container spacing={isSmall ? 0 : 4}>
              <Grid item xs={12} lg={6} >
                  <Autocomplete
                      value={row.rootCause}

                      id="rootCause"
                      clearOnBlur
                      fullWidth
                      options={rootOptionList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} label="Root Cause" variant="outlined" className={classes.textField} />}
                      onChange={(event, option) => {
                          handleRootCause(option as string);
                      }}
                  />
                  <Autocomplete
                      value={row.counterMeasureOwner}
                      id="counterMeasureOwner"
                      clearOnBlur
                      fullWidth
                      options={countermeasureList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} label="Countermeasure Owner" variant="outlined" className={classes.textField} />}
                      onChange={(event, option) => {
                          handleCounterMeasureOwner(option as string);
                      }}
                  />
            
              </Grid>
              <Grid item xs={12} lg={6}>
                  <Autocomplete
                      value={row.recommendedCounterMeasure}
                      id="recommendedCounterMeasure"
                      options={recommendedList}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} label="Recommended Countermeasure " variant="outlined" className={classes.textField} />}
                      onChange={(event, option) => {
                          handleRecommendedCounterMeasure(option as string);
                      }}
                  />
                  <Grid container spacing={isSmall ? 0 : 2}>
                      <Grid item xs={12} lg={6}>
                              {/*value={parseFloat(row.estCostChange).toFixed(2)}*/}
                          <TextField
                              value={(row.estCostChange)}
                              type="number"
                              label="Est Cost of Change"
                              variant="outlined"
                              fullWidth className={classes.textField}
                              inputProps={{
                                  maxLength: 13,
                                  step: "1"
                              }}
                              onChange={(e) => {
                                  const onChangeData = e.target.value
                                  handleEstCostChange(onChangeData as string)
                              }}
                          />
                      
                      </Grid>
    
                      <Grid item xs={12} lg={6}>
                          <TextField
                              id="date"
                              label="Anticipated MRP Recovery Date"
                              type="date"
                              defaultValue={anticipatedFomateDate}
                              fullWidth
                              variant="outlined"
                              className={classes.textField}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              InputProps={{
                                  inputProps: {
                                      min: formatDate(new Date(new Date())), max: "2922-06-30"} }}
                              onChange={(event) => {
                                  const date = new Date(event.target.value)
                                  handleAnticipatedMRPRecoveryDate(date as unknown as string)
                              }}
                          />
                      </Grid>

                  </Grid>
              </Grid>
          </Grid>
          {loading ? (
        <Grid item>
          <CircularProgress color={'primary'} />
        </Grid>
          ) : row.comments ? (
        <>
          <Grid item xs={6} className={classes.notesContainer}>
            <Grid item xs={'auto'} className={classes.notesItem}>
                              <Typography>{row.comments}</Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => setCommentDialogOpen(true)}>
                <Edit color={'primary'} />
              </IconButton>
                              <SupplierRecoveryCommentDialog
                open={commentDialogOpen}
                                  row={row}
                onClose={() => setCommentDialogOpen(false)}
                title="Edit note"
                confirmationButtonText="Save"
                                  content={row.comments}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.lastUpdatedContainer}>
                          <SupplierRecoveryLastUpdated row={row} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6} className={classes.notesContainer}>
            <Button
              data-tut={'reactour__add-Note'}
              variant={'outlined'}
              color={'primary'}
              onClick={() => setCommentDialogOpen(true)}
            >
              <Comment className={classes.commentIcon} color={'primary'} /> Add Note
            </Button>
                              <SupplierRecoveryCommentDialog
                                  open={commentDialogOpen}
                                  row={row}
                                  onClose={() => setCommentDialogOpen(false)}
                                  title="Add note"
                                  confirmationButtonText="Save"
                                  placeholder={'Please enter your note here'}
            />
          </Grid>
          <Grid item xs={6} className={classes.lastUpdatedContainer}>
                              <SupplierRecoveryLastUpdated row={row}  />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocompleteNoOptions: {
            color: theme.palette.text.primary,
        },
        [theme.breakpoints.up('sm')]: {
            backgroundColor: 'red',
            padding:100
        },
        commentIcon: {
            marginRight: theme.spacing(1),
        },
        commentsContainer: {
            padding: theme.spacing(0.5, 5),
            [theme.breakpoints.up('sm')]: {
    },
   
        },
       
        commentsSubheader: theme.typography.body1,
        notesContainer: {
            display: 'flex',
            alignItems: 'center',
            wordBreak: 'break-word',
        },
        lastUpdatedContainer: {
            alignSelf: 'flex-end',
            wordBreak: 'break-word',
        },
        notesItem: {
            marginRight: theme.spacing(1),
        },
        textField: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            "& .MuiFormLabel-root": {
                color: "grey" 
            }
        },
        dividerLine: {
            height: "2px",
            width: "100%",
            backgroundColor: "#004e97",
               marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
       
  }),
);
