import { FileResponse } from './generated/ApiClientGenerated';

export const downloadFile = (file: FileResponse): void => {
  const url = window.URL.createObjectURL(file.data);
  const a = document.createElement('a');
  a.href = url;
  a.download = String(file.fileName);
  a.click();
  window.URL.revokeObjectURL(url);
};
