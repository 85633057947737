import { Container, createStyles, LinearProgress, Link, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { DashboardPage } from './features/dashboard/dashboardPage';
import { isDashboardTourOpen } from './features/dashboard/dashboardSlice';
import { setIsTourOpenAction } from './features/detail/detailSlice';
import { DetailPage } from './features/detail/detailPage';
import { SupplierRecoveryPage } from './supplyActionRegister/supplierRecoveryPage';
import { PartDetailsPage } from './features/partDetails/partDetailsPage';
import { getUserInfoAction, logoutAction } from './features/users/userSlice';
import { useAppData } from './services/hooks';
import { RouterBreadcrumbs as Breadcrumbs } from './components/global/breadcrumbs';
import { NotFoundPage } from './notFound';

export const AppRoutes: FC = () => {
  const classes = useStyles();
  const userInfo = useAppData((r) => r.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfoAction());
    const visited = localStorage['alreadyVisited'];
    // if not yet visited, initialize walkthrough
    if (!visited) {
      localStorage['alreadyVisited'] = true;
      dispatch(setIsTourOpenAction(true));
      dispatch(isDashboardTourOpen(true));
    }
  }, []);

  return (
    <Container className={classes.root} maxWidth={'xl'}>
      <Route path="*">
        {(!userInfo.loaded || userInfo.loading) && (
          <LinearProgress aria-label={'linear-indeterminate-progress'} variant="indeterminate" />
        )}
        {userInfo.loaded && !userInfo.loading && (
          <>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard/" />
              </Route>
              <Route exact path="/planning-detail" component={DetailPage} />
                          <Route exact path="/part-details" component={PartDetailsPage} />
                          <Route exact path="/recovery-action" component={SupplierRecoveryPage} />
              <Route exact path="/dashboard" component={DashboardPage} />
              <Route exact path={'/not-found'} component={NotFoundPage} />
              <Redirect from="/detail" to="/planning-detail" />
              <Redirect to={'/not-found'} path="*" />
            </Switch>
          </>
        )}
      </Route>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '& .shepherd-theme-custom': {
      background: 'white !important',
    },
    '& .shepherd-content': {
      background: 'white',
    },
    root: {
      maxWidth: '1440px',
      margin: 'auto',
      padding: theme.spacing(3),
      // maxWidth: '1080px',
    },
  }),
);
