import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { apiCall, apiClient } from '../services/apiClient';
import {
   
    IsupplierRecoveryFilterset,
    supplierActionRegister,

    SupplierRecoveryFilterset,
    SupplierRecoveryResponse,
} from '../services/generated/ApiClientGenerated';
import { RootState } from '../store';
import { showToastAction } from '../features/globalSlice';
import {
  
    getAvailableSupplierRecoveryFiltersAction,
    getSupplierRecoveryAction,
    setSupplierRecoveryPageAction,
    setSupplierRecoveryPageSizeAction,
    setSupplierRecoveryPaginationSuccessAction,
    setSupplierRecoveryFiltersAction,  
    successGetSupplierRecoveryFiltersAction,
    successGetSupplierRecoveryAction,
    updateSupplierRecoveryAction,
    UpdateSupplierRecoveryType
} from './supplierRecoverySlice';

export function* getSupplierRecovery() {
    try {
        const filters: IsupplierRecoveryFilterset = yield select((r: RootState) => r.supplierRecovery.filters);
        if ((filters.BaseSupplierId?.length ?? 0) === 0) {
            yield put(successGetSupplierRecoveryAction([]));
        } else {
            const response: SupplierRecoveryResponse = yield apiCall(
                apiClient.supplierRecovery_GetDetails,
                new SupplierRecoveryFilterset(filters),
            );
            yield put(successGetSupplierRecoveryAction(response.data ?? []));
            yield put(setSupplierRecoveryPaginationSuccessAction(response.totalCount ?? 0));
        }
    } catch (err) {
        yield put(
            showToastAction({
                message: 'Could not load matching data. Please try again.',
                severity: 'error',
            }),
        );
    }
}



export function* updateSupplierRecovery(action: PayloadAction<UpdateSupplierRecoveryType>) {
    
    const oldObject = action.payload.body;
    const newObject = { ...oldObject, anticipatedMRPRecoveryDate: null };
     const updatedData = action.payload.body.anticipatedMRPRecoveryDate === '' ? newObject : action.payload.body;

    try {
        const newSupplierRecovery = new supplierActionRegister(updatedData);
        yield apiCall(
            apiClient.supplierRecovery_UpdatePartDetails,
            newSupplierRecovery,
        );
     
        yield put(
            showToastAction({
                message: 'Successfully updated supplier recovery action',
                severity: 'success',
            }),
        );
    } catch (err) {
        yield put(
            showToastAction({
                message: 'Could not update supplier recovery action data',
                severity: 'error',
            }),
        );
    }
}


export function* setSupplierRecoveryFilters() {
    yield put(getAvailableSupplierRecoveryFiltersAction());
}

export function* getAvailableSupplierRecoveryFilters() {
    try {
        const currentFilters: SupplierRecoveryFilterset = yield select((r: RootState) => r.supplierRecovery.filters);
        if ((currentFilters.BaseSupplierId?.length ?? 0) === 0) {
            yield put(successGetSupplierRecoveryFiltersAction({}));
        } else {
            const response: SupplierRecoveryFilterset = yield apiCall(
                apiClient.supplierRecovery_GetFilterOptions,
                new SupplierRecoveryFilterset(currentFilters),
            );
            yield put(successGetSupplierRecoveryFiltersAction(response));
        }
    } catch (err) {
        yield put(
            showToastAction({
                message: 'Could not get Supplier Recovery detail filters',
                severity: 'error',
                body: err.errors,
            }),
        );
    }
}

export function* supplierRecoverySaga() {
    yield all([
      
        takeLatest(getAvailableSupplierRecoveryFiltersAction.type, getAvailableSupplierRecoveryFilters),
        takeLatest(getSupplierRecoveryAction.type, getSupplierRecovery),
        takeLatest(setSupplierRecoveryPageAction.type, getSupplierRecovery),
        takeLatest(setSupplierRecoveryPageSizeAction.type, getSupplierRecovery), 
        takeLatest(setSupplierRecoveryFiltersAction.type, setSupplierRecoveryFilters),
        takeLatest(updateSupplierRecoveryAction.type, updateSupplierRecovery),

        
    ]);
}
