import { Table } from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import ReportIcon from '@material-ui/icons/Report';

export const CellComponent = (props: Table.DataCellProps) => {
  const classes = useStyles();

  return (
    <>
      {props.column.name === 'supplierId' ? (
        props.row.demandUtilization >= 1.5 ? (
          <Table.Cell {...props}>
            <div className={classes.iconCell}>
              <Tooltip arrow title={'Exceeds threshold'}>
                <ReportIcon className={classes.icon} />
              </Tooltip>
              {props.value}
            </div>
          </Table.Cell>
        ) : props.row.demandUtilization >= 1.1 ? (
          <Table.Cell {...props}>
            <div className={classes.iconCell}>
              <Tooltip arrow title={'Approaching threshold'}>
                <WarningIcon className={classes.icon} />
              </Tooltip>
              {props.value}
            </div>
          </Table.Cell>
        ) : (
          <Table.Cell {...props} />
        )
      ) : (
        <Table.Cell {...props} />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1.5),
    },
    iconCell: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
