import { push } from 'connected-react-router';
import { all, put, takeLatest } from 'redux-saga/effects';
import { apiCall, apiClient } from '../../services/apiClient';
import { UserInfo } from '../../services/generated/ApiClientGenerated';
import { setFiltersAction } from '../detail/detailSlice';
import { showToastAction } from '../globalSlice';
import { setPartDetailsFiltersAction } from '../partDetails/partDetailSlice';
import { getUserInfoAction, logoutAction, successGotUserInfoAction } from './userSlice';

function* getUserInfo() {
  const userInfo: UserInfo = yield apiCall(apiClient.users_GetUserInfo);
  if ((userInfo?.supplierIds?.length || 0) >= 1) {
    yield put(setPartDetailsFiltersAction({ key: 'supplierIds', value: userInfo.supplierIds }));
    yield put(setFiltersAction({ key: 'supplierIds', value: userInfo.supplierIds }));
  }
  yield put(successGotUserInfoAction(userInfo));
}

function* logoutSaga() {
  try {
    yield apiCall(apiClient.auth_SignOut);
    yield put(push('/login'));
  } catch (err) {
    yield put(
      showToastAction({
        message: 'Could not log out at this time',
        severity: 'error',
      }),
    );
  }
}

export function* userSaga() {
  yield all([takeLatest(getUserInfoAction, getUserInfo), takeLatest(logoutAction.type, logoutSaga)]);
}
