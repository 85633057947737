import { createStyles, makeStyles, Theme, TextField, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

const AnnualFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value, row }) => {
    return (
        <label htmlFor="annual-capacity">
            <div>
                {row.monthlyCapacity !== undefined ? (
                    <TextField tabIndex={0} value={row.monthlyCapacity?.reduce((inc: number, x: number) => inc + x, 0) ?? 0} disabled={true} />
                ) : (
                    <TextField tabIndex={0} value={value} disabled={false} />
                )}
            </div>
        </label>
    );
};

export const AnnualTypeProvider: FC<DataTypeProviderProps> = (props) => (
    <DataTypeProvider formatterComponent={AnnualFormatter} {...props} />
);
