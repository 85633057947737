import { spawn } from 'redux-saga/effects';

import { dashboardSaga } from './features/dashboard/dashboardSaga';
import { detailSaga } from './features/detail/detailSaga';
import { globalSaga } from './features/globalSaga';
import { userSaga } from './features/users/userSaga';
import { partDetailSaga } from './features/partDetails/partDetailSaga';
import { supplierRecoverySaga } from "./supplyActionRegister/supplierRecoverySaga";

export function* rootSagas() {
  yield spawn(dashboardSaga);
  yield spawn(detailSaga);
  yield spawn(globalSaga);
  yield spawn(userSaga);
    yield spawn(partDetailSaga);
    yield spawn(supplierRecoverySaga);

}
