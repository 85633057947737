import { PayloadAction } from '@reduxjs/toolkit';
import { replace, RouterLocation } from 'connected-react-router';
import { LocationState } from 'history';
import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { apiCall, apiClient } from '../../services/apiClient';
import { downloadFile } from '../../services/fileUtils';
import {
  FileParameter,
  FileResponse,
  IPartDetailFilterset,
  PartDetail,
  PartDetailFilterset,
  PartDetailResponse,
} from '../../services/generated/ApiClientGenerated';
import { RootState } from '../../store';
import { Sorting } from '../detail/detailSlice';
import { showToastAction } from '../globalSlice';
import {
  downloadPartDetailCSVAction,
  failedUploadPartDetailCSVAction,
  getAvailablePartDetailFiltersAction,
  loadPartDetailFiltersFromUrlAction,
  getPartDetailsAction,
  PartDetailDownloadType,
  setExpandedPartDetailRowIdsAction,
  setPartDetailPageAction,
  setPartDetailPageSizeAction,
  setPartDetailPaginationSuccessAction,
  setPartDetailsFiltersAction,
  setPartDetailSortAction,
  successDownloadPartDetailCSVAction,
  successGetPartDetailFiltersAction,
  successGetPartDetailsAction,
  successUploadPartDetailCSVAction,
  updatePartDetailsAction,
  UpdatePartDetailType,
  uploadPartDetailCSVAction,
} from '../partDetails/partDetailSlice';

export function* getPartDetails() {
  try {
    const filters: IPartDetailFilterset = yield select((r: RootState) => r.partDetail.filters);
    if ((filters.supplierIds?.length ?? 0) === 0) {
      yield put(successGetPartDetailsAction([]));
      // yield put(setPaginationSuccessAction(0));
    } else {
      const response: PartDetailResponse = yield apiCall(
        apiClient.partDetail_GetPartDetails,
        new PartDetailFilterset(filters),
      );
      yield put(successGetPartDetailsAction(response.data ?? []));
      yield put(setPartDetailPaginationSuccessAction(response.totalCount ?? 0));
    }
  } catch (err) {
    yield put(
      showToastAction({
        message: 'Could not load part detail data',
        severity: 'error',
      }),
    );
  }
}

export function* updatePartDetailData(action: PayloadAction<UpdatePartDetailType>) {
  try {
    const newPartDetail = new PartDetail(action.payload.body);
    yield apiCall(
      apiClient.partDetail_UpdatePartDetails,
      action.payload.supplierId,
      action.payload.partDetailId,
      newPartDetail,
    );
    if (Array.isArray(action.payload.body.monthlyCapacity)) {
      const expandedRowIds: (string | number)[] | undefined = yield select(
        (r: RootState) => r.partDetail.expandedRowIds,
      );
      const dedupedSet = new Set([
        ...(expandedRowIds ?? []),
        `${action.payload.supplierId}-${action.payload.partDetailId}`,
      ]);
      yield put(setExpandedPartDetailRowIdsAction([...dedupedSet]));
    }
    yield put(
      showToastAction({
        message: 'Successfully updated part detail',
        severity: 'success',
      }),
    );
  } catch (err) {
    yield put(
      showToastAction({
        message: 'Could not update part detail data',
        severity: 'error',
      }),
    );
  }
}

export function* downloadPartDetailsCsv(action: PayloadAction<PartDetailDownloadType>) {
  try {
    const filters: IPartDetailFilterset = yield select((r: RootState) => r.partDetail.filters);
    const result: FileResponse = yield apiCall(apiClient.partDetail_DownloadCSV, new PartDetailFilterset(filters));
    yield put(successDownloadPartDetailCSVAction());
    yield call(downloadFile, result);
  } catch (e) {
    yield put(showToastAction({ message: 'There was an issue downloading the CSV', severity: 'error' }));
  }
}

function* uploadPartDetailsCsv(action: PayloadAction<FileParameter>) {
  try {
    const filters: IPartDetailFilterset = yield select((r: RootState) => r.partDetail.filters);
    yield apiCall(apiClient.partDetail_UploadCSV, filters.supplierIds![0], action.payload);
    yield put(successUploadPartDetailCSVAction());
    yield put(getPartDetailsAction());
    yield put(showToastAction({ message: 'Uploaded CSV file' }));
  } catch (err) {
    yield put(failedUploadPartDetailCSVAction());
    if (err.status === 415) {
      yield put(
        showToastAction({
          message: 'Could not upload file. File type must be .csv',
          severity: 'warning',
        }),
      );
    } else {
      yield put(
        showToastAction({
          message: 'Could not upload CSV file',
          severity: 'warning',
          title: err.title,
          subtitle:
            'It looks like something went wrong when uploading your CSV file. Please fix errors, and re-upload with all columns present.',
          body: err.errors && Object.entries(err.errors).map(([k]) => `Missing Column: ${k}`),
        }),
      );
    }
  }
}

function* loadPartDetailsFromUrl() {
  // Current URL information
  const locationState: RouterLocation<LocationState> = yield select((r: RootState) => r.router.location);
  const params = new URLSearchParams(locationState.search);
  const currentFilters: IPartDetailFilterset = yield select((r: RootState) => r.partDetail.filters);

  for (const [key, value] of params.entries()) {
    if (Array.isArray(currentFilters[key as keyof IPartDetailFilterset])) {
      const strArray = value.split(',');
      yield put(
        setPartDetailsFiltersAction({
          key: key as keyof IPartDetailFilterset,
          value: strArray.map((option: string) => option) as IPartDetailFilterset[keyof IPartDetailFilterset],
        }),
      );
      // if key is show only weekly capacity, parse value as boolean
    } else if (key === 'hasMonthlyVariability' || key === 'hasProgrammedBaseline' || key === 'hasAnnualCapacity') {
      yield put(
        setPartDetailsFiltersAction({
          key: key as keyof IPartDetailFilterset,
          value: typeof value !== 'undefined' && Boolean(value) && value.toLowerCase() !== 'false',
        }),
      );
    }
    // If value is not an array or a bool, we are always expecting strings as filter values, which we parse as numbers
    else {
      yield put(
        setPartDetailsFiltersAction({
          key: key as keyof IPartDetailFilterset,
          value: Number(value as IPartDetailFilterset[keyof IPartDetailFilterset]),
        }),
      );
    }
  }
}

export function* setPartDetailSorting(action: PayloadAction<Sorting[]>) {
  yield put(getPartDetailsAction());
}

export function* setPartDetailsFilters(
  action: PayloadAction<{ key: keyof IPartDetailFilterset; value: IPartDetailFilterset[keyof IPartDetailFilterset] }>,
) {
  // Current URL information
  const locationState: RouterLocation<LocationState> = yield select((r: RootState) => r.router.location);

  const params = new URLSearchParams(locationState.search);
  const locationStateSearch = { ...locationState };
  if (
    typeof action.payload.value === 'undefined' ||
    action.payload.value === null ||
    action.payload.value === false ||
    (Array.isArray(action.payload.value) && action.payload.value.length === 0)
  ) {
    params.delete(action.payload.key);
  } else {
    if (action.payload.value) {
      params.set(action.payload.key, action.payload.value.toString());
    }
  }
  locationStateSearch.search = params.toString();
  yield put(replace(locationStateSearch));

  yield put(getAvailablePartDetailFiltersAction());
}

export function* getAvailablePartDetailFilters() {
  try {
    const currentFilters: PartDetailFilterset = yield select((r: RootState) => r.partDetail.filters);
    if ((currentFilters.supplierIds?.length ?? 0) === 0) {
      yield put(successGetPartDetailFiltersAction({}));
    } else {
      const response: PartDetailFilterset = yield apiCall(
        apiClient.partDetail_GetFilterOptions,
        new PartDetailFilterset(currentFilters),
      );
      yield put(successGetPartDetailFiltersAction(response));
    }
  } catch (err) {
    yield put(
      showToastAction({
        message: 'Could not get part detail filters',
        severity: 'error',
      }),
    );
  }
}

export function* partDetailSaga(configuredDebounce?: number) {
  yield all([
    takeLatest(downloadPartDetailCSVAction.type, downloadPartDetailsCsv),
    debounce(configuredDebounce ?? 500, getAvailablePartDetailFiltersAction.type, getAvailablePartDetailFilters),
    debounce(configuredDebounce ?? 500, getPartDetailsAction.type, getPartDetails),
    takeLatest(setPartDetailPageAction.type, getPartDetails),
    takeLatest(setPartDetailPageSizeAction.type, getPartDetails),
    takeLatest(setPartDetailSortAction.type, setPartDetailSorting),
    takeLatest(setPartDetailsFiltersAction.type, setPartDetailsFilters),
    takeLatest(updatePartDetailsAction.type, updatePartDetailData),
    takeLatest(uploadPartDetailCSVAction.type, uploadPartDetailsCsv),
    takeLatest(loadPartDetailFiltersFromUrlAction.type, loadPartDetailsFromUrl),
  ]);
}
