import {
  ChangeSet,
  CustomPaging,
  EditingState,
  PagingState,
  RowDetailState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableHeaderRow,
  TableInlineCellEditing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppData } from '../../services/hooks';
import { scrollToTop } from '../../services/windowHelpers';
import {
  SerializableSupplierInfo,
  setExpandedRowIdsAction,
  setPageIndexAction,
  setPageSizeAction,
  setSortAction,
  updateSupplierDataAction,
} from './detailSlice';
import { showToastAction } from '../globalSlice';
import { CellComponent } from './cellComponent';
import { DateTypeProvider } from './dateTypeProvider';
import { DetailRow } from './detailRow';
import { EditableTypeProvider } from './editableTypeProvider';
import { NoDataCell } from './noDataCell';
import { PercentageTypeProvider } from './percentageTypeProvider';
import { SortLabel } from './sortLabel';
import { TableComponent } from './tableComponent';
import { TableRow } from './tableRow';
import { ToggleCell } from './toggleCell';
import { ColumnType } from '../../services/shared/baseTypes';

export type SupplierDataProps = {
  supplierData: SerializableSupplierInfo[];
};

export type TableColumnExtensions = {
  columnName: string;
  wordWrapEnabled: boolean;
  width?: number | string;
};

export const SupplierCapacityGrid: FC<SupplierDataProps> = ({ supplierData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useAppData((r) => r.detail.filters);
  const totalCount = useAppData((r) => r.detail.supplier.totalCount);
  const pageSize = useAppData((r) => r.detail.supplier.pageSize);
  const pageSizes = useAppData((r) => r.detail.pageSizes);
  const expandedRowIds = useAppData((r) => r.detail.expandedRowIds);
  const dateColumns = ['tenderDate', 'dueDate'];
  const editableColumns = ['weeklyCapacity'];
  const percentageColumn = ['demandUtilization'];
  const sortByColumnName = filters.sortBy
    ? filters.sortBy == 'Demand'
      ? 'weeklyDemand'
      : filters.sortBy.charAt(0).toLowerCase() + filters.sortBy?.slice(1)
    : 'supplierId';

  const sortDirection = filters.sortDirection ? (filters.sortDirection == 'Descending' ? 'desc' : 'asc') : 'desc';
  const columns: ColumnType[] = [
    { name: 'id', title: 'ID' },
    { name: 'supplierId', title: 'Supplier Id', wordWrapEnabled: true },
    { name: 'supplierName', title: 'Supplier Name', wordWrapEnabled: true },
    { name: 'partNumber', title: 'Part Number', wordWrapEnabled: true },
    { name: 'partDescription', title: 'Part Description', wordWrapEnabled: true },
    { name: 'tenderDate', title: 'Tender Date', wordWrapEnabled: true },
    { name: 'dueDate', title: 'Due Date', wordWrapEnabled: true },
    { name: 'weeklyDemand', title: 'Weekly Demand', wordWrapEnabled: true },
    { name: 'impliedCapacity', title: 'Calculated Capacity', wordWrapEnabled: true },
    { name: 'weeklyCapacity', title: 'Override Capacity', wordWrapEnabled: true },
    {
      name: 'demandUtilization',
      title: 'Demand Utilization',
      wordWrapEnabled: true,
    },
  ];
  const tableColumnExtensions: TableColumnExtensions[] = [
    {
      columnName: 'supplierId',
      wordWrapEnabled: true,
    },
    {
      columnName: 'supplierName',
      wordWrapEnabled: true,
      width: '20%',
    },
    {
      columnName: 'partNumber',
      wordWrapEnabled: true,
    },
    {
      columnName: 'partDescription',
      wordWrapEnabled: true,
    },
    {
      columnName: 'tenderDate',
      wordWrapEnabled: true,
    },
    {
      columnName: 'dueDate',
      wordWrapEnabled: true,
    },
    {
      columnName: 'weeklyDemand',
      wordWrapEnabled: true,
    },
    {
      columnName: 'impliedCapacity',
      wordWrapEnabled: true,
    },
    {
      columnName: 'weeklyCapacity',
      wordWrapEnabled: true,
    },
    {
      columnName: 'demandUtilization',
      wordWrapEnabled: true,
    },
  ];
  const editingStateColumnExtensions = [
    {
      columnName: 'supplierId',
      editingEnabled: false,
    },
    {
      columnName: 'supplierName',
      editingEnabled: false,
    },
    {
      columnName: 'partNumber',
      editingEnabled: false,
    },
    {
      columnName: 'partDescription',
      editingEnabled: false,
    },
    {
      columnName: 'tenderDate',
      editingEnabled: false,
    },
    {
      columnName: 'dueDate',
      editingEnabled: false,
    },
    {
      columnName: 'weeklyDemand',
      editingEnabled: false,
    },
    { columnName: 'impliedCapacity', editingEnabled: false },
    { columnName: 'weeklyCapacity', editingEnabled: true },
    { columnName: 'demandUtilization', editingEnabled: false },
  ];

  const sortingExtensions: Array<SortingState.ColumnExtension> = [
    {
      columnName: 'supplierId',
      sortingEnabled: true,
    },
    {
      columnName: 'supplierName',
      sortingEnabled: true,
    },
    {
      columnName: 'partNumber',
      sortingEnabled: true,
    },
    {
      columnName: 'partDescription',
      sortingEnabled: false,
    },
    {
      columnName: 'tenderDate',
      sortingEnabled: true,
    },
    {
      columnName: 'dueDate',
      sortingEnabled: true,
    },
    {
      columnName: 'weeklyDemand',
      sortingEnabled: true,
    },
    { columnName: 'impliedCapacity', sortingEnabled: true },
    { columnName: 'weeklyCapacity', sortingEnabled: true },
    { columnName: 'demandUtilization', sortingEnabled: true },
  ];

  const commitChanges = ({ changed }: ChangeSet) => {
    if (!changed) return;
    const isPositiveNumber = /^\d+$/;
    const [rowId] = Object.keys(changed || {}).map((k) => Number(k));
    if (changed[rowId] === undefined) return;

    const newWeeklyCapacity = changed[rowId].weeklyCapacity;
    if (isPositiveNumber.test(newWeeklyCapacity)) {
      const rowToUpdate = supplierData.find((row, index) => index === rowId);
      if (rowToUpdate && rowToUpdate.supplierId && rowToUpdate.partNumber && rowToUpdate.demandId) {
        dispatch(
          updateSupplierDataAction({
            supplierId: rowToUpdate.supplierId,
            partNumber: rowToUpdate.partNumber,
            demandId: rowToUpdate.demandId,
            weeklyCapacity: Number(newWeeklyCapacity),
            rowId: rowId,
          }),
        );
      }
    } else {
      dispatch(
        showToastAction({
          message: 'There was an issue updating capacity',
          severity: 'warning',
          title: 'Invalid data entry',
          body: 'Enter a valid whole number of 0 or greater',
        }),
      );
    }
  };

  const handlePageIndexChange = (pageIndex: number) => {
    dispatch(setPageIndexAction(pageIndex));
    scrollToTop();
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatch(setPageSizeAction(pageSize));
    scrollToTop();
  };

  const handleSortOrderChange = (e: Sorting[]) => {
    dispatch(setSortAction(e));
  };

  return (
    <Paper className={classes.root}>
      <Grid rows={supplierData} columns={columns}>
        <EditingState onCommitChanges={commitChanges} columnExtensions={editingStateColumnExtensions} />
        <DateTypeProvider for={dateColumns} />
        <EditableTypeProvider for={editableColumns} />
        <PercentageTypeProvider for={percentageColumn} />
        <PagingState
          currentPage={filters.page}
          onCurrentPageChange={handlePageIndexChange}
          pageSize={filters.pageSize || pageSize}
          onPageSizeChange={handlePageSizeChange}
        />
        <PagingPanel pageSizes={pageSizes} />
        <CustomPaging totalCount={totalCount} />
        <SortingState
          onSortingChange={(e) => handleSortOrderChange(e)}
          defaultSorting={[
            {
              columnName: sortByColumnName,
              direction: sortDirection,
            },
          ]}
          columnExtensions={sortingExtensions}
        />
        <DragDropProvider />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={(expandedRowIds) => dispatch(setExpandedRowIdsAction(expandedRowIds))}
        />
        <Table
          tableComponent={TableComponent}
          columnExtensions={tableColumnExtensions}
          noDataCellComponent={NoDataCell}
          rowComponent={TableRow}
          cellComponent={CellComponent}
        />
        <TableColumnReordering
          defaultOrder={[
            'supplierId',
            'supplierName',
            'partNumber',
            'partDescription',
            'tenderDate',
            'dueDate',
            'weeklyDemand',
            'impliedCapacity',
            'weeklyCapacity',
            'demandUtilization',
          ]}
        />
        <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} />
        <TableColumnVisibility defaultHiddenColumnNames={['id']} />
        <TableRowDetail contentComponent={DetailRow} toggleCellComponent={ToggleCell} />
        <TableInlineCellEditing startEditAction={'click'} selectTextOnEditStart={true} />
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // Overriding MUI's min-width calculation to avoid hidden overflow data columns
      '& .MuiTable-root': {
        minWidth: 'inherit !important',
      },
    },
    groupCell: {
      '& > div': {
        backgroundColor: 'inherit',
      },
    },
  }),
);
