import {
    ChangeSet,
    CustomPaging,
    EditingState,
    GroupingState,
    PagingState,
    RowDetailState,
    Sorting,
    SortingState,
    Table as TableBase,
} from '@devexpress/dx-react-grid';
import {
    Grid as DataGrid,
    PagingPanel,
    Table,
    TableColumnVisibility,
    TableHeaderRow,
    TableInlineCellEditing,
    TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { createStyles, Grid, IconButton, makeStyles, Paper, styled, Theme } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { FC, MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IPartDetail } from '../../services/generated/ApiClientGenerated';
import { useAppData } from '../../services/hooks';
import { ColumnType } from '../../services/shared/baseTypes';
import { scrollToTop } from '../../services/windowHelpers';
import { CellComponent } from '../detail/cellComponent';
import { NoDataCell } from '../detail/noDataCell';
import { TableComponent } from '../detail/tableComponent';
import { ToggleCell } from '../detail/toggleCell';
import { showToastAction } from '../globalSlice';
import { AnnualTypeProvider } from './annualTypeProvider';
import { BooleanTypeProvider } from './booleanTypeProvider';
import { KeyTypeProvider } from './keyTypeProvider';
import { MitigationTypeProvider } from './mitigationTypeProvider';
import { MonthlyTypeProvider } from './monthlyTypeProvider';
import { PartDetailRow } from './partDetailRow';
import {
    setExpandedPartDetailRowIdsAction,
    setPartDetailPageAction,
    setPartDetailPageSizeAction,
    setPartDetailSortAction,
    updatePartDetailsAction,
} from './partDetailSlice';
import { PartDetailSortLabel } from './partDetailSortLabel';
import { PartDetailTableRow } from './partDetailTableRow';

export type PartDetailTableProps = {
    partDetailData: {
        supplierId?: string | undefined;
        baseSupplierId?: string | undefined;
        supplierName?: string | undefined;
        partNumber?: string | undefined;
        contractualCapacity?: number | undefined;
        annualCapacity?: number | undefined;
        monthlyCapacity?: number[] | undefined;
        keyConstraints?: string[] | undefined;
        mitigationMeasures?: string[] | undefined;
        programmedBaseline?: boolean;
        lastUpdatedBy?: string | undefined;
        lastUpdatedOn?: Date;
        notes?: string | undefined;
    }[];
};

type PartDetailHeaderProps = {
    value: boolean;
    onClick: MouseEventHandler;
} & Omit<TableBase.RowProps, 'tableRow'>;

const PartDetailHeader: FC<PartDetailHeaderProps> = ({ children, onClick, value }) => (
    <tr>
        <th style={{ borderBottom: '1px solid rgb(224, 224, 224)' }}>
            <IconButton style={{ color: '#FFF' }} onClick={onClick}>
                {value ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
        </th>
        {(children as any).slice(1)}
    </tr>
);

const PartDetailHeaderStyled = styled(PartDetailHeader)(({ theme }) => ({
    color: theme.palette.primary.light,
}));

export const getRowId = (row: IPartDetail) => `${row.supplierId}-${row.partNumber}`;

export const PartDetailTable: FC<PartDetailTableProps> = ({ partDetailData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filters = useAppData((r) => r.partDetail.filters);
    const expandedRowIds = useAppData((r) => r.partDetail.expandedRowIds);
    const pageSize = useAppData((r) => r.partDetail.partDetails.pageSize);
    const pageSizes = useAppData((r) => r.partDetail.pageSizes);
    const totalCount = useAppData((r) => r.partDetail.partDetails.totalCount);
    const [allExpanded, setAllExpanded] = useState(false);
    const booleanColumns = ['programmedBaseline'];
    const monthlyColumns = ['monthlyCapacity'];
    const editableColumns = ['annualCapacity'];
    const mitigationColumns = ['mitigationMeasures'];
    const keyColumns = ['keyConstraints'];
    const sortByColumnName =
        filters.sortBy === 'MonthlyVariability'
            ? 'monthlyCapacity'
            : filters.sortBy.replace(/^(.)/, (m: string) => m.toLowerCase());
    const sortDirection = filters.sortDirection === 'Ascending' ? 'asc' : 'desc';

    const columns: ColumnType[] = [
        { name: 'baseSupplierId', title: 'Supplier Id', wordWrapEnabled: true },
        { name: 'supplierName', title: 'Supplier Name', wordWrapEnabled: true },
        { name: 'partNumber', title: 'Part Number', wordWrapEnabled: true },
        { name: 'partDescription', title: 'Part Description', wordWrapEnabled: true },
        { name: 'annualCapacity', title: 'Annual Capacity', wordWrapEnabled: true },
        { name: 'monthlyCapacity', title: 'Monthly Variability', wordWrapEnabled: true },
        { name: 'keyConstraints', title: 'Constraints?', wordWrapEnabled: true },
        { name: 'mitigationMeasures', title: 'Mitigation Measures?', wordWrapEnabled: true },
    ];

    const handleExpandAll = () => {
        setAllExpanded(!allExpanded);
        if (!allExpanded) {
            dispatch(setExpandedPartDetailRowIdsAction(partDetailData.map(getRowId)));
        } else {
            dispatch(setExpandedPartDetailRowIdsAction([]));
        }
    };

    const commitChanges = ({ changed }: ChangeSet) => {
        if (!changed) return;
        const [changedRowId] = Object.keys(changed || {});
        if (changed[changedRowId] === undefined) return;
        const supplierId = changedRowId.split('-')[0];
        // const partNumber = changedRowId.split('-')[1];
        // const partNumberUpdated = changedRowId.split('-')[1] + "-"+ changedRowId.split('-')[2]
        const rowToUpdate = partDetailData.find((row) => row.supplierId === supplierId && ((row.supplierId + "-" + row.partNumber) === changedRowId));
        //  const rowToUpdate = partDetailData.find((row) => row.supplierId === supplierId && (row.partNumber === partNumber || row.partNumber === partNumberUpdated));
        const isPositiveNumber = /^\d+$/;
        let newAnnualCapacity = changed[changedRowId].annualCapacity;
        newAnnualCapacity = isPositiveNumber.test(newAnnualCapacity)
            ? Number(newAnnualCapacity)
            : rowToUpdate?.annualCapacity;
        const newBody = {
            ...rowToUpdate,
            programmedBaseline: true,
            annualCapacity: newAnnualCapacity,
        };
        if (rowToUpdate && rowToUpdate.baseSupplierId && rowToUpdate.partNumber) {
            dispatch(
                updatePartDetailsAction({
                    supplierId: rowToUpdate.supplierId!,
                    partDetailId: rowToUpdate.partNumber!,
                    body: newBody,
                }),
            );
        } else {
            dispatch(
                showToastAction({
                    message: 'There was an issue updating capacity',
                    severity: 'warning',
                    title: 'Invalid data entry',
                    body: 'Enter a valid whole number of 0 or greater',
                }),
            );
        }
    };

    const tableColumnExtensions: Table.ColumnExtension[] = [
        {
            columnName: 'baseSupplierId',
            wordWrapEnabled: true,
        },
        {
            columnName: 'supplierName',
            wordWrapEnabled: true,
        },
        {
            columnName: 'partNumber',
            wordWrapEnabled: true,
        },
        {
            columnName: 'partDescription',
            wordWrapEnabled: true,
        },
        {
            columnName: 'annualCapacity',
            wordWrapEnabled: true,
        },
        {
            columnName: 'monthlyCapacity',
            wordWrapEnabled: true,
        },
        {
            columnName: 'keyConstraints',
            wordWrapEnabled: true,
        },
        {
            columnName: 'mitigationMeasures',
            wordWrapEnabled: true,
        },
    ];

    const editingStateColumnExtensions = [
        {
            columnName: 'baseSupplierId',
            editingEnabled: false,
        },
        {
            columnName: 'supplierName',
            editingEnabled: false,
        },
        {
            columnName: 'partNumber',
            editingEnabled: false,
        },
        {
            columnName: 'partDescription',
            editingEnabled: false,
        },
        {
            columnName: 'annualCapacity',
            editingEnabled: true,
        },
        {
            columnName: 'monthlyCapacity',
            editingEnabled: true,
        },
        {
            columnName: 'keyConstraints',
            editingEnabled: false,
        },
        {
            columnName: 'mitigationMeasures',
            editingEnabled: false,
        },
    ];

    const sortingStateColumnExtensions: Array<SortingState.ColumnExtension> = [
        {
            columnName: 'baseSupplierId',
            sortingEnabled: true,
        },
        {
            columnName: 'supplierName',
            sortingEnabled: true,
        },
        {
            columnName: 'partNumber',
            sortingEnabled: true,
        },
        {
            columnName: 'partDescription',
            sortingEnabled: false,
        },
        {
            columnName: 'annualCapacity',
            sortingEnabled: true,
        },
        {
            columnName: 'monthlyCapacity',
            sortingEnabled: true,
        },
        {
            columnName: 'keyConstraints',
            sortingEnabled: true,
        },
        {
            columnName: 'mitigationMeasures',
            sortingEnabled: true,
        },
    ];

    const handlePageIndexChange = (pageIndex: number) => {
        dispatch(setPartDetailPageAction(pageIndex + 1));
        scrollToTop();
    };

    const handlePageSizeChange = (pageSize: number) => {
        dispatch(setPartDetailPageSizeAction(pageSize));
        scrollToTop();
    };

    const handleSortOrderChange = (e: Sorting[]) => {
        const updatedSorting = e.map(({ columnName, direction }) => ({
            columnName: columnName === 'monthlyCapacity' ? 'monthlyVariability' : columnName,
            direction,
        }));
        if (e.some(({ columnName }) => columnName === 'partDescription')) return;
        dispatch(setPartDetailSortAction(updatedSorting));
    };

    return (
        <Paper className={classes.root}>
            <Grid item>
                <DataGrid rows={partDetailData ?? []} columns={columns} getRowId={getRowId}>
                    <EditingState onCommitChanges={commitChanges} columnExtensions={editingStateColumnExtensions} />
                    <PagingState
                        currentPage={filters.page - 1}
                        onCurrentPageChange={handlePageIndexChange}
                        pageSize={(filters.pageSize || pageSize) ?? 10}
                        onPageSizeChange={handlePageSizeChange}
                    />
                    <PagingPanel pageSizes={pageSizes} />
                    <CustomPaging totalCount={totalCount} />
                    <Table
                        tableComponent={TableComponent}
                        cellComponent={CellComponent}
                        noDataCellComponent={NoDataCell}
                        rowComponent={PartDetailTableRow}
                        columnExtensions={tableColumnExtensions}
                    />
                    <BooleanTypeProvider for={booleanColumns} />
                    <MonthlyTypeProvider for={monthlyColumns} />
                    <AnnualTypeProvider for={editableColumns} />
                    <MitigationTypeProvider for={mitigationColumns} />
                    <KeyTypeProvider for={keyColumns} />
                    <SortingState
                        onSortingChange={(e) => handleSortOrderChange(e)}
                        defaultSorting={[
                            {
                                columnName: sortByColumnName,
                                direction: sortDirection,
                            },
                        ]}
                        columnExtensions={sortingStateColumnExtensions}
                    />
                    <RowDetailState
                        expandedRowIds={expandedRowIds}
                        onExpandedRowIdsChange={(expandedRowIds) => dispatch(setExpandedPartDetailRowIdsAction(expandedRowIds))}
                    />
                    <GroupingState />
                    <TableHeaderRow
                        showSortingControls
                        sortLabelComponent={PartDetailSortLabel}
                        rowComponent={({ children }) => (
                            <PartDetailHeaderStyled value={allExpanded} onClick={handleExpandAll}>
                                {children}
                            </PartDetailHeaderStyled>
                        )}
                    />
                    <TableColumnVisibility defaultHiddenColumnNames={['id']} />
                    <TableRowDetail contentComponent={PartDetailRow} toggleCellComponent={ToggleCell} />
                    <TableInlineCellEditing startEditAction={'click'} selectTextOnEditStart={false} />
                </DataGrid>
            </Grid>
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // Overriding MUI's min-width calculation to avoid hidden overflow data columns
            '& .MuiTable-root': {
                minWidth: 'inherit !important',
            },
        },
        header: {
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
            backgroundColor: theme.palette.primary.light,
            borderRadius: '0.5rem 0.5rem 0 0',
        },
        headerContainer: {
            '& .MuiLink-root:hover': {
                textDecoration: 'none',
            },
        },
    }),
);
