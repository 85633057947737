import React, { FC } from 'react';
import { createStyles, Grid, Link, makeStyles, Theme } from '@material-ui/core';

import { ReactComponent as PolarisLogo } from '../../assets/Polaris-supplier-portal-logo.svg';

export const BrandHeader: FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapper} container justifyContent="space-between">
      <Grid item md={5}>
        <Link aria-label={'polaris-suppliers-website'} href={'https://polarissuppliers.com'}>
          <PolarisLogo className={classes.logo} />
        </Link>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1, 0),
    },
    logo: {
      width: '40%',
      margin: theme.spacing(0, 4),
    },
  }),
);
