import { IntegratedPaging, IntegratedSorting, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { createStyles, Grid, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import {
  DragDropProvider,
  Grid as DataGrid,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { useAppData } from '../../services/hooks';
import { ColumnType } from '../../services/shared/baseTypes';
import { DateTypeProvider } from '../detail/dateTypeProvider';
import { NoDataCell } from '../detail/noDataCell';
import { TableComponent } from '../detail/tableComponent';
import { TableRow } from '../detail/tableRow';
import { FocusableCell } from '../../components/reusable/focusableCell';
import { SerializableSupplierInfo } from './dashboardSlice';
import { HeaderCell } from '../../components/reusable/headerCell';

export type ActivityFeedProps = {
  supplierData: SerializableSupplierInfo[];
  supplierId: string | undefined;
};

export const ActivityFeed: FC<ActivityFeedProps> = ({ supplierData, supplierId }) => {
  const classes = useStyles();
  const supplierDataWithDateUpdated = supplierData.filter((supplierRow: SerializableSupplierInfo) => {
    return supplierRow.dateUpdated !== undefined;
  });
  const pageSize = useAppData((r) => r.dashboard.dashboardData.pageSize);

  const dateColumns = ['dateUpdated'];
  const columns: ColumnType[] = [
    { name: 'id', title: 'ID' },
    { name: 'supplierId', title: 'Supplier Id', wordWrapEnabled: true },
    { name: 'supplierName', title: 'Supplier Name', wordWrapEnabled: true },
    { name: 'partNumber', title: 'Part Number', wordWrapEnabled: true },
    { name: 'dateUpdated', title: 'Date Updated', wordWrapEnabled: true },
    { name: 'lastUpdatedBy', title: 'Updated By', wordWrapEnabled: true },
  ];

  return (
    <>
      <Grid item className={classes.headerContainer}>
        <Link href={`/detail/?supplierIds=${supplierId}`} target="_blank" rel="noreferrer">
          <Typography className={classes.header} variant="h6" component="h2" align={'center'}>
            Activity Feed
          </Typography>
        </Link>
      </Grid>
      <Grid item>
        <DataGrid rows={supplierDataWithDateUpdated} columns={columns}>
          <PagingState defaultPageSize={pageSize} />
          <IntegratedPaging />
          <SortingState
            defaultSorting={[
              {
                columnName: 'dateUpdated',
                direction: 'desc',
              },
            ]}
          />
          <IntegratedSorting />
          <DateTypeProvider for={dateColumns} />
          <DragDropProvider />
          <Table
            tableComponent={TableComponent}
            noDataCellComponent={NoDataCell}
            rowComponent={TableRow}
            cellComponent={FocusableCell}
          />
          <TableColumnReordering
            defaultOrder={['supplierId', 'supplierName', 'partNumber', 'dateUpdated', 'lastUpdatedBy']}
          />
          <TableHeaderRow cellComponent={HeaderCell} />
          <TableColumnVisibility defaultHiddenColumnNames={['id']} />
        </DataGrid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      backgroundColor: theme.palette.primary.light,
      borderRadius: '0.5rem 0.5rem 0 0',
    },
    headerContainer: {
      '& .MuiLink-root:hover': {
        textDecoration: 'none',
      },
    },
  }),
);
