import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardInfo, ISupplierInfo } from '../../services/generated/ApiClientGenerated';

export type SerializableSupplierInfo = Omit<ISupplierInfo, 'dateUpdated'> & {
  dateUpdated?: number; // Override as timestamp
};

export type DashboardInfo = {
  loading: boolean;
  loaded: boolean;
  pageSize?: number;
  totalCount?: number;
  numberOfItemsNeedingCapacityLoaded?: number;
  numberOfItemsExceedingThreshold?: number;
  numberOfStaleItems?: number;
  numberOfDemandLinesWithoutCapacityBaselineLoaded?: number;
};

export type DashboardState = {
  dashboardData: DashboardInfo;
  isDashboardTourOpen: boolean;
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardData: {
      loading: false,
      loaded: false,
      pageSize: 10,
    },
    isDashboardTourOpen: false,
  } as DashboardState,
  reducers: {
    failedGetDashboardDataAction: (state) => {
      state.dashboardData.loading = false;
      state.dashboardData.loaded = true;
      state.dashboardData.totalCount = undefined;
      state.dashboardData.numberOfItemsExceedingThreshold = undefined;
      state.dashboardData.numberOfItemsNeedingCapacityLoaded = undefined;
      state.dashboardData.numberOfStaleItems = undefined;
      state.dashboardData.numberOfDemandLinesWithoutCapacityBaselineLoaded = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getDashboardDataAction: (state, action: PayloadAction<string>) => {
      state.dashboardData.loading = true;
      state.dashboardData.loaded = false;
    },
    isDashboardTourOpen: (state, action: PayloadAction<boolean>) => {
      state.isDashboardTourOpen = action.payload;
    },
    successGetDashboardDataAction: (state, action: PayloadAction<IDashboardInfo>) => {
      state.dashboardData.loading = false;
      state.dashboardData.loaded = true;
      state.dashboardData.totalCount = action.payload.numberOfTotalItems;
      state.dashboardData.numberOfItemsExceedingThreshold = action.payload.numberOfItemsExceedingThreshold;
      state.dashboardData.numberOfItemsNeedingCapacityLoaded = action.payload.numberOfItemsNeedingCapacityLoaded;
      state.dashboardData.numberOfStaleItems = action.payload.numberOfStaleItems;
      state.dashboardData.numberOfDemandLinesWithoutCapacityBaselineLoaded =
        action.payload.numberOfDemandLinesWithoutCapacityBaselineLoaded;
    },
  },
});

export const {
  failedGetDashboardDataAction,
  getDashboardDataAction,
  isDashboardTourOpen,
  successGetDashboardDataAction,
} = dashboardSlice.actions;
