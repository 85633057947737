import { PayloadAction } from '@reduxjs/toolkit';
import { replace, RouterLocation } from 'connected-react-router';
import { History } from 'history';
import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { apiCall, apiClient } from '../../services/apiClient';
import { downloadFile } from '../../services/fileUtils';
import {
  AddNotesRequest,
  FileParameter,
  FileResponse,
  ISupplierFilterset,
  ISupplierIdName,
  SupplierFilterset,
  SupplierInfoResponse,
} from '../../services/generated/ApiClientGenerated';
import { RootState } from '../../store';
import { showToastAction } from '../globalSlice';
import {
  clearFiltersAction,
  downloadCsvAction,
  DownloadType,
  failedUploadCSVAction,
  getAvailableFiltersAction,
  getSupplierDataAction,
  initializeAvailableFiltersAction,
  loadFiltersFromUrlAction,
  searchVendorNumbersAction,
  SerializableSupplierFilterset,
  setFiltersAction,
  setLastUpdatedAction,
  setPageIndexAction,
  setPageSizeAction,
  setPaginationSuccessAction,
  setSortAction,
  setSupplierCapacityAction,
  Sorting,
  stopLoadingSupplierDataAction,
  stopNotesLoadingAction,
  submitNotesAction,
  successDownloadAction,
  successGetSupplierDataAction,
  successSearchVendorNumbersAction,
  successSetAvailableFiltersAction,
  successUploadCSVAction,
  UpdateCapacityType,
  updateSupplierDataAction,
  uploadCSVAction,
} from './detailSlice';
import LocationState = History.LocationState;

function convertToSupplierFilterset(original: SerializableSupplierFilterset): SupplierFilterset {
  return new SupplierFilterset({
    ...original,
    // Deduplicate supplier ids
    supplierIds: original.supplierIds?.reduce((distinctSupplierIds, currId) => {
      if (!distinctSupplierIds?.includes(currId)) distinctSupplierIds?.push(currId);
      return distinctSupplierIds;
    }, [] as string[]),
    minTenderDate: original.minTenderDate ? new Date(original.minTenderDate) : undefined,
    maxTenderDate: original.maxTenderDate ? new Date(original.maxTenderDate) : undefined,
    minDueDate: original.minDueDate ? new Date(original.minDueDate) : undefined,
    maxDueDate: original.maxDueDate ? new Date(original.maxDueDate) : undefined,
    minDateUpdated: original.minDateUpdated ? new Date(original.minDateUpdated) : undefined,
    maxDateUpdated: original.maxDateUpdated ? new Date(original.maxDateUpdated) : undefined,
  });
}

function* downloadCsv(action: PayloadAction<DownloadType>) {
  try {
    const filters: SerializableSupplierFilterset = yield select((r: RootState) => r.detail.filters);
    const result: FileResponse = yield apiCall(
      apiClient.supplier_DownloadCSV,
      action.payload.supplierId,
      convertToSupplierFilterset(filters),
    );
    yield put(successDownloadAction());
    yield call(downloadFile, result);
  } catch {
    yield put(showToastAction({ message: 'There was an issue downloading the CSV', severity: 'error' }));
  }
}

export function* initializeAvailableFilters(action: PayloadAction<string[]>) {
  try {
    const response: ISupplierFilterset = yield apiCall(
      apiClient.supplier_GetFilters,
      new SupplierFilterset({
        supplierIds: action.payload,
      }),
    );
    yield put(successSetAvailableFiltersAction(response));
  } catch (err) {
    yield put(
      showToastAction({
        message: 'There was an issue updating filters',
        severity: 'warning',
      }),
    );
  }
}

export function* getAvailableFilters() {
  try {
    const currentFilters: SerializableSupplierFilterset = yield select((r: RootState) => r.detail.filters);
    if ((currentFilters.supplierIds?.length ?? 0) === 0) {
      yield put(successSetAvailableFiltersAction({}));
    } else {
      const response: ISupplierFilterset = yield apiCall(
        apiClient.supplier_GetFilters,
        convertToSupplierFilterset(currentFilters),
      );
      yield put(successSetAvailableFiltersAction(response));
    }
  } catch (err) {
    yield put(
      showToastAction({
        message: 'There was an issue updating filters',
        severity: 'warning',
        body: err.errors,
      }),
    );
  }
}

function* loadFiltersFromUrl() {
  // Current URL information
  const locationState: RouterLocation<LocationState> = yield select((r: RootState) => r.router.location);
  const params = new URLSearchParams(locationState.search);
  const currentFilters: SupplierFilterset = yield select((r: RootState) => r.detail.filters);

  for (const [key, value] of params.entries()) {
    if (Array.isArray(currentFilters[key as keyof SupplierFilterset])) {
      const strArray = value.split(',');
      yield put(
        setFiltersAction({
          key: key as keyof SerializableSupplierFilterset,
          value: strArray.map(
            (option: string) => option,
          ) as SerializableSupplierFilterset[keyof SerializableSupplierFilterset],
        }),
      );
      // if key is show only weekly capacity, parse value as boolean
    } else if (key === 'showOnlyNullWeeklyCapacity' || key === 'showOnlyStaleItems') {
      yield put(
        setFiltersAction({
          key: key as keyof SerializableSupplierFilterset,
          value: Boolean(value),
        }),
      );
    }
    // If value is not an array or a bool, we are always expecting strings as filter values, which we parse as numbers
    else {
      yield put(
        setFiltersAction({
          key: key as keyof SerializableSupplierFilterset,
          value: Number(value as SerializableSupplierFilterset[keyof SerializableSupplierFilterset]),
        }),
      );
    }
  }
}

function* clearFilters() {
  const locationState: RouterLocation<LocationState> = yield select((r: RootState) => r.router.location);

  // creating new variable so we don't mutate location state
  const locationStateSearch = { ...locationState };
  locationStateSearch.search = '';
  yield put(replace(locationStateSearch));

  // Get & Update availableFilters
  yield put(getAvailableFiltersAction());

  // Update supplier data from filters
  yield put(getSupplierDataAction());
}

export function* setFilters(
  action: PayloadAction<{ key: keyof SupplierFilterset; value: SupplierFilterset[keyof SupplierFilterset] }>,
) {
  // Current URL information
  const locationState: RouterLocation<LocationState> = yield select((r: RootState) => r.router.location);

  const params = new URLSearchParams(locationState.search);
  const locationStateSearch = { ...locationState };
  if (
    typeof action.payload.value === 'undefined' ||
    action.payload.value === null ||
    action.payload.value === false ||
    (Array.isArray(action.payload.value) && action.payload.value.length === 0)
  ) {
    params.delete(action.payload.key);
  } else {
    if (action.payload.value) {
      params.set(action.payload.key, action.payload.value.toString());
    }
  }
  locationStateSearch.search = params.toString();
  yield put(replace(locationStateSearch));

  //Get & Update availableFilters
  yield put(getAvailableFiltersAction());

  // Update supplier data from filters
  yield put(getSupplierDataAction());

  // Update page index to 0
  yield put(setPageIndexAction(0));
}

export function* getSupplierData() {
  try {
    const filters: SerializableSupplierFilterset = yield select((r: RootState) => r.detail.filters);
    if ((filters.supplierIds?.length ?? 0) === 0) {
      yield put(successGetSupplierDataAction([]));
      yield put(setPaginationSuccessAction(0));
    } else {
      const response: SupplierInfoResponse = yield apiCall(
        apiClient.supplier_GetSupplierData,
        convertToSupplierFilterset(filters),
      );
      yield put(
        successGetSupplierDataAction(
          response.data!.map((r) => ({
            ...r,
            dateUpdated: r.dateUpdated?.valueOf(),
          })),
        ),
      );
      yield put(setPaginationSuccessAction(response.totalCount));
    }
  } catch (err) {
    yield put(stopLoadingSupplierDataAction());
    yield put(
      showToastAction({
        message: 'Could not load supplier data',
        severity: 'error',
      }),
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* setPageIndexSaga(action: PayloadAction<number>) {
  yield put(getSupplierDataAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* setPageSizeSaga(action: PayloadAction<number>) {
  yield put(getSupplierDataAction());
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function* setSortSaga(action: PayloadAction<Sorting[]>) {
  yield put(getSupplierDataAction());
}

export function* searchVendors(action: PayloadAction<string>) {
  try {
    const supplierData: ISupplierIdName[] = yield apiCall(apiClient.suppliers, action.payload);
    if (!supplierData) {
      // yield put(successSearchVendorNumbersAction([{ baseSupplierId: 'Sorry, No Matching Suppliers' }]));
      yield put(successSearchVendorNumbersAction([]));
    } else if (supplierData) {
      yield put(successSearchVendorNumbersAction(supplierData));
    }
  } catch (err) {}
}

export function* updateNotes(action: PayloadAction<AddNotesRequest>) {
  try {
    yield apiCall(apiClient.supplier_AddNotes, new AddNotesRequest(action.payload));
    yield put(getSupplierDataAction());
    yield put(stopNotesLoadingAction());
    yield put(showToastAction({ message: 'Your note has been updated!', severity: 'success' }));
  } catch (err) {
    yield put(stopNotesLoadingAction());
    yield put(
      showToastAction({
        message: 'Could not update note',
        severity: 'warning',
        title: err.title,
        subtitle: err.detail || 'See below for additional details',
        body: err.errors && Object.entries(err.errors).map(([k, v]) => `${k}: ${v}`),
      }),
    );
  }
}

function* updateSupplierData(action: PayloadAction<UpdateCapacityType>) {
  try {
    yield apiCall(
      apiClient.supplier_UpdateCapacityInfo,
      action.payload.supplierId,
      action.payload.partNumber,
      action.payload.demandId,
      Number(action.payload.weeklyCapacity),
    );
    yield put(
      setSupplierCapacityAction({
        supplierId: action.payload.supplierId,
        demandId: action.payload.demandId,
        weeklyCapacity: action.payload.weeklyCapacity,
        rowId: action.payload.rowId,
      }),
    );
    yield put(setLastUpdatedAction(action.payload.rowId));
    yield put(getSupplierDataAction());
  } catch (err) {
    yield put(
      showToastAction({
        message: 'Could not update supplier data',
        severity: 'warning',
        title: err.title,
        subtitle: err.detail || 'See below for additional details',
        body: err.errors && Object.entries(err.errors).map(([k, v]) => `${k}: ${v}`),
      }),
    );
  }
}

function* uploadCSV(action: PayloadAction<FileParameter>) {
  try {
    const filters: ISupplierFilterset = yield select((r: RootState) => r.detail.filters);
    yield apiCall(apiClient.supplier_UploadCSV, filters.supplierIds![0], action.payload);
    yield put(successUploadCSVAction());
    yield put(getSupplierDataAction());
    yield put(showToastAction({ message: 'Uploaded CSV file' }));
  } catch (err) {
    yield put(failedUploadCSVAction());
    if (err.status === 415) {
      yield put(
        showToastAction({
          message: 'Could not upload file. File type must be .csv',
          severity: 'warning',
        }),
      );
    } else {
      yield put(
        showToastAction({
          message: 'Could not upload CSV file',
          severity: 'warning',
          title: err.title,
          subtitle:
            'It looks like something went wrong when uploading your CSV file. Please fix errors, and re-upload with all columns present.',
          body: err.errors && Object.entries(err.errors).map(([k]) => `Missing Column: ${k}`),
        }),
      );
    }
  }
}

export function* detailSaga(configuredDebounce?: number) {
  yield all([
    debounce(configuredDebounce ?? 500, getAvailableFiltersAction.type, getAvailableFilters),
    debounce(configuredDebounce ?? 500, getSupplierDataAction.type, getSupplierData),
    debounce(configuredDebounce ?? 500, searchVendorNumbersAction.type, searchVendors),
    takeLatest(clearFiltersAction.type, clearFilters),
    takeLatest(downloadCsvAction.type, downloadCsv),
    takeLatest(initializeAvailableFiltersAction.type, initializeAvailableFilters),
    takeLatest(loadFiltersFromUrlAction.type, loadFiltersFromUrl),
    takeLatest(setFiltersAction.type, setFilters),
    takeLatest(setPageIndexAction.type, setPageIndexSaga),
    takeLatest(setPageSizeAction.type, setPageSizeSaga),
    takeLatest(setSortAction.type, setSortSaga),
    takeLatest(submitNotesAction.type, updateNotes),
    takeLatest(updateSupplierDataAction.type, updateSupplierData),
    takeLatest(uploadCSVAction.type, uploadCSV),
  ]);
}
