import { TableHeaderRow as TableHeaderRowBase } from '@devexpress/dx-react-grid';
import React, { FC } from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import { Button, createStyles, makeStyles, Tooltip, Typography } from '@material-ui/core';

const SortingIcon = ({ direction }: any) => {
  return <div>{direction == 'asc' ? <ArrowUpward /> : <ArrowDownward />}</div>;
};

export const SortLabel: FC<TableHeaderRowBase.SortLabelProps> = ({ column, onSort, children, direction, disabled }) => {
  const classes = useStyles();

  let tooltip = '';
  let dataTut = '';
  if (column.name === 'dueDate') {
    dataTut = 'reactour__sort';
  }
  if (column.name === 'impliedCapacity') {
    tooltip = `The data in this column is pulled in for the part from the Part Detail page (including the number zero)`;
  }
  if (column.name === 'weeklyCapacity') {
    tooltip = `If you enter data into this column, it will override the capacity for just the week specified in the Due Date column`;
    dataTut = 'reactour__tooltip';
  }
  if (column.name === 'tenderDate') {
    tooltip = 'Estimated week quantity will be shipped';
  }
  if (column.name === 'dueDate') {
    tooltip = 'Estimated week quantity will be due at Polaris facility';
  }
  if (column.name === 'weeklyDemand') {
    tooltip = 'Forecasted quantity of parts needed';
  }
  if (column.name === 'demandUtilization') {
    tooltip =
      'Percentage of supplier capacity consumed by forecasted quantity of parts required (Weekly Capacity / Weekly Demand)';
  }

  return (
    <Tooltip title={tooltip} data-tut={dataTut}>
      {disabled ? (
        <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '13px' }} color="textSecondary">
          {children}
        </Typography>
      ) : (
        <Button
          size="small"
          onClick={() => onSort({ direction: direction === 'asc' ? 'desc' : 'asc', keepOther: false })}
        >
          {children}
          {column.name === 'weeklyCapacity' ? <EditIcon className={classes.editIcon} /> : null}
          {direction && <SortingIcon className={classes.icon} direction={direction} />}
        </Button>
      )}
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    editIcon: {
      color: theme.palette.text.secondary,
      fontSize: '1.125rem',
    },
    icon: {
      '& .MuiSvgIcon-root': {
        fontSize: '1.125rem',
      },
    },
  }),
);
