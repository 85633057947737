import React, { FC } from 'react';
import { format } from 'date-fns';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

const DateFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value }) => {
  // ensures that null date isn't displayed in grid
  if (value && value.toString() != new Date('1900-01-01T06:00:00.000Z')) {
    return <>{format(value, 'yyyy-MM-dd')}</>;
  } else return <></>;
};

export const DateTypeProvider: FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);
