
import React, { FC } from 'react';
import { createStyles, Grid, makeStyles, Theme, Link } from '@material-ui/core';
import { SupplierCapacityHeader } from '../components/dashboard/supplierCapacityHeader';
import { SupplierRecoveryFilterModule } from './supplierRecoveryFilterModule';
import { SupplierRecoveryGrid } from './supplierRecoveryGrid';
import { SpeedDialComponent as SpeedDial } from '../components/reusable/speedDial';
import { useAppData } from '../services/hooks';
import { CircularProgress } from '../components/reusable/circularProgress';

export const SupplierRecoveryPage : FC = () => {
    const classes = useStyles();
    const recoveryData = useAppData((r) => r.supplierRecovery.supplierRecoveries);
    const header = 'Supplier Recovery Action Register';
    return (
        <>
            <Grid container direction={'column'}>
            
                <Grid item className={classes.headerButtonContainer}>
                    <Grid item className={classes.gridHeader}>
                        <SupplierCapacityHeader header={header} />
                    </Grid>              
                </Grid>
            </Grid>
            <Grid container direction={'row'}>
                <Grid className={classes.filterContainer} item xs={12} md={3} lg={2}>
                    <SupplierRecoveryFilterModule />
                </Grid>
                <Grid item xs={12} md={9} lg={10}>
                    {recoveryData.loading ? (
                        <Grid container direction={'row'} className={classes.loadingContainer} justifyContent={'center'}>
                            <Grid item className={classes.circularProgress}>
                                <CircularProgress color={'primary'} />
                            </Grid>
                            <Grid item>
                                <SupplierRecoveryGrid supplyActionData={recoveryData.value} />
                            </Grid>
                        </Grid>
                    ) : (
                            <SupplierRecoveryGrid supplyActionData={recoveryData.value} />
                    )}
                   
                </Grid>
            </Grid>
            <Grid container justifyContent={'flex-end'} className={classes.linkContainer}>
                <SpeedDial className={classes.fab} />
            </Grid>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            minWidth: '7.188rem',
        },
        buttonContainer: {
            padding: theme.spacing(0, 0.5),
        },
        buttonWrapper: {
            alignItems: 'center',
            display: 'flex',
        },
        headerButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(1),
        },
        fab: {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        },
        linkContainer: {
            marginTop: theme.spacing(1),
        },
        loadingContainer: {
            width: 'inherit',
        },
        loadingIconContainer: {
            textAlign: 'center',
        },
        circularProgress: {
            zIndex: 9,
            position: 'absolute',
            margin: theme.spacing(12, 0),
            display: 'flex',
        },
        gridHeader: {
            padding: theme.spacing(0),
        },
        filterContainer: {
            marginBottom: theme.spacing(2),
        },
        [`@media print`]: {
            buttonWrapper: {
                display: 'none',
            },
            filterContainer: {
                display: 'none',
            },
        },
    }),
);

