import { TableHeaderRow as TableHeaderRowBase } from '@devexpress/dx-react-grid';
import React, { FC } from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import { Button, createStyles, makeStyles, Tooltip, Typography } from '@material-ui/core';

const SortingIcon = ({ direction }: any) => {
  return <div>{direction == 'asc' ? <ArrowUpward /> : <ArrowDownward />}</div>;
};

export const PartDetailSortLabel: FC<TableHeaderRowBase.SortLabelProps> = ({
  children,
  column,
  direction,
  disabled,
  onSort,
}) => {
  const classes = useStyles();

  let tooltip = '';
  let dataTut = '';
  if (column.name === 'annualCapacity') {
    tooltip = 'Total annual capacity';
    dataTut = 'reactour__annualCapacity';
  }
  if (column.name === 'monthlyCapacity') {
    tooltip = `Indicates whether there is variability during any given months of the year`;
    dataTut = 'reactour__monthlyVar';
  }
  if (column.name === 'programmedBaseline') {
    tooltip = `If you turn this on, this will set the Annual or Monthly Capacity as the baseline for all demand requests and you can no longer update the Weekly Demand on the Planning Detail page.`;
    dataTut = 'reactour__programmedBaseline';
  }
  if (column.name === 'keyConstraints') {
    tooltip = `Indicates whether this part has any constraints`;
    dataTut = 'reactour__keyConstraints';
  }
  if (column.name === 'mitigationMeasures') {
    tooltip = `Indicates whether this part has any mitigation measures put in place`;
    dataTut = 'reactour__mitigationMeasures';
  }
  return (
    <Tooltip arrow title={tooltip} data-tut={dataTut}>
      {disabled ? (
        <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '13px' }} color="textSecondary">
          {children}
        </Typography>
      ) : (
        <Button
          size="small"
          onClick={() => onSort({ direction: direction === 'asc' ? 'desc' : 'asc', keepOther: false })}
        >
          {children}
          {column.name === 'annualCapacity' ? <EditIcon className={classes.editIcon} /> : null}
          {direction && <SortingIcon className={classes.icon} direction={direction} />}
        </Button>
      )}
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    editIcon: {
      color: theme.palette.text.secondary,
      fontSize: '1.125rem',
    },
    icon: {
      '& .MuiSvgIcon-root': {
        fontSize: '1.125rem',
      },
    },
  }),
);
