import { connectRouter } from 'connected-react-router';
import { History, LocationState } from 'history';
import { combineReducers } from 'redux';

import { dashboardSlice } from './features/dashboard/dashboardSlice';
import { detailSlice } from './features/detail/detailSlice';
import { globalSlice } from './features/globalSlice';
import { userSlice } from './features/users/userSlice';
import { partDetailSlice } from "./features/partDetails/partDetailSlice";
import { supplierRecoverySlice } from "./supplyActionRegister/supplierRecoverySlice";


// We allow this createRootReducer to infer types based on sub-reducers
// We then use those types in store.ts
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createRootReducer<L = LocationState>(history: History<L>) {
  return combineReducers({
    router: connectRouter(history),
    dashboard: dashboardSlice.reducer,
    detail: detailSlice.reducer,
      partDetail: partDetailSlice.reducer,
      supplierRecovery: supplierRecoverySlice.reducer,
    global: globalSlice.reducer,
    user: userSlice.reducer,
  });
}
