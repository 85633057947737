import React, { FC, useEffect, useState } from 'react';
import { createStyles, Grid, InputLabel, makeStyles, Slider, Theme, Tooltip, Typography } from '@material-ui/core';

type RangeProps = {
  value: number[];
  onChange: (value: [number | undefined, number | undefined]) => any;
  min: number;
  max: number;
  percentageFormatter?: boolean;
  label: string;
  step: number;
};

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: Props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value} arrow>
      {children}
    </Tooltip>
  );
}

export const RangeSlider: FC<RangeProps> = ({ label, step, percentageFormatter, value, min, max, onChange }) => {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const valueLabelFormat = (value: number) => {
    if (percentageFormatter) {
      return `${value.toFixed(1)}%`;
    }
    return `${value}`;
  };

  const handleChange = (event: React.ChangeEvent<any>, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      let newMinValue: number | undefined = newValue[0];
      let newMaxValue: number | undefined = newValue[1];
      if (newValue[0] === min) {
        newMinValue = undefined;
      }
      if (newValue[1] === max) {
        newMaxValue = undefined;
      }
      onChange([newMinValue, newMaxValue]);
    }
  };

  if (value.length !== 2) {
    throw 'value parameter supplied with an incorrect amount of values';
  }

  function handleInternalChange(event: React.ChangeEvent<any>, newValue: number | number[]) {
    return setInternalValue(newValue as number[]);
  }

  return (
    <Grid container className={classes.root} direction={'column'}>
      <Grid item>
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
        <Typography className={classes.inputLabelSubheader}>
          {percentageFormatter ? `${value[0].toFixed(1)}% to ${value[1].toFixed(1)}%` : `${value[0]} to ${value[1]}`}
        </Typography>
      </Grid>
      <Grid item className={classes.sliderContainer}>
        <Slider
          getAriaLabel={valueLabelFormat}
          value={internalValue}
          max={max}
          min={min}
          onChangeCommitted={handleChange}
          onChange={handleInternalChange}
          step={percentageFormatter ? step * 0.1 : step}
          ValueLabelComponent={ValueLabelComponent}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    inputLabel: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    inputLabelSubheader: {
      fontSize: '0.75rem',
      padding: theme.spacing(0, 0.5),
      color: theme.palette.secondary.main,
    },
    sliderContainer: {
      padding: theme.spacing(0, 1),
      textAlign: 'center',
    },
  }),
);
