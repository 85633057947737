import React, { FC } from 'react';
import { BrandHeader } from './brandheader';
import { Nav } from './nav';
import { SarNav } from './sarnav';
import { useLocation } from 'react-router-dom'

export const Header: FC = () => {
  const location = useLocation();
  return (
    <>
      <BrandHeader />
      {
        location.pathname === "/recovery-action" ? <SarNav /> : <Nav />
      }


    </>
  );
};
