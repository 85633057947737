import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserInfo, UserInfo } from '../../services/generated/ApiClientGenerated';

export const userSlice = createSlice({
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutAction: (state) => {},
    getUserInfoAction: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    successGotUserInfoAction: (state, action: PayloadAction<IUserInfo>) => {
      if (Array.isArray(action.payload.supplierIds)) state.supplierIds = action.payload.supplierIds;
      if (Array.isArray(action.payload.roles)) state.roles = action.payload.roles;
      state.loading = false;
      state.loaded = true;
    },
    failureGetUserInfoAction: (state) => {
      state.loading = false;
    },
  },
  name: 'users',
  initialState: {
    loading: false,
    loaded: false,
    supplierIds: [] as string[],
    roles: [] as string[],
  },
});

export type UsersState = ReturnType<typeof userSlice.reducer>;
export const { getUserInfoAction, successGotUserInfoAction, failureGetUserInfoAction, logoutAction } =
  userSlice.actions;
