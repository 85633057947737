import { Switch } from '@material-ui/core';
import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { useDispatch, useSelector } from 'react-redux';
import { IPartDetail } from '../../services/generated/ApiClientGenerated';
import { RootState } from '../../store';
import { updatePartDetailsAction } from './partDetailSlice';

const BooleanFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value, row }) => {
  const dispatch = useDispatch();
  const partDetails: IPartDetail[] = useSelector((r: RootState) => r.partDetail.partDetails.value);
  const supplierId: string = row.supplierId;
  const partNumber: string = row.partNumber;
  const partDetail = partDetails.filter((p) => p.partNumber === partNumber && p.supplierId === supplierId)[0];
  const handleToggle = () => {
    dispatch(
      updatePartDetailsAction({
        supplierId: supplierId,
        partDetailId: partNumber,
        body: {
          ...partDetail,
          programmedBaseline: !partDetail.programmedBaseline,
        },
      }),
    );
  };
  return <Switch checked={value} value={value} color={'primary'} onChange={handleToggle} />;
};

export const BooleanTypeProvider: FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanFormatter} {...props} />
);
