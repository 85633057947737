import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  
    ISupplierRecovery,
    IsupplierRecoveryFilterset,
    supplierActionRegister,
    ListSortDirection,
    SupplierRecoverySortOption,
    
} from '../services/generated/ApiClientGenerated';
import {  PaginatableLoadable } from '../services/shared/baseTypes';





export type SerializableSupplierInfo = Omit<ISupplierRecovery, 'dateUpdated'> & {
    dateUpdated?: number; // Override as timestamp
};

 
export type RecoveryState = {
    availableFilters: IsupplierRecoveryFilterset;
    supplierRecoveries: PaginatableLoadable<ISupplierRecovery>;
    filters: IsupplierRecoveryFilterset;
    pageSizes: number[];
};
export type UpdateSupplierRecoveryType = {
    baseSupplierId: string;
    partNumber: string;
    body: ISupplierRecovery;
};

export type Sorting = {
    direction: string;
    columnName: string;
};

export const supplierRecoverySlice = createSlice({
    name: 'supplierRecovery',
    initialState: {
        availableFilters: {},
        
        supplierRecoveries: {
            loading: false,
            loaded: false,
            value: [],
            pageSize: 25,
            totalCount: 0,
        },
        pageSizes: [5, 10, 15, 50, 100],
    
     
        filters: {
            SortColumn: SupplierRecoverySortOption.Undefined,
            sortDirection: ListSortDirection.Ascending,
            PageSize: 10,
            PageNumber: 1,
            supplierIds: [],
            partNumbers: [],
        } as IsupplierRecoveryFilterset,
     
        
    } as RecoveryState,
    reducers: {
        getSupplierRecoveryAction: (state) => {
            state.supplierRecoveries.loading = true;
            state.supplierRecoveries.loaded = false;
        },
        updateSupplierRecoveryAction: (state, action: PayloadAction<UpdateSupplierRecoveryType>) => {
            state.supplierRecoveries.value = state.supplierRecoveries.value.map((row, index) => {
                if (
                    row.baseSupplierId === action.payload.body.baseSupplierId &&
                    row.partNumber === action.payload.body.partNumber
                ) {
                    return action.payload.body;


                } else {
                    return row;
                }

            });
        },
        setSupplierRecoverySortAction: (state, action: PayloadAction<Sorting[]>) => {
            if (action.payload.length === 0) {
                state.filters.SortColumn = SupplierRecoverySortOption.Undefined;
                state.filters.sortDirection = ListSortDirection.Descending;
            } else {
                const sortOption = action.payload[0].columnName;
                state.filters.SortColumn = sortOption.replace(/^./, (m) => m.toUpperCase()) as SupplierRecoverySortOption;
                state.filters.sortDirection =
                    action.payload[0].direction === 'asc' ? ListSortDirection.Ascending : ListSortDirection.Descending;
            }
        },
        successGetSupplierRecoveryAction: (state, action: PayloadAction<supplierActionRegister[]>) => {
            state.supplierRecoveries = {
                ...state.supplierRecoveries,
                loading: false,
                loaded: true,
                value: action.payload,
            };
        },
      
     
   
        getAvailableSupplierRecoveryFiltersAction: (state) => {  },
        successGetSupplierRecoveryFiltersAction: (state, action: PayloadAction<IsupplierRecoveryFilterset>) => {


            state.availableFilters = action.payload;
        },
        setSupplierRecoveryFiltersAction: (
            state,
            action: PayloadAction<{

                key: keyof IsupplierRecoveryFilterset;
                value: IsupplierRecoveryFilterset[keyof IsupplierRecoveryFilterset];

            }>,
        ) => {
            (state.filters as any)[action.payload.key] = action.payload.value;
        },
        clearSupplierRecoveryFiltersAction: (state) => {
            state.filters = {
                SortColumn: SupplierRecoverySortOption.Undefined,
                sortDirection: ListSortDirection.Ascending,
                pageSize: 10,
                page: 1,
                supplierIds: state.filters.BaseSupplierId,
                partNumber: [],
               
            } as IsupplierRecoveryFilterset;
        },
      
     
     
      
        setSupplierRecoveryPageAction: (state, action: PayloadAction<number>) => {
            state.supplierRecoveries.loading = true;
            state.supplierRecoveries.loaded = false;
            state.filters.PageNumber = action.payload;
        },
        setSupplierRecoveryPageSizeAction: (state, action: PayloadAction<number>) => {
            state.supplierRecoveries.loading = true;
            state.supplierRecoveries.loaded = false;
            state.filters.PageSize = action.payload;
        },
        setSupplierRecoveryPaginationSuccessAction: (state, action: PayloadAction<number>) => {
            state.supplierRecoveries.totalCount = action.payload;
        },
      
    },
});

export const {
    clearSupplierRecoveryFiltersAction, 
    getAvailableSupplierRecoveryFiltersAction,
    getSupplierRecoveryAction,   
    setSupplierRecoveryPageAction,
    setSupplierRecoveryPageSizeAction,
    setSupplierRecoveryPaginationSuccessAction,
    setSupplierRecoveryFiltersAction,
    setSupplierRecoverySortAction,
    successGetSupplierRecoveryFiltersAction,
    successGetSupplierRecoveryAction,
    updateSupplierRecoveryAction,
} = supplierRecoverySlice.actions;
