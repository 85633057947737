import React, { FC, useEffect, useState } from 'react';
import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { CircularProgress } from '../../components/reusable/circularProgress';
import { useAppData } from '../../services/hooks';
import { SerializableSupplierInfo } from './detailSlice';
import { CommentDialog } from './commentDialog';
import { LastUpdated } from './lastUpdated';
import Edit from '@material-ui/icons/Edit';
import Comment from '@material-ui/icons/Comment';

export const DetailRow: FC<{ row: SerializableSupplierInfo }> = ({ row }) => {
  const classes = useStyles();
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const loading = useAppData((r) => r.detail.notes.loading);

  return (
    <Grid container direction={'row'} spacing={4} justifyContent={'center'} className={classes.commentsContainer}>
      {loading ? (
        <Grid item>
          <CircularProgress color={'primary'} />
        </Grid>
      ) : row.notes ? (
        <>
          <Grid item xs={6} className={classes.notesContainer}>
            <Grid item xs={'auto'} className={classes.notesItem}>
              <Typography>{row.notes}</Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => setCommentDialogOpen(true)}>
                <Edit color={'primary'} />
              </IconButton>
              <CommentDialog
                open={commentDialogOpen}
                row={row}
                onClose={() => setCommentDialogOpen(false)}
                title="Edit note"
                confirmationButtonText="Save"
                content={row.notes}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.lastUpdatedContainer}>
            <LastUpdated row={row} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6} className={classes.notesContainer}>
            <Button
              data-tut={'reactour__add-Note'}
              variant={'outlined'}
              color={'primary'}
              onClick={() => setCommentDialogOpen(true)}
            >
              <Comment className={classes.commentIcon} color={'primary'} /> Add Note
            </Button>
            <CommentDialog
              open={commentDialogOpen}
              row={row}
              onClose={() => setCommentDialogOpen(false)}
              title="Add note"
              confirmationButtonText="Save"
              placeholder={'Please enter your note here'}
            />
          </Grid>
          <Grid item xs={6} className={classes.lastUpdatedContainer}>
            <LastUpdated row={row} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentIcon: {
      marginRight: theme.spacing(1),
    },
    commentsContainer: {
      padding: theme.spacing(0.5, 5),
    },
    commentsSubheader: theme.typography.body1,
    notesContainer: {
      display: 'flex',
      alignItems: 'center',
      wordBreak: 'break-word',
    },
    lastUpdatedContainer: {
      alignSelf: 'flex-end',
      wordBreak: 'break-word',
    },
    notesItem: {
      marginRight: theme.spacing(1),
    },
  }),
);
