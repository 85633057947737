import React, { FC, useEffect, useState } from 'react';
import { createStyles, Grid, InputLabel, makeStyles, Slider, Theme, Tooltip, Typography } from '@material-ui/core';
import { endOfDay, format, startOfDay } from 'date-fns';

type DateRangeProps = {
  value: number[];
  onChange: (value: (number | undefined)[]) => any;
  min: number;
  max: number;
  label: string;
};

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: Props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value} arrow>
      {children}
    </Tooltip>
  );
}

export const DateRangeSlider: FC<DateRangeProps> = ({ label, value, min, max, onChange }) => {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      let newMinValue: number | undefined = newValue[0];
      let newMaxValue: number | undefined = newValue[1];
      if (startOfDay(newValue[0]).getTime() === startOfDay(min).getTime()) {
        newMinValue = undefined;
      }
      if (endOfDay(newValue[1]).getTime() === endOfDay(max).getTime()) {
        newMaxValue = undefined;
      }
      onChange([newMinValue, newMaxValue]);
    }
  };

  function handleInternalChange(event: React.ChangeEvent<any>, newValue: number | number[]) {
    return setInternalValue(newValue as number[]);
  }

  const valueLabelFormat = (value: number) => {
    if (value) {
      const dateLabel = format(Number(value), 'yyyy-MM-dd');
      return `${dateLabel}`;
    } else return '';
  };

  if (value.length !== 2) {
    throw 'value parameter supplied with an incorrect number of dates';
  }

  return (
    <Grid container className={classes.root} direction={'column'}>
      <Grid item>
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
        <Typography className={classes.inputLabelSubheader}>
          <span className={classes.noWrap}>{format(Number(value[0] || 0), 'yyyy-MM-dd')}</span> to{' '}
          <span className={classes.noWrap}>{format(Number(value[1] || 0), 'yyyy-MM-dd')}</span>
        </Typography>
      </Grid>
      <Grid item className={classes.sliderContainer}>
        <Slider
          getAriaLabel={valueLabelFormat}
          value={[startOfDay(internalValue[0] || 0).getTime(), endOfDay(internalValue[1] || 0).getTime()]}
          getAriaValueText={valueLabelFormat}
          min={startOfDay(min).getTime()}
          max={endOfDay(max).getTime()}
          onChangeCommitted={handleChange}
          onChange={handleInternalChange}
          step={1000 * 60 * 60 * 24} //milliseconds in a day
          ValueLabelComponent={ValueLabelComponent}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    inputLabel: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    inputLabelSubheader: {
      fontSize: '0.75rem',
      padding: theme.spacing(0, 0.5),
      color: theme.palette.secondary.main,
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    sliderContainer: {
      padding: theme.spacing(0, 1),
      textAlign: 'center',
    },
    sliderLabel: {
      marginBottom: theme.spacing(4),
    },
  }),
);
