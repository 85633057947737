import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useAppData } from '../../services/hooks';

type RoleGuardProps = {
  role: string;
};

export const RoleGuard: React.FC<RoleGuardProps> = ({ role, children }: PropsWithChildren<RoleGuardProps>) => {
  const user = useAppData((s) => s.user);
  if (user?.roles?.indexOf(role) >= 0) {
    return <>{children}</>;
  } else {
    return null;
  }
};
