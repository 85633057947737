import { PayloadAction } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'redux-saga/effects';
import { apiCall, apiClient } from '../../services/apiClient';

import { showToastAction } from '../globalSlice';
import { failedGetDashboardDataAction, getDashboardDataAction, successGetDashboardDataAction } from './dashboardSlice';

type DashboardData = {
  numberOfTotalItems: number;
};

export function* getDashboardData(action: PayloadAction<string>) {
  const supplierId: string = action.payload;
  if (supplierId) {
    try {
      const response: DashboardData = yield apiCall(apiClient.dashboard_Dashboard, supplierId);
      yield put(successGetDashboardDataAction(response));
    } catch (err) {
      yield put(failedGetDashboardDataAction());
      yield put(
        showToastAction({
          message: 'There was an issue accessing the dashboard data',
          severity: 'warning',
          body: err.errors,
        }),
      );
    }
  } else {
    yield put(failedGetDashboardDataAction());
  }
}

export function* dashboardSaga() {
  yield all([takeLatest(getDashboardDataAction.type, getDashboardData)]);
}
