import { Box, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RoleGuard } from '../../features/auth/roleGuard';
import {
  loadFiltersFromUrlAction,
  searchVendorNumbersAction,
  SerializableSupplierFilterset,
  setFiltersAction,
} from '../../features/detail/detailSlice';
import { ISupplierIdName } from '../../services/generated/ApiClientGenerated';
import { useAppData } from '../../services/hooks';

type AutocompleteProps = {
  role: string | undefined;
  supplierId?: ISupplierIdName;
  id?: string;
};

export const AutocompleteComponent: FC<AutocompleteProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filters = useAppData((r) => r.detail.filters);
  const [baseSupplierId, setBaseSupplierId] = useState<ISupplierIdName>({
    baseSupplierId: Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : '',
  });
  const availableSuppliers = useAppData((r) => r.detail.availableVendorNumbers);

  function getOptionLabel(o: ISupplierIdName) {
    return `${o.baseSupplierId ? o.baseSupplierId : ''}${o.name ? ` - ${o.name}` : ''}`;
  }

  useEffect(() => {
    if (props.supplierId) {
      dispatch(searchVendorNumbersAction(props.supplierId.toString()));
    }
  }, [props.supplierId]);

  const handleFilterChange = (
    key: keyof SerializableSupplierFilterset,
    value: SerializableSupplierFilterset[keyof SerializableSupplierFilterset],
  ) => {
    dispatch(setFiltersAction({ key, value }));
  };

  const updateSuppliers = (supplierId: string) => {
    dispatch(searchVendorNumbersAction(supplierId));
  };

  return (
    <Box className={classes.root}>
      <RoleGuard role={props.role ? props.role : 'Supplier'}>
        <Autocomplete
          classes={{ noOptions: classes.autocompleteNoOptions }}
          noOptionsText={'No matching options. Try another search.'}
          fullWidth
          autoComplete
          includeInputInList
          filterSelectedOptions
          filterOptions={(x) => x}
          options={availableSuppliers}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField {...params} label={props.supplierId ? props.supplierId : 'Supplier'} variant="outlined" />
          )}
          onInputChange={(e, newValue) => {
            updateSuppliers(newValue);
          }}
          onChange={(event, options) => {
            if (!options || !options.baseSupplierId) {
              handleFilterChange('supplierIds', []);
              return;
            }
            handleFilterChange('supplierIds', [options.baseSupplierId]);
            setBaseSupplierId(options);
          }}
          value={baseSupplierId}
        />
      </RoleGuard>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAutocomplete-noOptions': {
        color: theme.palette.text.primary,
      },
      margin: theme.spacing(0, 1, 0, 0),
      '& .MuiFormLabel-root': {
        color: theme.palette.secondary.main,
      },
    },
    autocompleteNoOptions: {
      color: theme.palette.text.primary,
    },
  }),
);
