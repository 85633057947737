import { TableHeaderRow } from '@devexpress/dx-react-grid';
import { createStyles, makeStyles, TableCell, Theme } from '@material-ui/core';
import React from 'react';

export const HeaderCell = ({ ...restProps }: TableHeaderRow.CellProps) => {
  const classes = useStyles();
  return <TableCell className={classes.headerCell} {...restProps} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCell: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      paddingLeft: '0.5rem',
      textTransform: 'uppercase',
    },
  }),
);
