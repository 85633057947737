import { createStyles, makeStyles, Theme, TextField } from '@material-ui/core';
import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

const EditableFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value, row }) => {
  const classes = useStyles();

  return (
    <label htmlFor="weekly-capacity">
      <div data-tut={'reactour__inline-Edit'}>
        <TextField
          tabIndex={0}
          value={value}
          disabled={row.programmedBaseline}
          className={
            row.demandUtilization >= 1.5
              ? classes.exceedsThreshold
              : row.demandUtilization >= 1.1
              ? classes.approachesThreshold
              : ''
          }
        />
      </div>
    </label>
  );
};

export const EditableTypeProvider: FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={EditableFormatter} {...props} />
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    exceedsThreshold: {
      '& .MuiInput-input': {
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
      },
    },
    approachesThreshold: {
      '& .MuiInput-input': {
        color: theme.palette.warning.contrastText,
        fontWeight: 'bold',
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.warning.contrastText}`,
      },
    },
  }),
);
