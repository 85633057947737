import {
  ChangeSet,
  CustomPaging,
  EditingState,
  PagingState,
  RowDetailState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableHeaderRow, 
  TableInlineCellEditing,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { ISupplierRecovery } from '../services/generated/ApiClientGenerated';
import { SupplierRecoveryCellComponent } from './supplierRecoveryCellComponent';
import { SupplierRecoveryRow } from './supplierRecoveryRow';
import { SupplierRecoveryNoDataCell } from './supplierRecoveryNoDataCell';
import { SupplierRecoverySortLabel } from './supplierRecoverySortLabel';
import { SupplierRecoveryTableComponent } from './supplierRecoveryTableComponent';
import { SupplierRecoveryTableRow } from './supplierRecoveryTableRow';
import { SupplierRecoveryToggleCell } from './supplierRecoveryToggleCell';
import { ColumnType } from '../services/shared/baseTypes';
import { useAppData } from '../services/hooks';
import { useDispatch } from 'react-redux';
import { scrollToTop } from '../services/windowHelpers';
import ExtremeIcon from '../assets/Extreme.png';
import HighIcon from '../assets/High.png';
import MediumIcon from '../assets/Medium.png';
import LowIcon from '../assets/Low.png';

import {
    setSupplierRecoveryPageSizeAction,
    setSupplierRecoveryPageAction,
    setSupplierRecoverySortAction,
    updateSupplierRecoveryAction,
} from './supplierRecoverySlice';



export type TableColumnExtensions = {
  columnName: string;
  wordWrapEnabled: boolean;
  width?: number | string;
};


export type SupplierRecoveryTableProps = {
    supplyActionData: {
        supplierId?: string | undefined;
        baseSupplierId?: string | undefined;
        supplierName?: string | undefined;
        partNumber?: string | undefined;
        partDescription?: string | undefined;
        partCriticality?: string | undefined;
        whereUsedList?: number[] | undefined;
        est52WeekSpend?: string[];
        est52WeekVolume?: string[];
        currentPastDueQty?: string[];
        production?: string | undefined;
        shippingToMRP?: string | undefined;
        lRWImpact?: string | undefined;
        currentlyNeededForLRW?: string | undefined;
        qtyRequiredForLRW?: string;
        rootCause?: string | undefined;
        recommendedCounterMeasure?: string | undefined;
        counterMeasureOwner?: string | undefined;
        estCostChange?: string;
        anticipatedMRPRecoveryDate?: string | undefined  | null ;
        comments?: string | undefined;
        lastUpdatedDateTime?: Date | undefined;
        lastUpdatedUser?: string | undefined;
        sourceSystemId?: string | undefined;
    }[];
};

export const getRowId = (row: ISupplierRecovery) => `${row.supplierId}-${row.partNumber}`;

export const SupplierRecoveryGrid: FC<SupplierRecoveryTableProps> = ({ supplyActionData} ) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const pageSize = useAppData((r) => r.supplierRecovery.supplierRecoveries.PageSize);
    const pageSizes = useAppData((r) => r.supplierRecovery.pageSizes);
    const totalCount = useAppData((r) => r.supplierRecovery.supplierRecoveries.totalCount);
    const filters = useAppData((r) => r.supplierRecovery.filters);
    const sortByColumnName =
        filters.SortColumn === undefined ? '' : filters.SortColumn.replace(/^(.)/, (m: string) => m.toLowerCase());
    const sortDirection = filters.sortDirection === 'Ascending' ? 'asc' : 'desc';

    let NewSupplyActionData = supplyActionData.map((supplyActionData: any) => ({
        ...supplyActionData,
        est52WeekSpend: parseFloat(supplyActionData.est52WeekSpend).toFixed(2),
        est52WeekVolume: parseFloat(supplyActionData.est52WeekVolume).toFixed(0),
        currentPastDueQty: parseFloat(supplyActionData.currentPastDueQty).toFixed(0),
        icon: (
            supplyActionData.partCriticality === "Low" ? LowIcon :
                supplyActionData.partCriticality === "Medium" ? MediumIcon :
                    supplyActionData.partCriticality === "High" ? HighIcon :
                        supplyActionData.partCriticality === "Extreme" ? ExtremeIcon : LowIcon)
    }));
    NewSupplyActionData = NewSupplyActionData.map(function (data: any) {
        data.icon = <div className={classes.icon}>
            <img src={data.icon}  />

</div>
        return data;
    });

  const columns: ColumnType[] = [
      { name: 'id', title: 'ID' },
      { name: 'icon', title: 'Part Criticality' },
      { name: 'supplierId', title: 'Supplier id', wordWrapEnabled: true },
      { name: 'supplierName', title: 'Supplier Name', wordWrapEnabled: true },
      { name: 'partNumber', title: 'Part Number', wordWrapEnabled: true },
      { name: 'partDescription', title: 'Part Description', wordWrapEnabled: true },
      //{ name: 'whereUsed', title: 'Where used', wordWrapEnabled: true },
      { name: 'est52WeekSpend', title: 'Est 52 wk spend', wordWrapEnabled: true },
      { name: 'est52WeekVolume', title: 'Est 52 wk volume', wordWrapEnabled: true },
      { name: 'currentPastDueQty',title: 'Current past due qty', wordWrapEnabled: true,  },
    ];
    const commitChanges = ({ changed }: ChangeSet) => {
        if (!changed) return;
        const [changedRowId] = Object.keys(changed || {});
        if (changed[changedRowId] === undefined) return;
        const supplierId = changedRowId.split('-')[0];
        const partNumber = changedRowId.split('-')[1];
        const rowToUpdate = supplyActionData.find((row) => row.supplierId === supplierId && row.partNumber === partNumber);
        const isPositiveNumber = /^\d+$/;
        let newEst52WeekSpend = changed[changedRowId].est52WeekSpend;
        newEst52WeekSpend = isPositiveNumber.test(newEst52WeekSpend)
            ? Number(newEst52WeekSpend)
            : rowToUpdate?.est52WeekSpend;

        const newBody = {
            ...rowToUpdate,
            programmedBaseline: true,
            est52WeekSpend: newEst52WeekSpend,
        };
        if (rowToUpdate && rowToUpdate.baseSupplierId && rowToUpdate.partNumber) {
            dispatch(
                updateSupplierRecoveryAction({
                    baseSupplierId: rowToUpdate.supplierId!,
                    partNumber: rowToUpdate.partNumber!,
                    body: newBody,
                }),
            );
        } 
    };

  const tableColumnExtensions: TableColumnExtensions[] = [
    {
          columnName: 'icon',
      wordWrapEnabled: true,
    },
    {
        columnName: 'supplierId',
      wordWrapEnabled: true,
    },
    {
        columnName: 'supplierName',
      wordWrapEnabled: true,
    },
    {
        columnName: 'partNumber',
      wordWrapEnabled: true,
    },
    {
        columnName: 'partDescription',
      wordWrapEnabled: true,
    },
    {
        columnName: 'whereUsed',
      wordWrapEnabled: true,
    },
    {
        columnName: 'est52WeekSpend',
      wordWrapEnabled: true,
    },
    {
        columnName: 'est52WeekVolume',
      wordWrapEnabled: true,
    },
    {
        columnName: 'currentPastDueQty',
      wordWrapEnabled: true,
    },
  ];
    const editingStateColumnExtensions = [
        {
            columnName: 'icon',
            editingEnabled: false,
        },
        {
            columnName: 'supplierId',
            editingEnabled: false,
        },
        {
            columnName: 'supplierName',
            editingEnabled: false,
        },
        {
            columnName: 'partNumber',
            editingEnabled: false,
        },
        {
            columnName: 'partDescription',
            editingEnabled: false,
        },
        {
            columnName: 'whereUsed',
            editingEnabled: false,
        },
        {
            columnName: 'est52WeekSpend',
            editingEnabled: false,
        },
        {
            columnName: 'est52WeekVolume',
            editingEnabled: false,
        },
        {
            columnName: 'currentPastDueQty',
            editingEnabled: false,
        },
    ];

    const sortingStateColumnExtensions: Array<SortingState.ColumnExtension> = [
        //{
        //    columnName: 'icon',
        //    sortingEnabled: false,
        //},
        //{
        //    columnName: 'supplierId',
        //    sortingEnabled: false,
        //},
        {
            columnName: 'supplierName',
            sortingEnabled: true,
        },
        {
            columnName: 'partNumber',
            sortingEnabled: true,
        },
        //{
        //    columnName: 'partDescription',
        //    sortingEnabled: false,
        //},
        //{
        //    columnName: 'whereUsed',
        //    sortingEnabled: false,
        //},
        //{
        //    columnName: 'est52WeekSpend',
        //    sortingEnabled: false,
        //},
        //{
        //    columnName: 'est52WeekVolume',
        //    sortingEnabled: false,
        //},
        //{
        //    columnName: 'currentPastDueQty',
        //    sortingEnabled: false,
        //},
    ];

    const handlePageIndexChange = (pageIndex: number) => {
        dispatch(setSupplierRecoveryPageAction(pageIndex + 1));
        scrollToTop();
    };
    const handlePageSizeChange = (pageSize: number) => {
        dispatch(setSupplierRecoveryPageSizeAction(pageSize));
        scrollToTop();
    };
    const handleSortOrderChange = (e: Sorting[]) => {
        const updatedSorting = e.map(({ columnName, direction }) => ({
            columnName:columnName,
            direction,
        }));
        if (e.some(({ columnName }) => (
            columnName === 'icon' || columnName === 'supplierId' || columnName === 'partDescription' || columnName === 'whereUsed' || columnName === 'est52WeekSpend' || columnName === 'est52WeekVolume' || columnName ===  'currentPastDueQty' ))) return;
        dispatch(setSupplierRecoverySortAction(updatedSorting));
    };
  return (
      <Paper className={classes.root}>
          <Grid rows={NewSupplyActionData} columns={columns} getRowId={getRowId} >
              <EditingState onCommitChanges={commitChanges} columnExtensions={editingStateColumnExtensions}/>
             
              <PagingState
                  currentPage={filters.PageNumber -1 }
                  onCurrentPageChange={handlePageIndexChange}
                  pageSize={(filters.PageSize || pageSize) ?? 10}
                  onPageSizeChange={handlePageSizeChange}
              />
              <PagingPanel pageSizes={pageSizes} />
              <CustomPaging totalCount={totalCount} />
              <SortingState
                  onSortingChange={(e) => handleSortOrderChange(e)}
                  defaultSorting={[
                    {
                          columnName: sortByColumnName,
                          direction: sortDirection,
                      },
                  ]}
                  columnExtensions={sortingStateColumnExtensions}
              />

              <DragDropProvider />
              <RowDetailState

              />
              <Table
                  tableComponent={SupplierRecoveryTableComponent}
                  columnExtensions={tableColumnExtensions}
                  noDataCellComponent={SupplierRecoveryNoDataCell}
                  rowComponent={SupplierRecoveryTableRow}
                  cellComponent={SupplierRecoveryCellComponent}
              />
              
              <TableHeaderRow showSortingControls sortLabelComponent={SupplierRecoverySortLabel} />
              <TableColumnVisibility defaultHiddenColumnNames={['id']} />
              <TableRowDetail contentComponent={SupplierRecoveryRow} toggleCellComponent={SupplierRecoveryToggleCell} />
              <TableInlineCellEditing startEditAction={'click'} selectTextOnEditStart={true} />
          </Grid>
    </Paper>
  );
};


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // Overriding MUI's min-width calculation to avoid hidden overflow data columns
      '& .MuiTable-root': {
        minWidth: 'inherit !important',
      },
    },
    groupCell: {
      '& > div': {
        backgroundColor: 'inherit',
      },
      },
      icon: {
          display: 'flex',
          justifyContent:'center'
      },
  }),
);
