import React, { FC } from 'react';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import { Button, createStyles, makeStyles, Tooltip } from '@material-ui/core';

const SortingIcon = ({ direction }: any) => {
  return <div>{direction == 'asc' ? <ArrowUpward /> : <ArrowDownward />}</div>;
};

export const SupplierRecoverySortLabel: FC = ({ column, onSort, children, direction }: any) => {
  const classes = useStyles();

  let tooltip = '';
  const dataTut = '';
  if (column.name === 'icon') {
    tooltip = `Relative Part Criticality - Buyer defined, based on need for rework, status for shipping to MRP, or other agreed upon conditions`;
  } else if (column.name === 'est52WeekSpend') {
    tooltip = `Qty of parts forecasted by MRP multiplied by CMAT value`;

  } else if (column.name === 'est52WeekVolume') {
    tooltip = `Qty of parts forecast by MRP`;

  } else if (column.name === 'currentPastDueQty') {
    tooltip = `Directional supplier past due PO data - Sum of all POs not confirmed as shipped ( no ASN or import broker confirmation) and with a dock date more than 1 week in the past. `;

  }


  return (
    <Tooltip arrow title={tooltip} data-tut={dataTut}>
      <Button size="small" onClick={onSort}>
        {children}
        {column.name === 'weeklyCapacity' ? <EditIcon className={classes.editIcon} /> : null}
        {direction && <SortingIcon className={classes.icon} direction={direction} />}
      </Button>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    editIcon: {
      color: theme.palette.text.secondary,
      fontSize: '1.125rem',
    },
    icon: {
      '& .MuiSvgIcon-root': {
        fontSize: '1.125rem',
      },
    },
  }),
);
