import React, { useEffect, useState } from 'react';
import { Button, createStyles, FormControlLabel, Grid, makeStyles, Switch, TextField, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RouterLocation } from 'connected-react-router';
import { History } from 'history';
import { useAppData } from '../services/hooks';
import { IsupplierRecoveryFilterset, ISupplierIdName } from '../services/generated/ApiClientGenerated';
import { RangeSlider } from '../components/reusable/rangeSlider';
import { RoleGuard } from '../features/auth/roleGuard';
import { searchVendorNumbersAction } from '../features/detail/detailSlice';
import LocationState = History.LocationState;
import { RootState } from '../store';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {
    setSupplierRecoveryFiltersAction,
    clearSupplierRecoveryFiltersAction,
    getAvailableSupplierRecoveryFiltersAction,
    getSupplierRecoveryAction,
} from './supplierRecoverySlice';

const partApi = [
    'Extreme',
    'High',
    'Medium',
    'Low',
];


export const SupplierRecoveryFilterModule = (): JSX.Element => {
    const [value, setValue] = React.useState([0, 5500]);
    const [svalue, setSvalue] = React.useState([0, 548020]);
    const [vvalue, setVvalue] = React.useState([0, 2882470]);
    const [currentpastinitial, setCurrentpastinitial] = React.useState('');
    const [currentpastlast, setCurrentpastlast] = React.useState('');
    const [weekSpentinitial, setWeekSpentinitial] = React.useState('');
    const [WeekSpentlast, setWeekSpentlast] = React.useState('');
    const [weekVolumeinitial, setWeekVolumeinitial] = React.useState('');
    const [weekVolumelast, setWeekVolumelast] = React.useState('');
    const dispatch = useDispatch();
    const classes = useStyles();
    const availableSuppliers = useAppData((r) => r.detail.availableVendorNumbers);
    const filters = useAppData((r) => r.supplierRecovery.filters);
    const availableFilters = useAppData((r) => r.supplierRecovery.availableFilters);
    const [baseSupplierId, setBaseSupplierId] = useState<ISupplierIdName>({
        baseSupplierId: Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : '',
    });
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const locationState: RouterLocation<LocationState> = useAppData((r: RootState) => r.router.location);
    const params = new URLSearchParams(locationState.search);
    const handleUpdateSuppliers = (supplierId: string) => {
        dispatch(searchVendorNumbersAction(supplierId));
    };
    function handleFilterChange<TKey extends keyof IsupplierRecoveryFilterset>(key: TKey, value: IsupplierRecoveryFilterset[TKey]) {
        dispatch(setSupplierRecoveryFiltersAction({ key, value }));
    }

    const getPartNumbers = (partNumbersArray: string[] | undefined) => {
        // creates new array without duplicate part numbers
        let newArr: any[];
        if (partNumbersArray) {
            newArr = [...new Set(partNumbersArray)];
        } else {
            newArr = ['0'];
        }
        return newArr;
    };

    const handleClearFilters = () => {
        dispatch(clearSupplierRecoveryFiltersAction());
    };
    useEffect(() => {
        if (!baseSupplierId) setBaseSupplierId(Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : '');
    }, [filters.supplierIds]);

    useEffect(() => {
        dispatch(getAvailableSupplierRecoveryFiltersAction());
    }, [filters]);

    useEffect(() => {
        dispatch(getSupplierRecoveryAction());
    }, [filters]);

    const handleDoubleFilterChange = (
        keyOne: keyof IsupplierRecoveryFilterset,
        keyTwo: keyof IsupplierRecoveryFilterset,
        value: IsupplierRecoveryFilterset[keyof IsupplierRecoveryFilterset][],

    ) => {
        handleFilterChange(keyOne, value[0]);
        handleFilterChange(keyTwo, value[1]);
    };

    function getOptionLabel(o: ISupplierIdName) {
        return `${o.baseSupplierId ? o.baseSupplierId : ''}${o.name ? ` - ${o.name}` : ''}`;
    }
    return (
        <div className={classes.filterWrapper}>
            <Grid container direction={'column'} spacing={1} data-tut={'reactour__filter-Module'}>
                <Grid item>
                    <RoleGuard role="Polaris">
                        <Autocomplete
                            classes={{ noOptions: classes.autocompleteNoOptions }}
                            noOptionsText={'No matching options. Try another search.'}
                            id="vendor-number-or-name-select-Polaris"
                            // clearOnBlur
                            fullWidth
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            filterOptions={(x) => x}
                            options={availableSuppliers}
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) => <TextField {...params} label="Supplier" variant="outlined" />}
                            onInputChange={(e, newValue) => {
                                handleUpdateSuppliers(newValue);
                            }}
                            onChange={(event, options) => {
                                if (!options || !options.baseSupplierId) {
                                    handleFilterChange('BaseSupplierId', "");
                                    return;
                                }
                                handleFilterChange('BaseSupplierId', options.baseSupplierId);
                                setBaseSupplierId(options);
                            }}
                            value={baseSupplierId}
                        />
                    </RoleGuard>
                </Grid>

                <Grid item>
                    <Autocomplete
                        id="part-number-select"
                        clearOnBlur
                        multiple
                        aria-label={'part-number-select'}
                        fullWidth
                        options={getPartNumbers(availableFilters.partNumbers)}
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => <TextField {...params} label="Part Number" variant="outlined" />}
                        value={filters.partNumbers || []}
                        onChange={(event, options) => {
                            if (!options) {
                                return;
                            }
                            handleFilterChange(
                                'partNumbers',
                                options.map((option: string) => option),
                            );
                        }}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        id="part-number-select"
                        clearOnBlur
                        multiple
                        aria-label={'part-number-select'}
                        fullWidth
                        options={partApi}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Part Criticality" variant="outlined" />}
                        onChange={(event, options) => {
                            if (!options) {
                                return;
                            }
                            handleFilterChange('partCriticality', options[0]);


                        }}
                    />
                </Grid>

                <Grid item className={classes.toggle}>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                checked={Boolean(filters.NotShippingToMRP)}
                                onChange={() =>
                                    handleFilterChange('NotShippingToMRP', filters.NotShippingToMRP ? undefined : true)
                                }
                                name="show-only-empty-fields"
                                color="primary"
                            />
                        }
                        data-tut={'reactour__blank-capacity'}
                        label="Not Shipping to MRP"
                    />
                </Grid>

                <Grid item className={classes.toggle}>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch

                                name="show-only-stale-data"
                                color="primary"
                            />
                        }
                        data-tut={'reactour__stale-data'}
                        label="Parts with Past Due"
                    />
                </Grid>

                <Grid>
                    {(availableFilters.minCurrentPastDueQty > 0 || availableFilters.minCurrentPastDueQty === 0) && <Grid item>
                        <RangeSlider
                            label={'Current Past Due Qty'}
                            value={[
                                filters.minCurrentPastDueQty || availableFilters.minCurrentPastDueQty,
                                filters.maxCurrentPastDueQty || availableFilters.maxCurrentPastDueQty,
                            ]}
                            onChange={(value) => handleDoubleFilterChange('minCurrentPastDueQty', 'maxCurrentPastDueQty', value)}
                            min={availableFilters.minCurrentPastDueQty}
                            max={availableFilters.maxCurrentPastDueQty}
                            step={10}
                        />
                        <Grid item xs={12} lg={12} spacing={isSmall ? 0 : 4}>
                            <Grid container >
                                <Grid item xs={6}>
                                    <TextField
                                        label="Min value"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "1"
                                        }}
                                        onChange={(e) => {
                                            const onChangeData = e.target.value
                                            setCurrentpastinitial(onChangeData)
                                            {
                                                (e.target.value !== '' && (availableFilters.minCurrentPastDueQty === e.target.value || availableFilters.minCurrentPastDueQty < e.target.value) && availableFilters.maxCurrentPastDueQty > e.target.value && availableFilters.minCurrentPastDueQty < availableFilters.maxCurrentPastDueQty) ? setTimeout(() =>
                                                    handleDoubleFilterChange('minCurrentPastDueQty', 'maxCurrentPastDueQty', [
                                                        onChangeData,
                                                        currentpastlast
                                                    ])
                                                    , 2000) :
                                                    setTimeout(() =>
                                                        handleDoubleFilterChange('minCurrentPastDueQty', 'maxCurrentPastDueQty', [
                                                            availableFilters.minCurrentPastDueQty,
                                                            currentpastlast
                                                        ])
                                                        , 2000)
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Max value"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "1"
                                        }}
                                        onChange={(e) => {
                                            const onChangeData = e.target.value
                                            setCurrentpastlast(onChangeData)
                                            {
                                                e.target.value !== '' &&
                                                    (availableFilters.maxCurrentPastDueQty > e.target.value || availableFilters.maxCurrentPastDueQty === e.target.value) && availableFilters.minCurrentPastDueQty < e.target.value ? setTimeout(() =>
                                                        handleDoubleFilterChange('minCurrentPastDueQty', 'maxCurrentPastDueQty', [
                                                            currentpastinitial,
                                                            onChangeData
                                                        ])
                                                        , 2000) :
                                                    setTimeout(() =>
                                                        handleDoubleFilterChange('minCurrentPastDueQty', 'maxCurrentPastDueQty', [
                                                            currentpastinitial,
                                                            availableFilters.maxCurrentPastDueQty
                                                        ])
                                                        , 2000)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    }


                    {(availableFilters.minEst52WeekSpend > 0 || availableFilters.minEst52WeekSpend === 0) && <Grid item>
                        <RangeSlider
                            label={'Est 52 WK Spend'}
                            value={[
                                filters.minEst52WeekSpend || availableFilters.minEst52WeekSpend,
                                filters.maxEst52WeekSpend || availableFilters.maxEst52WeekSpend,
                            ]}
                            onChange={(value) => handleDoubleFilterChange('minEst52WeekSpend', 'maxEst52WeekSpend', value)}
                            min={availableFilters.minEst52WeekSpend}
                            max={availableFilters.maxEst52WeekSpend}
                            step={10}
                        />
                           <Grid item xs={12} lg={12} spacing={isSmall ? 0 : 4}>
                            <Grid container >
                                <Grid item xs={6}>
                                    <TextField
                                        label="Min value"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "1"
                                        }}
                                        onChange={(e) => {
                                            const onChangeData = e.target.value
                                            setWeekSpentinitial(onChangeData)
                                            {
                                                (e.target.value !== '' && (availableFilters.minEst52WeekSpend === e.target.value || availableFilters.minEst52WeekSpend < e.target.value) && availableFilters.maxEst52WeekSpend > e.target.value && availableFilters.minEst52WeekSpend < availableFilters.maxEst52WeekSpend) ? setTimeout(() =>
                                                    handleDoubleFilterChange('minEst52WeekSpend', 'maxEst52WeekSpend', [
                                                        onChangeData,
                                                        WeekSpentlast
                                                    ])
                                                    , 2000) :
                                                    setTimeout(() =>
                                                        handleDoubleFilterChange('minEst52WeekSpend', 'maxEst52WeekSpend', [
                                                            availableFilters.minEst52WeekSpend,
                                                            WeekSpentlast
                                                        ])
                                                        , 2000)
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Max value"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "1"
                                        }}
                                        onChange={(e) => {
                                            const onChangeData = e.target.value
                                            setWeekSpentlast(onChangeData)
                                            {
                                                e.target.value !== '' &&
                                                    (availableFilters.maxEst52WeekSpend > e.target.value || availableFilters.maxEst52WeekSpend === e.target.value) && availableFilters.minEst52WeekSpend < e.target.value ? setTimeout(() =>
                                                        handleDoubleFilterChange('minEst52WeekSpend', 'maxEst52WeekSpend', [
                                                            weekSpentinitial,
                                                            onChangeData
                                                        ])
                                                        , 2000) :
                                                    setTimeout(() =>
                                                        handleDoubleFilterChange('minEst52WeekSpend', 'maxEst52WeekSpend', [
                                                            weekSpentinitial,
                                                            availableFilters.maxEst52WeekSpend
                                                        ])
                                                        , 2000)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    }

                    {(availableFilters.minEst52WeekVolume > 0 || availableFilters.minEst52WeekVolume === 0) && <Grid item>
                        <RangeSlider
                            label={'Est 52 WK Volume'}
                            value={[
                                filters.minEst52WeekVolume || availableFilters.minEst52WeekVolume,
                                filters.maxEst52WeekVolume || availableFilters.maxEst52WeekVolume,
                            ]}
                            onChange={(value) => handleDoubleFilterChange('minEst52WeekVolume', 'maxEst52WeekVolume', value)}
                            min={availableFilters.minEst52WeekVolume}
                            max={availableFilters.maxEst52WeekVolume}
                            step={10}
                        />
                           <Grid item xs={12} lg={12} spacing={isSmall ? 0 : 4}>
                            <Grid container >
                                <Grid item xs={6}>
                                    <TextField
                                        label="Min value"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "1"
                                        }}
                                        onChange={(e) => {
                                            const onChangeData = e.target.value
                                            setWeekVolumeinitial(onChangeData)
                                            {
                                                (e.target.value !== '' && (availableFilters.minEst52WeekVolume === e.target.value || availableFilters.minEst52WeekVolume < e.target.value) && availableFilters.maxEst52WeekVolume > e.target.value && availableFilters.minEst52WeekVolume < availableFilters.maxEst52WeekVolume) ? setTimeout(() =>
                                                    handleDoubleFilterChange('minEst52WeekVolume', 'maxEst52WeekVolume', [
                                                        onChangeData,
                                                        weekVolumelast
                                                    ])
                                                    , 2000) :
                                                    setTimeout(() =>
                                                        handleDoubleFilterChange('minEst52WeekVolume', 'maxEst52WeekVolume', [
                                                            availableFilters.minEst52WeekVolume,
                                                         weekVolumelast
                                                        ])
                                                        , 2000)
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Max value"
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 13,
                                            step: "1"
                                        }}
                                        onChange={(e) => {
                                            const onChangeData = e.target.value
                                            setWeekVolumelast(onChangeData)
                                            {
                                                e.target.value !== '' &&
                                                    (availableFilters.maxEst52WeekVolume > e.target.value || availableFilters.maxEst52WeekVolume === e.target.value) && availableFilters.minEst52WeekVolume < e.target.value ? setTimeout(() =>
                                                        handleDoubleFilterChange('minEst52WeekVolume', 'maxEst52WeekVolume', [
                                                            weekVolumeinitial,
                                                            onChangeData
                                                        ])
                                                        , 2000) :
                                                    setTimeout(() =>
                                                        handleDoubleFilterChange('minEst52WeekVolume', 'maxEst52WeekVolume', [
                                                            weekVolumeinitial,
                                                            availableFilters.maxEst52WeekVolume
                                                        ])
                                                        , 2000)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>





            </Grid>
            <Grid container direction={'row'} justifyContent={'center'} className={classes.clearFilterContainer}>
                <Button
                    data-tut={'reactour__clear-filters'}
                    variant="contained"
                    color="primary"
                    onClick={handleClearFilters}
                    disabled={(filters.BaseSupplierId?.length ?? 0) == 0}
                >
                    Clear Filters
                </Button>
            </Grid>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocompleteNoOptions: {
            color: theme.palette.text.primary,
        },
        inputLabel: {
            padding: theme.spacing(0.5, 0.5, 0),
        },
        clearFilterContainer: {
            marginTop: theme.spacing(2.5),
            display: 'flex',
        },
        filterWrapper: {
            '& .MuiAutocomplete-noOptions': {
                color: theme.palette.text.primary,
            },
            margin: theme.spacing(0, 1, 0, 0),
            '& .MuiFormLabel-root': {
                color: theme.palette.secondary.main,
            },
        },
        inputField: {
            width: '6.25rem',
        },
        switch: {
            '& .MuiFormControlLabel-label': {
                lineHeight: '1',
            },
        },
        toggle: {
            margin: theme.spacing(0, 1),
        },
    }),
);
