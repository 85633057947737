import React, { FC } from 'react';
import { Badge, Box, createStyles, Grid, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { CircularProgress } from '../../components/reusable/circularProgress';
import { DashboardInfo } from './dashboardSlice';

export type MetricsType = {
  dashboardData: DashboardInfo;
  loading: boolean;
  supplierId: string | undefined;
};

export const Metrics: FC<MetricsType> = ({ dashboardData, loading, supplierId }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.header} variant="h6" component="h2" align={'center'}>
        My Metrics
      </Typography>
      <Grid item className={classes.metricsContainer}>
        {loading ? (
          <Box className={classes.circularProgress}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!dashboardData.totalCount &&
            !dashboardData.numberOfItemsExceedingThreshold &&
            !dashboardData.numberOfItemsNeedingCapacityLoaded &&
            !dashboardData.numberOfStaleItems &&
            !dashboardData.numberOfItemsNeedingCapacityLoaded ? (
              <Box className={classes.circularProgress}>
                <Typography variant="subtitle1" component="h3">
                  No metrics available.
                </Typography>
              </Box>
            ) : (
              <>
                {dashboardData.totalCount ? (
                  <Link href={`/part-details/?supplierIds=${supplierId}`} target="_blank" rel="noreferrer">
                    <Grid item className={classes.individualMetricsContainers}>
                      <Box className={classes.metric} data-testid="total-count">
                        <Typography variant="h4" component="h3">
                          {dashboardData.totalCount}
                        </Typography>
                      </Box>
                      <Typography>Number of Total Parts</Typography>
                    </Grid>
                  </Link>
                ) : (
                  <></>
                )}

                {dashboardData.numberOfItemsExceedingThreshold ? (
                  <Link
                    href={`/planning-detail/?supplierIds=${supplierId}&minDemandUtilization=150`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Grid item className={classes.individualMetricsContainers}>
                      {dashboardData.numberOfItemsExceedingThreshold >= 1 ? (
                        <Badge badgeContent={'!'} color={'error'} data-tut={'reactour__badge'}>
                          <Box className={classes.metric} data-testid="parts-needing-capacity">
                            <Typography variant="h4" component="h3">
                              {dashboardData.numberOfItemsExceedingThreshold}
                            </Typography>
                          </Box>
                        </Badge>
                      ) : (
                        <Box className={classes.metric} data-testid="parts-needing-capacity">
                          <Typography variant="h4" component="h3">
                            {dashboardData.numberOfItemsExceedingThreshold}
                          </Typography>
                        </Box>
                      )}
                      <Typography>Number of Demand Lines Exceeding Threshold</Typography>
                    </Grid>
                  </Link>
                ) : (
                  <></>
                )}

                {dashboardData.numberOfItemsNeedingCapacityLoaded ? (
                  <Link
                    href={`/part-details/?supplierIds=${supplierId}&hasMonthlyVariability=false&hasAnnualCapacity=false`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Grid
                      item
                      className={classes.individualMetricsContainers}
                      data-tut={'reactour__exceeding-threshold-icon'}
                    >
                      {dashboardData.numberOfItemsNeedingCapacityLoaded >= 1 ? (
                        <Badge badgeContent={'!'} color={'error'} data-tut={'reactour__badge'}>
                          <Box className={classes.metric} data-testid="items-exceeding-threshold">
                            <Typography variant="h4" component="h3">
                              {dashboardData.numberOfItemsNeedingCapacityLoaded}
                            </Typography>
                          </Box>
                        </Badge>
                      ) : (
                        <Box className={classes.metric} data-testid="items-exceeding-threshold">
                          <Typography variant="h4" component="h3">
                            {dashboardData.numberOfItemsNeedingCapacityLoaded}
                          </Typography>
                        </Box>
                      )}

                      <Typography>Number of Parts Needing Capacity Loaded</Typography>
                    </Grid>
                  </Link>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      margin: '1rem',
    },
    header: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      backgroundColor: theme.palette.primary.light,
      borderRadius: '0.5rem 0.5rem 0 0',
    },
    metric: {
      border: `0.125rem solid ${theme.palette.primary.main}`,
      borderRadius: '0.625rem',
      padding: '0.5rem 1rem',
      marginBottom: '0.5rem',
    },
    metricsContainer: {
      minWidth: '38.75rem',
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-around',
      '& .MuiLink-root:hover': {
        textDecoration: 'none',
        '& .MuiBox-root': {
          boxShadow: `${theme.shadows[2]}`,
          transition: `all .2s ${theme.transitions.easing.easeInOut}`,
          borderRadius: '12px',
          background: theme.palette.primary.main,
          '& h3': {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
    individualMetricsContainers: {
      width: '12.9375rem',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      padding: '0.5rem',
      '& .MuiBadge-colorError': {
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
      },
    },
  }),
);
