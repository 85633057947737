import { Switch } from '@material-ui/core';
import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';
import { useDispatch, useSelector } from 'react-redux';
import { IPartDetail } from '../../services/generated/ApiClientGenerated';
import { RootState } from '../../store';
import { updatePartDetailsAction } from './partDetailSlice';

const MonthlyFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value, row, ...others }) => {
    const dispatch = useDispatch();
    const partDetails: IPartDetail[] = useSelector((r: RootState) => r.partDetail.partDetails.value);
    const supplierId: string = row.supplierId;
    const partNumber: string = row.partNumber;
    const partDetail = partDetails.filter((p) => p.partNumber === partNumber && p.supplierId === supplierId)[0];
    const handleToggle = () => {
        dispatch(
            updatePartDetailsAction({
                supplierId: supplierId,
                partDetailId: partNumber,
                body: {
                    ...partDetail,
                    monthlyCapacity: Array.isArray(partDetail.monthlyCapacity)
                        ? undefined
                        : new Array(12).fill(Math.floor((partDetail.annualCapacity ?? 0) / 12)),
                    annualCapacity: Array.isArray(partDetail.monthlyCapacity)
                        ? partDetail.monthlyCapacity.reduce((acc, v) => acc + v, 0)
                        : (Math.floor((row.annualCapacity ?? 0) / 12)) * 12,
                },
            }),
        );
    };
    return <Switch checked={value !== undefined} value={value} color={'primary'} onChange={handleToggle} />;
};

export const MonthlyTypeProvider: FC<DataTypeProviderProps> = (props) => (
    <DataTypeProvider formatterComponent={MonthlyFormatter} editorComponent={MonthlyFormatter} {...props} />
);
