import { Button, createStyles, FormControlLabel, Grid, makeStyles, Switch, TextField, Theme } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { RouterLocation } from 'connected-react-router';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RangeSlider } from '../../components/reusable/rangeSlider';

import { IPartDetailFilterset, ISupplierIdName } from '../../services/generated/ApiClientGenerated';
import { useAppData } from '../../services/hooks';
import { RootState } from '../../store';
import { RoleGuard } from '../auth/roleGuard';
import { searchVendorNumbersAction } from '../detail/detailSlice';
import {
  clearPartDetailFiltersAction,
  getAvailablePartDetailFiltersAction,
  getPartDetailsAction,
  setPartDetailsFiltersAction,
  loadPartDetailFiltersFromUrlAction,
} from './partDetailSlice';
import LocationState = History.LocationState;

export const PartDetailFilterModule = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const availableFilters = useAppData((r) => r.partDetail.availableFilters);
  const filters = useAppData((r) => r.partDetail.filters);
  const availableSuppliers = useAppData((r) => r.detail.availableVendorNumbers);
  const [baseSupplierId, setBaseSupplierId] = useState<ISupplierIdName>({
    baseSupplierId: Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : '',
  });
  const locationState: RouterLocation<LocationState> = useAppData((r: RootState) => r.router.location);
  const params = new URLSearchParams(locationState.search);

  useEffect(() => {
    dispatch(loadPartDetailFiltersFromUrlAction());
  }, [params.entries]);

  const keyConstraints: string[] = useAppData((r) => r.partDetail.keyConstraints);
  const mitigationMeasures: string[] = useAppData((r) => r.partDetail.mitigationMeasures);

  useEffect(() => {
    if (!baseSupplierId || !baseSupplierId.baseSupplierId) {
      setBaseSupplierId({ baseSupplierId: Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : '' });
    }
  }, [filters.supplierIds]);

  useEffect(() => {
    dispatch(getAvailablePartDetailFiltersAction());
  }, [filters]);

  useEffect(() => {
    dispatch(getPartDetailsAction());
  }, [filters]);

  function handleFilterChange<TKey extends keyof IPartDetailFilterset>(key: TKey, value: IPartDetailFilterset[TKey]) {
    dispatch(setPartDetailsFiltersAction({ key, value }));
  }

  function handleDoubleFilterChange<TKey1 extends keyof IPartDetailFilterset, TKey2 extends keyof IPartDetailFilterset>(
    keyOne: TKey1,
    keyTwo: TKey2,
    value: [IPartDetailFilterset[TKey1], IPartDetailFilterset[TKey2]],
  ) {
    handleFilterChange(keyOne, value[0]);
    handleFilterChange(keyTwo, value[1]);
  }

  const handleClearFilters = () => {
    dispatch(clearPartDetailFiltersAction());
  };

  const handleUpdateSuppliers = (supplierId: string) => {
    dispatch(searchVendorNumbersAction(supplierId));
  };

  const getPartNumbers = (partNumbersArray: string[] | undefined) => {
    // creates new array without duplicate part numbers
    let newArr: any[];
    if (partNumbersArray) {
      newArr = [...new Set(partNumbersArray)];
    } else {
      newArr = ['0'];
    }
    return newArr;
  };

  function getOptionLabel(o: ISupplierIdName) {
    return `${o.baseSupplierId ? o.baseSupplierId : ''}${o.name ? ` - ${o.name}` : ''}`;
  }

  return (
    <div className={classes.filterWrapper}>
      <Grid container direction={'column'} spacing={1} data-tut={'reactour__filter-Module'}>
        <Grid item>
          <RoleGuard role="Supplier">
            <Autocomplete
              id="vendor-number-or-name-select-Supplier"
              clearOnBlur
              fullWidth
              disabled={typeof availableFilters.supplierIds === 'undefined' || availableFilters.supplierIds.length <= 1}
              options={availableFilters?.supplierIds || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={filters.supplierIds ? filters.supplierIds[0] : 'Supplier'}
                  variant="outlined"
                />
              )}
              onChange={(event, options) => {
                if (!options) {
                  handleFilterChange('supplierIds', []);
                  return;
                }
                handleFilterChange('supplierIds', [options]);
              }}
              value={Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : ''}
            />
          </RoleGuard>
          <RoleGuard role="Polaris">
            <Autocomplete
              classes={{ noOptions: classes.autocompleteNoOptions }}
              noOptionsText={'No matching options. Try another search.'}
              id="vendor-number-or-name-select-Polaris"
              // clearOnBlur
              fullWidth
              autoComplete
              includeInputInList
              filterSelectedOptions
              filterOptions={(x) => x}
              options={availableSuppliers}
              getOptionLabel={getOptionLabel}
              renderInput={(params) => <TextField {...params} label="Supplier" variant="outlined" />}
              onInputChange={(e, newValue) => {
                handleUpdateSuppliers(newValue);
              }}
              onChange={(event, options) => {
                if (!options || !options.baseSupplierId) {
                  handleFilterChange('supplierIds', []);
                  return;
                }
                handleFilterChange('supplierIds', [options.baseSupplierId]);
                setBaseSupplierId(options);
              }}
              value={baseSupplierId}
            />
          </RoleGuard>
        </Grid>
        <Grid item>
          <Autocomplete
            id="part-number-select"
            clearOnBlur
            multiple
            aria-label={'part-number-select'}
            fullWidth
            options={getPartNumbers(availableFilters.partNumber)}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => <TextField {...params} label="Part Number" variant="outlined" />}
            value={filters.partNumber || []}
            onChange={(event, options) => {
              if (!options) {
                return;
              }
              handleFilterChange(
                'partNumber',
                options.map((option: string) => option),
              );
            }}
          />
        </Grid>

        <Grid item>
          <Autocomplete
            id="keyConstraint-select"
            clearOnBlur
            multiple
            aria-label={'key-constraints-select'}
            data-tut={'reactour__keyConstraints'}
            fullWidth
            options={keyConstraints}
            getOptionLabel={(option) => option.toString()}
            value={filters.keyConstraints ?? []}
            onChange={(e, options) => {
              if (!options) return;
              handleFilterChange(
                'keyConstraints',
                options.map((option: string) => option),
              );
            }}
            renderInput={(params) => <TextField {...params} label="Key Constraints" variant="outlined" />}
          />
        </Grid>

        <Grid item>
          <Autocomplete
            id="mitigationMeasures-select"
            clearOnBlur
            multiple
            aria-label={'mitigation-measures-select'}
            data-tut={'reactour__mitigationMeasures'}
            fullWidth
            options={mitigationMeasures}
            getOptionLabel={(option) => option.toString()}
            value={filters.mitigationMeasures ?? []}
            onChange={(e, options) => {
              if (!options) return;
              handleFilterChange(
                'mitigationMeasures',
                options.map((option: string) => option),
              );
            }}
            renderInput={(params) => <TextField {...params} label="Mitigation Measures" variant="outlined" />}
          />
        </Grid>
        {availableFilters.minAnnualCapacity &&
        availableFilters.maxAnnualCapacity &&
        availableFilters.minAnnualCapacity !== availableFilters.maxAnnualCapacity ? (
          <Grid item>
            <RangeSlider
              label={'Annual Capacity'}
              data-tut={'reactour__annualCapacity'}
              value={[
                filters.minAnnualCapacity || availableFilters.minAnnualCapacity,
                filters.maxAnnualCapacity || availableFilters.maxAnnualCapacity,
              ]}
              onChange={(value) => handleDoubleFilterChange('minAnnualCapacity', 'maxAnnualCapacity', value)}
              min={availableFilters.minAnnualCapacity}
              max={availableFilters.maxAnnualCapacity}
              step={1}
            />
          </Grid>
        ) : null}

        <Grid item className={classes.toggle}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={Boolean(filters.hasMonthlyVariability)}
                onChange={() =>
                  handleFilterChange('hasMonthlyVariability', filters.hasMonthlyVariability ? undefined : true)
                }
                name="has-monthly-variability"
                color="primary"
              />
            }
            data-tut={'reactour__hasMonthlyVariability'}
            label="Has monthly variability"
          />
        </Grid>

        <Grid item className={classes.toggle}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={typeof filters.hasAnnualCapacity !== 'undefined' ? !filters.hasAnnualCapacity : false}
                onChange={() => {
                  if (filters.hasAnnualCapacity ?? true) {
                    handleFilterChange('hasMonthlyVariability', false);
                    handleFilterChange('hasAnnualCapacity', false);
                  } else {
                    handleFilterChange('hasMonthlyVariability', undefined);
                    handleFilterChange('hasAnnualCapacity', undefined);
                  }
                }}
                name="no-annual-capacity-loaded"
                color="primary"
              />
            }
            data-tut={'reactour__noCapacityLoaded'}
            label="No Capacity Loaded"
          />
        </Grid>
      </Grid>

      <Grid container direction={'row'} justifyContent={'center'} className={classes.clearFilterContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClearFilters}
          disabled={(filters.supplierIds?.length ?? 0) == 0}
        >
          Clear Filters
        </Button>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteNoOptions: {
      color: theme.palette.text.primary,
    },
    inputLabel: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    clearFilterContainer: {
      marginTop: theme.spacing(2.5),
      display: 'flex',
    },
    filterWrapper: {
      '& .MuiAutocomplete-noOptions': {
        color: theme.palette.text.primary,
      },
      margin: theme.spacing(0, 1, 0, 0),
      '& .MuiFormLabel-root': {
        color: theme.palette.secondary.main,
      },
    },
    inputField: {
      width: '6.25rem',
    },
    switch: {
      '& .MuiFormControlLabel-label': {
        lineHeight: '1',
      },
    },
    chipSelect: {
      width: '100%',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    toggle: {
      margin: theme.spacing(0, 1),
    },
  }),
);
