import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FileParameter,
  IAddNotesRequest,
  ISupplierFilterset,
  ISupplierIdName,
  ISupplierInfo,
  ListSortDirection,
  SupplierSortOption,
} from '../../services/generated/ApiClientGenerated';
import { Loadable, PaginatableLoadable } from '../../services/shared/baseTypes';

export type SerializableSupplierInfo = Omit<ISupplierInfo, 'dateUpdated'> & {
  dateUpdated?: number; // Override as timestamp
};

export type DetailState = {
  availableFilters: SerializableSupplierFilterset; // update this, use for min & max - show us all options
  availableVendorNumbers: ISupplierIdName[];
  expandedRowIds: (string | number)[] | undefined;
  file: Loadable<FileParameter[]>;
  fileDownload: {
    loading: boolean;
    loaded: boolean;
  };
  filters: SerializableSupplierFilterset; // use for value and onchange, filter supplier data
  isTourOpen: boolean;
  notes: {
    loading: boolean;
    loaded: boolean;
  };
  pageSizes: number[];
  supplier: PaginatableLoadable<SerializableSupplierInfo>;
  supplierNumber?: number;
};

export type DownloadType = {
  supplierId: string;
};

export type SerializableSupplierFilterset = Omit<
  ISupplierFilterset,
  'minTenderDate' | 'maxTenderDate' | 'minDueDate' | 'maxDueDate' | 'maxDateUpdated' | 'minDateUpdated'
> & {
  minTenderDate?: number;
  maxTenderDate?: number;
  minDueDate?: number;
  maxDueDate?: number;
  maxDateUpdated?: number;
  minDateUpdated?: number;
};

export type UpdateCapacityType = {
  supplierId: string;
  partNumber: string;
  demandId: string;
  weeklyCapacity: number;
  rowId: number;
};

export type UpdatedCapacity = {
  supplierId: string;
  demandId: string;
  weeklyCapacity: number;
  rowId: number;
};

export type Tasks = {
  supplierId: string;
  taskDescription: string;
};

export type Sorting = {
  direction: string;
  columnName: string;
};

export const detailSlice = createSlice({
  name: 'detail',
  initialState: {
    availableFilters: {} as SerializableSupplierFilterset,
    availableVendorNumbers: [],
    expandedRowIds: [],
    file: {
      loading: false,
      loaded: false,
      value: [],
    },
    fileDownload: {
      loading: false,
      loaded: false,
    },
    filters: {
      supplierIds: [],
      partNumber: [],
      sortBy: SupplierSortOption.Undefined,
      sortDirection: ListSortDirection.Ascending,
    } as SerializableSupplierFilterset,
    isTourOpen: false,
    notes: {
      loading: false,
      loaded: false,
    },
    pageSizes: [5, 10, 15, 50, 100],
    supplier: {
      loading: false,
      loaded: false,
      value: [],
      pageSize: 50,
      totalCount: 0,
    },
  } as DetailState,
  reducers: {
    clearFiltersAction: (state) => {
      state.filters = {
        supplierIds: state.filters.supplierIds,
      } as SerializableSupplierFilterset;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadCsvAction: (state, action: PayloadAction<DownloadType>) => {
      state.fileDownload.loading = true;
      state.fileDownload.loaded = false;
    },
    failedUploadCSVAction: (state) => {
      state.file.loading = false;
      state.file.loaded = false;
    },
    getSupplierDataAction: (state) => {
      state.supplier.loading = true;
      state.supplier.loaded = false;
    },
    getAvailableFiltersAction: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initializeAvailableFiltersAction: (state, action: PayloadAction<string[]>) => {},
    loadFiltersFromUrlAction: () => {},
    searchVendorNumbersAction: (state, action: PayloadAction<string>) => {},
    setExpandedRowIdsAction: (state, action: PayloadAction<(string | number)[]>) => {
      state.expandedRowIds = action.payload;
    },
    setFiltersAction: (
      state,
      action: PayloadAction<{
        key: keyof SerializableSupplierFilterset;
        value: SerializableSupplierFilterset[keyof SerializableSupplierFilterset];
      }>,
    ) => {
      (state.filters as any)[action.payload.key] = action.payload.value;
    },
    setIsTourOpenAction: (state, action: PayloadAction<boolean>) => {
      state.isTourOpen = action.payload;
    },
    setPageIndexAction: (state, action: PayloadAction<number>) => {
      state.supplier.loading = true;
      state.supplier.loaded = false;
      state.filters.page = action.payload;
    },
    setPageSizeAction: (state, action: PayloadAction<number>) => {
      state.supplier.loading = true;
      state.supplier.loaded = false;
      state.filters.pageSize = action.payload;
    },
    setPaginationSuccessAction: (state, action: PayloadAction<number | undefined>) => {
      state.supplier.totalCount = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setSortAction: (state, action: PayloadAction<Sorting[]>) => {
      if (action.payload.length === 0) {
        state.filters.sortBy = SupplierSortOption.Undefined;
        state.filters.sortDirection = ListSortDirection.Descending;
      } else {
        let sortOption = action.payload[0].columnName;
        if (sortOption == 'weeklyDemand') {
          sortOption = 'Demand';
        }
        state.filters.sortBy = sortOption.replace(/^./, (m) => m.toUpperCase()) as SupplierSortOption;
        state.filters.sortDirection =
          action.payload[0].direction === 'asc' ? ListSortDirection.Ascending : ListSortDirection.Descending;
      }
    },
    setSupplierCapacityAction: (state, action: PayloadAction<UpdatedCapacity>) => {
      //update state to display new capacity
      const newSupplierData = [...state.supplier.value];
      newSupplierData[action.payload.rowId].weeklyCapacity = Number(action.payload.weeklyCapacity);
      state.supplier.value = [...newSupplierData];
    },
    stopLoadingSupplierDataAction: (state) => {
      state.supplier = {
        ...state.supplier,
        loading: false,
        loaded: true,
        value: [],
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitNotesAction: (state, action: PayloadAction<IAddNotesRequest>) => {
      state.notes.loading = true;
      state.notes.loaded = false;
    },
    successDownloadAction: (state) => {
      state.fileDownload.loading = false;
      state.fileDownload.loaded = true;
    },
    successGetSupplierDataAction: (state, action: PayloadAction<SerializableSupplierInfo[]>) => {
      state.supplier = {
        ...state.supplier,
        loading: false,
        loaded: true,
        value: action.payload,
      };
    },
    stopNotesLoadingAction: (state) => {
      state.notes.loading = false;
      state.notes.loaded = true;
    },
    successSetAvailableFiltersAction: (state, action: PayloadAction<ISupplierFilterset>) => {
      state.availableFilters = {
        ...action.payload,
        minTenderDate: action.payload.minTenderDate ? action.payload.minTenderDate?.getTime() : undefined,
        maxTenderDate: action.payload.maxTenderDate ? action.payload.maxTenderDate.getTime() : undefined,
        minDueDate: action.payload.minDueDate ? action.payload.minDueDate.getTime() : undefined,
        maxDueDate: action.payload.maxDueDate ? action.payload.maxDueDate.getTime() : undefined,
        minDateUpdated: action.payload.minDateUpdated ? action.payload.minDateUpdated.getTime() : undefined,
        maxDateUpdated: action.payload.maxDateUpdated ? action.payload.maxDateUpdated.getTime() : undefined,
      };
    },
    successSearchVendorNumbersAction: (state, action: PayloadAction<ISupplierIdName[]>) => {
      state.availableVendorNumbers = action.payload;
    },
    successUploadCSVAction: (state) => {
      state.file.loading = false;
      state.file.loaded = true;
    },
    updateSupplierDataAction: (state, action: PayloadAction<UpdateCapacityType>) => {
      state.supplier.value = state.supplier.value.map((row, index) => {
        if (index === action.payload.rowId && row.supplierId && row.partNumber && row.demandId) {
          return {
            ...row,
            weeklyCapacity: action.payload.weeklyCapacity,
            demandUtilization:
              action.payload.weeklyCapacity != 0 && row.weeklyDemand
                ? row.weeklyDemand / action.payload.weeklyCapacity
                : undefined,
          };
        } else {
          return row;
        }
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadCSVAction: (state, action: PayloadAction<FileParameter>) => {
      state.file.loading = true;
      state.file.loaded = false;
    },
    setLastUpdatedAction: (state, action: PayloadAction<number>) => {
      state.supplier.value = state.supplier.value.map((row, index) => {
        if (index === action.payload) {
          return {
            ...row,
            lastUpdatedBy: 'you',
            lastUpdatedDate: new Date(),
          };
        } else {
          return row;
        }
      });
    },
  },
});

export const {
  clearFiltersAction,
  downloadCsvAction,
  failedUploadCSVAction,
  getAvailableFiltersAction,
  getSupplierDataAction,
  initializeAvailableFiltersAction,
  loadFiltersFromUrlAction,
  searchVendorNumbersAction,
  setExpandedRowIdsAction,
  setFiltersAction,
  setIsTourOpenAction,
  setLastUpdatedAction,
  setPageIndexAction,
  setPageSizeAction,
  setPaginationSuccessAction,
  setSortAction,
  setSupplierCapacityAction,
  stopLoadingSupplierDataAction,
  stopNotesLoadingAction,
  submitNotesAction,
  successDownloadAction,
  successGetSupplierDataAction,
  successSearchVendorNumbersAction,
  successSetAvailableFiltersAction,
  successUploadCSVAction,
  updateSupplierDataAction,
  uploadCSVAction,
} = detailSlice.actions;
