import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC, useState } from 'react';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import MessageIcon from '@material-ui/icons/Message';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MapIcon from '@material-ui/icons/Map';
import { useDispatch } from 'react-redux';
import { isDashboardTourOpen } from '../../features/dashboard/dashboardSlice';
import { isPartDetailTourOpen } from '../../features/partDetails/partDetailSlice';
import { useAppData } from '../../services/hooks';
import { setIsTourOpenAction } from '../../features/detail/detailSlice';

type SpeedDialProps = {
  className?: string;
  dashboard?: boolean;
  partDetail?: boolean;
  planningDetail?: boolean;
};

export const SpeedDialComponent: FC<SpeedDialProps> = ({ className, dashboard, partDetail, planningDetail }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const isTourOpen = useAppData((r) => r.detail.isTourOpen);
  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box className={classes.root}>
      <SpeedDial
        className={className}
        ariaLabel="SpeedDial-selection"
        icon={
          <div className={classes.helpMenuContainer} data-tut={'reactour__help-Menu'}>
            <Typography component={'h2'} variant={'h5'}>
              ?
            </Typography>
          </div>
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
          key={'leave-feedback'}
          icon={
            <Link
              aria-label={'leave-feedback'}
              rel="noreferrer"
              target="_blank"
              href="https://forms.office.com/pages/responsepage.aspx?id=TIz3hRGtNUeWJAssEWEd_8j2TVbQuSdKhZTzaSetv-RUMFNGTUJDMUVETTM5UTNGQk1JNzVCQk5PUi4u"
            >
              <MessageIcon />
            </Link>
          }
          tooltipTitle={'Leave Feedback'}
          tooltipOpen
          onClick={handleClose}
        />

        <SpeedDialAction
          key={'contact-support'}
          icon={
            <Link role={'link'} aria-label="Contact support link" onClick={handleDialogOpen}>
              <ContactSupportIcon />
            </Link>
          }
          tooltipTitle={'Contact Support'}
          tooltipOpen
          onClick={handleClose}
        />

        <SpeedDialAction
          key={'quick-reference-guide'}
          icon={
            <Link
              aria-label={'download-quick-reference-guide'}
              href={process.env.PUBLIC_URL + '/assets/CapacityCollectionToolQuickReference.pdf'}
              download
            >
              <PictureAsPdfIcon />
            </Link>
          }
          tooltipTitle={'Download Quick Reference Guide'}
          tooltipOpen
          onClick={handleClose}
        />

        {dashboard && (
          <SpeedDialAction
            key={'take-guided-tour'}
            tooltipTitle={'Take Guided Tour'}
            FabProps={{ 'aria-label': 'take guided tour' }}
            onClick={() => {
              dispatch(isDashboardTourOpen(!isTourOpen));
              handleClose();
            }}
            icon={<MapIcon />}
            tooltipOpen
          />
        )}

        {partDetail && (
          <SpeedDialAction
            key={'take-guided-tour'}
            tooltipTitle={'Take Guided Tour'}
            FabProps={{ 'aria-label': 'take guided tour' }}
            onClick={() => {
              dispatch(isPartDetailTourOpen(!isTourOpen));
              handleClose();
            }}
            icon={<MapIcon />}
            tooltipOpen
          />
        )}

        {planningDetail && (
          <SpeedDialAction
            key={'take-guided-tour'}
            tooltipTitle={'Take Guided Tour'}
            FabProps={{ 'aria-label': 'take guided tour' }}
            onClick={() => {
              dispatch(setIsTourOpenAction(!isTourOpen));
              handleClose();
            }}
            icon={<MapIcon />}
            tooltipOpen
          />
        )}
      </SpeedDial>

      <Dialog onClose={handleDialogClose} aria-labelledby={'Contact Support'} open={dialogOpen}>
        <DialogTitle>{'Contact Support'}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogBody}>
            Contact{' '}
            <Link href="mailto:capacity.collection@polaris.com" rel="noreferrer" target="_blank">
              capacity.collection@polaris.com
            </Link>{' '}
            with questions.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpMenuContainer: {
      width: '4.6875rem',
      height: '3.125rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    root: {
      position: 'absolute',
      '& .MuiFab-root': {
        lineHeight: 'initial',
      },
      '& .MuiSvgIcon-root': {
        color: 'white',
      },
      '& .MuiSpeedDialAction-fab': {
        background: theme.palette.primary.main,
      },
      '& .MuiSpeedDialAction-fab:hover': {
        background: theme.palette.primary.dark,
      },
      '& .MuiSpeedDialAction-staticTooltipLabel': {
        color: theme.palette.text.primary,
        padding: theme.spacing(0, 1),
        fontSize: '0.875rem',
        width: 'max-content',
      },
    },
    quickRefGuide: {
      fontWeight: theme.typography.fontWeightBold,
    },
    dialogBody: {
      color: theme.palette.text.primary,
      padding: theme.spacing(1, 0),
    },
  }),
);
