import { Close } from '@material-ui/icons';
import Comment from '@material-ui/icons/Comment';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
    Button,
    Chip,
    createStyles,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    Select,
    Theme,
    MenuItem,
    Typography,
    withStyles,
    InputBase,
    Input,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { PartDetail } from '../../services/generated/ApiClientGenerated';
import { useAppData } from '../../services/hooks';
import { CommentDialog } from '../detail/commentDialog';
import Edit from '@material-ui/icons/Edit';
import { showToastAction } from '../globalSlice';
import { PartDetailCommentDialog } from './partDetailCommentDialog';
import { PartDetailLastUpdated } from './partDetailLastUpdated';
import { KeyConstraints, MitigationMeasures, updatePartDetailsAction } from './partDetailSlice';

export const PartDetailRow: FC<{ row: PartDetail }> = ({ row }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const loading = useAppData((r) => r.detail.notes.loading);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const keyConstraints = useAppData((r) => r.partDetail.keyConstraints);
    const mitigationMeasures = useAppData((r) => r.partDetail.mitigationMeasures);
    const [keyConstraint, setKeyConstraint] = useState('');
    const [mitigationMeasure, setMitigationMeasure] = useState('');

    const createTagHandler = (tagType: 'mitigationMeasures' | 'keyConstraints') => (value: string, isDelete: boolean) => {
        const newTagArray = row[tagType]?.slice();
        if (!row.baseSupplierId || newTagArray === undefined) {
            return;
        }
        if (isDelete) {
            const index = newTagArray.indexOf(value);
            if (index > -1) {
                newTagArray.splice(index, 1);
            }
        } else {
            newTagArray.push(value);
        }

        dispatch(
            updatePartDetailsAction({
                supplierId: row.supplierId!,
                partDetailId: row.partNumber!,
                body: {
                    ...row,
                    [tagType]: newTagArray,
                },
            }),
        );
    };

    const handleKeyChange = createTagHandler('keyConstraints');
    const handleMitigationChange = createTagHandler('mitigationMeasures');

    const handleMonthlyChange = (value: string, index: number) => {
        if (row.baseSupplierId === undefined || row.monthlyCapacity === undefined) {
            return;
        }
        const isPositiveNumber = /^\d+$/;
        const newMonthlyCapacity = row.monthlyCapacity.slice();
        if (isPositiveNumber.test(value)) {
            newMonthlyCapacity[index] = parseInt(value);
            dispatch(
                updatePartDetailsAction({
                    supplierId: row.supplierId!,
                    partDetailId: row.partNumber!,
                    body: {
                        ...row,
                        programmedBaseline: true,
                        monthlyCapacity: newMonthlyCapacity,
                        annualCapacity: newMonthlyCapacity.reduce((acc, v) => acc + v, 0),
                    },
                }),
            );
        } else {
            dispatch(
                showToastAction({
                    message: 'There was an issue updating monthly capacity',
                    severity: 'warning',
                    title: 'Invalid data entry',
                    body: 'Enter a valid whole number of 0 or greater',
                }),
            );
        }
    };

    const CustomInput = withStyles((theme) => ({
        input: {
            borderRadius: 0,
            borderColor: theme.palette.primary.main,
            borderWidth: '1px',
            borderStyle: 'solid',
            width: '100px',
        },
    }))(InputBase);

    return (
        <Grid container direction={'row'} spacing={4} justifyContent={'center'} className={classes.commentsContainer}>
            {row.monthlyCapacity && (
                <Grid item xs={12} container direction={'row'}>
                    <Grid item xs={2} className={classes.detailHeader}>
                        Stated Monthly Capacity:
                    </Grid>
                    <Grid item container direction={'row'} xs={10}>
                        {row.monthlyCapacity.map((x, i) => (
                            <Grid container direction={'column'} xs={1} key={`${row.baseSupplierId}-${months[i]}`}>
                                <Grid item className={classes.months}>
                                    {months[i]}
                                </Grid>
                                <Grid item className={classes.monthlyCapacity}>
                                    <Input defaultValue={x} onChange={(event) => handleMonthlyChange(event.target.value, i)} />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} container direction={'row'}>
                <Grid item xs={2} className={classes.tagHeader}>
                    Key Constraints:
                </Grid>
                <Grid item container direction={'row'} xs={10}>
                    {row.keyConstraints?.map((x) => (
                        <Chip
                            key={x}
                            variant={'outlined'}
                            className={classes.chip}
                            label={x}
                            deleteIcon={<Close />}
                            onDelete={() => handleKeyChange(x, true)}
                        />
                    ))}
                    <FormControl>
                        <Select
                            autoWidth
                            label={'Add Key Constraint'}
                            variant={'outlined'}
                            value={keyConstraint}
                            placeholder={'Key Constraints'}
                            labelId="keyConstraintLabel"
                            input={<CustomInput />}
                            onChange={(e) => handleKeyChange(e.target.value as string, false)}
                        >
                            {keyConstraints
                                .filter((x: KeyConstraints) => !row.keyConstraints?.includes(x))
                                .map((x: KeyConstraints) => (
                                    <MenuItem key={`${row.baseSupplierId}-${x}`} value={x}>
                                        {x}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction={'row'}>
                <Grid item xs={2} className={classes.tagHeader}>
                    Mitigation Measures:
                </Grid>
                <Grid item container direction={'row'} xs={10}>
                    {row.mitigationMeasures?.map((x) => (
                        <Chip
                            key={x}
                            variant={'outlined'}
                            className={classes.chip}
                            label={x}
                            deleteIcon={<Close />}
                            onDelete={() => handleMitigationChange(x, true)}
                        />
                    ))}
                    <FormControl>
                        <Select
                            autoWidth
                            variant={'outlined'}
                            value={mitigationMeasure}
                            placeholder={'Mitigation Measures'}
                            labelId="MMLabel"
                            input={<CustomInput />}
                            onChange={(e) => handleMitigationChange(e.target.value as string, false)}
                        >
                            {mitigationMeasures
                                .filter((x: MitigationMeasures) => !row.mitigationMeasures?.includes(x))
                                .map((x: MitigationMeasures) => (
                                    <MenuItem key={`${row.baseSupplierId}-${x}`} value={x}>
                                        {x}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {row.notes ? (
                <>
                    <Grid item xs={6} className={classes.notesContainer}>
                        <Grid item xs={'auto'} className={classes.notesItem}>
                            <Typography>{row.notes}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => setCommentDialogOpen(true)}>
                                <Edit color={'primary'} />
                            </IconButton>
                            <PartDetailCommentDialog
                                open={commentDialogOpen}
                                row={row}
                                onClose={() => setCommentDialogOpen(false)}
                                title="Edit note"
                                confirmationButtonText="Save"
                                content={row.notes}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6} className={classes.lastUpdatedContainer}>
                        <PartDetailLastUpdated row={row} />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={6} className={classes.notesContainer}>
                        <Button
                            data-tut={'reactour__add-Note'}
                            variant={'outlined'}
                            color={'primary'}
                            onClick={() => setCommentDialogOpen(true)}
                        >
                            <Comment className={classes.commentIcon} color={'primary'} /> Add Note
                        </Button>
                        <PartDetailCommentDialog
                            open={commentDialogOpen}
                            row={row}
                            onClose={() => setCommentDialogOpen(false)}
                            title="Add note"
                            confirmationButtonText="Save"
                            placeholder={'Please enter your note here'}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.lastUpdatedContainer}>
                        <PartDetailLastUpdated row={row} />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commentIcon: {
            marginRight: theme.spacing(1),
        },
        commentsContainer: {
            padding: theme.spacing(0.5, 5),
        },
        commentsSubheader: theme.typography.body1,
        notesContainer: {
            display: 'flex',
            alignItems: 'center',
            wordBreak: 'break-word',
        },
        lastUpdatedContainer: {
            alignSelf: 'flex-end',
            wordBreak: 'break-word',
        },
        notesItem: {
            marginRight: theme.spacing(1),
        },
        detailHeader: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.hint,
            paddingTop: '20px',
        },
        tagHeader: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.hint,
        },
        months: {
            height: '30px',
            borderRight: '1px solid white',
            paddingLeft: '10px',
            color: theme.palette.text.hint,
            fontWeight: theme.typography.fontWeightBold,
        },
        monthlyCapacity: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.text.hint,
            height: '40px',
            borderRight: '1px solid white',
            padding: '10px',
        },
        chip: {
            borderColor: theme.palette.primary.main,
            borderRadius: '0',
            marginRight: '10px',
        },
    }),
);
