import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { SerializableSupplierInfo } from './detailSlice';

type LastUpdatedProps = {
  row: SerializableSupplierInfo;
};

export const LastUpdated: FC<LastUpdatedProps> = ({ row }) => {
  const classes = useStyles();
  const lastUpdatedUser = row.lastUpdatedBy !== 'anonymous' ? row.lastUpdatedBy : `Supplier ID: ${row.supplierId}`;

  return (
    <Grid
      container
      direction={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
      spacing={1}
      className={classes.lastUpdatedContainer}
    >
      {row.dateUpdated ? (
        <div className={classes.lastUpdatedWrapper} data-tut={'reactour__last-Updated'}>
          <Grid item className={classes.icon}>
            <UpdateIcon color={'primary'} />
          </Grid>
          <Grid item>
            <Typography className={classes.lastUpdated} component={'h2'} variant={'subtitle1'}>
              Last Updated: {format(row.dateUpdated, 'yyyy-MM-dd')} {lastUpdatedUser ? `by ${lastUpdatedUser}` : ''}
            </Typography>
          </Grid>
        </div>
      ) : (
        ''
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      display: 'flex',
      marginRight: '0.3125rem',
    },
    lastUpdated: {
      fontStyle: 'italic',
      fontSize: 'small',
    },
    lastUpdatedContainer: {
      marginRight: theme.spacing(1),
    },
    lastUpdatedWrapper: {
      display: 'flex',
    },
  }),
);
