import React, { useRef } from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { FileParameter, SupplierFilterset } from '../../services/generated/ApiClientGenerated';

type Props = {
  label: string;
  acceptValues?: string[];
  onChangeFile: (file: FileParameter) => void;
  filters: SupplierFilterset;
};

export const UploadFileButton = ({
  acceptValues,
  children,
  label,
  onChangeFile,
  filters,
  ...other
}: Props & ButtonProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const fileList = event.target.files;
    if (!fileList || fileList.length !== 1 || !fileList[0]) return;
    const file = fileList[0];
    onChangeFile({ data: file, fileName: file.name });
    inputRef.current!.value = '';
  };

  return (
    <label htmlFor="upload-file">
      <input
        name="upload-file"
        aria-label="file"
        hidden
        accept={acceptValues?.join(',')}
        onChange={onChangeInternal}
        type="file"
        ref={inputRef}
      />
      <Button
        variant="contained"
        color="primary"
        component="span"
        {...other}
        disabled={(filters.supplierIds?.length ?? 0) == 0}
        onClick={handleUpload}
      >
        {children}
      </Button>
    </label>
  );
};
