import { Button, createStyles, FormControlLabel, Grid, makeStyles, Switch, TextField, Theme } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { RouterLocation } from 'connected-react-router';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateRangeSlider } from '../../components/reusable/dateRangeSlider';
import { RangeSlider } from '../../components/reusable/rangeSlider';
import { ISupplierIdName } from '../../services/generated/ApiClientGenerated';
import { useAppData } from '../../services/hooks';
import { RootState } from '../../store';
import { RoleGuard } from '../auth/roleGuard';
import {
  clearFiltersAction,
  getSupplierDataAction,
  loadFiltersFromUrlAction,
  searchVendorNumbersAction,
  SerializableSupplierFilterset,
  setFiltersAction,
} from './detailSlice';
import LocationState = History.LocationState;

export const FilterModule = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const availableFilters = useAppData((r) => r.detail.availableFilters);
  const filters = useAppData((r) => r.detail.filters);
  const availableSuppliers = useAppData((r) => r.detail.availableVendorNumbers);
  const [baseSupplierId, setBaseSupplierId] = useState<ISupplierIdName>({
    baseSupplierId: Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : '',
  });
  const locationState: RouterLocation<LocationState> = useAppData((r: RootState) => r.router.location);
  const params = new URLSearchParams(locationState.search);

  useEffect(() => {
    if (!baseSupplierId || !baseSupplierId.baseSupplierId)
      setBaseSupplierId({ baseSupplierId: Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : '' });
  }, [filters.supplierIds]);

  useEffect(() => {
    dispatch(loadFiltersFromUrlAction());
  }, [params.entries]);

  useEffect(() => {
    // Initialize supplier data, as necessary
    dispatch(getSupplierDataAction());
  }, [filters]);

  const handleDoubleFilterChange = (
    keyOne: keyof SerializableSupplierFilterset,
    keyTwo: keyof SerializableSupplierFilterset,
    value: SerializableSupplierFilterset[keyof SerializableSupplierFilterset][],
  ) => {
    handleFilterChange(keyOne, value[0]);
    handleFilterChange(keyTwo, value[1]);
  };

  const handleFilterChange = (
    key: keyof SerializableSupplierFilterset,
    value: SerializableSupplierFilterset[keyof SerializableSupplierFilterset],
  ) => {
    dispatch(setFiltersAction({ key, value }));
  };

  const clearFilters = () => {
    dispatch(clearFiltersAction());
  };

  const updateSuppliers = (supplierId: string) => {
    dispatch(searchVendorNumbersAction(supplierId));
  };

  const getPartNumbers = (partNumbersArray: string[] | undefined) => {
    // creates new array without duplicate part numbers
    let newArr: any[];
    if (partNumbersArray) {
      newArr = [...new Set(partNumbersArray)];
    } else {
      newArr = ['0'];
    }
    return newArr;
  };

  function getOptionLabel(o: ISupplierIdName) {
    return `${o.baseSupplierId ? o.baseSupplierId : ''}${o.name ? ` - ${o.name}` : ''}`;
  }

  return (
    <div className={classes.filterWrapper}>
      <Grid container direction={'column'} spacing={1} data-tut={'reactour__filter-Module'}>
        <Grid item>
          <RoleGuard role="Supplier">
            <Autocomplete
              id="vendor-number-or-name-select-Supplier"
              clearOnBlur
              fullWidth
              disabled={typeof availableFilters.supplierIds === 'undefined' || availableFilters.supplierIds.length <= 1}
              options={availableFilters?.supplierIds || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={filters.supplierIds ? filters.supplierIds[0] : 'Supplier'}
                  variant="outlined"
                />
              )}
              onChange={(event, options) => {
                if (!options) {
                  handleFilterChange('supplierIds', []);
                  return;
                }
                handleFilterChange('supplierIds', [options]);
              }}
              value={Array.isArray(filters.supplierIds) ? filters.supplierIds[0] : ''}
            />
          </RoleGuard>
          <RoleGuard role="Polaris">
            <Autocomplete
              classes={{ noOptions: classes.autocompleteNoOptions }}
              noOptionsText={'No matching options. Try another search.'}
              id="vendor-number-or-name-select-Polaris"
              // clearOnBlur
              fullWidth
              autoComplete
              includeInputInList
              filterSelectedOptions
              filterOptions={(x) => x}
              options={availableSuppliers}
              getOptionLabel={getOptionLabel}
              renderInput={(params) => <TextField {...params} label="Supplier" variant="outlined" />}
              onInputChange={(e, newValue) => {
                updateSuppliers(newValue);
              }}
              onChange={(event, options) => {
                if (!options || !options.baseSupplierId) {
                  handleFilterChange('supplierIds', []);
                  return;
                }
                handleFilterChange('supplierIds', [options.baseSupplierId]);
                setBaseSupplierId(options);
              }}
              value={baseSupplierId}
            />
          </RoleGuard>
        </Grid>
        <Grid item>
          <Autocomplete
            id="part-number-select"
            clearOnBlur
            multiple
            aria-label={'part-number-select'}
            fullWidth
            options={getPartNumbers(availableFilters.partNumber)}
            getOptionLabel={(option) => option.toString()}
            renderInput={(params) => <TextField {...params} label="Part Number" variant="outlined" />}
            value={filters.partNumber || []}
            onChange={(event, options) => {
              if (!options) {
                return;
              }
              handleFilterChange(
                'partNumber',
                options.map((option: string) => option),
              );
            }}
          />
        </Grid>
        {!isNaN(availableFilters.minTenderDate) &&
        !isNaN(availableFilters.maxTenderDate) &&
        availableFilters.minTenderDate !== availableFilters.maxTenderDate ? (
          <Grid item>
            <DateRangeSlider
              label={'Tender Date'}
              value={[
                filters?.minTenderDate || availableFilters?.minTenderDate,
                filters?.maxTenderDate || availableFilters?.maxTenderDate,
              ]}
              min={availableFilters?.minTenderDate}
              max={availableFilters?.maxTenderDate}
              onChange={(value) => {
                handleDoubleFilterChange('minTenderDate', 'maxTenderDate', value);
              }}
            />
          </Grid>
        ) : (
          ''
        )}
        {!isNaN(availableFilters.minDueDate) &&
        !isNaN(availableFilters.maxDueDate) &&
        availableFilters.minDueDate !== availableFilters.maxDueDate ? (
          <Grid item>
            <DateRangeSlider
              label={'Due Date'}
              value={[
                filters.minDueDate || availableFilters.minDueDate,
                filters.maxDueDate || availableFilters.maxDueDate,
              ]}
              min={availableFilters.minDueDate}
              max={availableFilters.maxDueDate}
              onChange={(value) => {
                handleDoubleFilterChange('minDueDate', 'maxDueDate', value);
              }}
            />
          </Grid>
        ) : (
          ''
        )}
        {!isNaN(availableFilters.minWeeklyDemand) &&
        !isNaN(availableFilters.maxWeeklyDemand) &&
        availableFilters.minWeeklyDemand !== availableFilters.maxWeeklyDemand ? (
          <Grid>
            <RangeSlider
              label={'Weekly Demand'}
              value={[
                filters.minWeeklyDemand || availableFilters.minWeeklyDemand,
                filters.maxWeeklyDemand || availableFilters.maxWeeklyDemand,
              ]}
              onChange={(value) => handleDoubleFilterChange('minWeeklyDemand', 'maxWeeklyDemand', value)}
              min={availableFilters.minWeeklyDemand}
              max={availableFilters.maxWeeklyDemand}
              step={10}
            />
          </Grid>
        ) : (
          <></>
        )}
        {!isNaN(availableFilters.minImpliedCapacity) &&
        !isNaN(availableFilters.maxImpliedCapacity) &&
        availableFilters.minImpliedCapacity !== availableFilters.maxImpliedCapacity ? (
          <Grid item>
            <RangeSlider
              label={'Implied Capacity'}
              value={[
                filters.minImpliedCapacity || availableFilters.minImpliedCapacity,
                filters.maxImpliedCapacity || availableFilters.maxImpliedCapacity,
              ]}
              onChange={(value) => handleDoubleFilterChange('minImpliedCapacity', 'maxImpliedCapacity', value)}
              min={availableFilters.minImpliedCapacity}
              max={availableFilters.maxImpliedCapacity}
              step={10}
            />
          </Grid>
        ) : (
          ''
        )}
        {!isNaN(availableFilters.minWeeklyCapacity) &&
        !isNaN(availableFilters.maxWeeklyCapacity) &&
        availableFilters.minWeeklyCapacity !== availableFilters.maxWeeklyCapacity ? (
          <Grid item>
            <Grid item>
              <RangeSlider
                label={'Weekly Capacity'}
                value={[
                  filters.minWeeklyCapacity || availableFilters.minWeeklyCapacity,
                  filters.maxWeeklyCapacity || availableFilters.maxWeeklyCapacity,
                ]}
                onChange={(value) => handleDoubleFilterChange('minWeeklyCapacity', 'maxWeeklyCapacity', value)}
                min={availableFilters.minWeeklyCapacity}
                max={availableFilters.maxWeeklyCapacity}
                step={10}
              />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Grid item className={classes.toggle}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={Boolean(filters.showOnlyNullWeeklyCapacity)}
                onChange={() =>
                  handleFilterChange('showOnlyNullWeeklyCapacity', Boolean(!filters.showOnlyNullWeeklyCapacity))
                }
                name="show-only-empty-fields"
                color="primary"
              />
            }
            data-tut={'reactour__blank-capacity'}
            label="Show only unloaded weekly capacity"
          />
        </Grid>
        {!isNaN(availableFilters.minDemandUtilization) &&
        !isNaN(availableFilters.maxDemandUtilization) &&
        availableFilters.minDemandUtilization !== availableFilters.maxDemandUtilization ? (
          <Grid item>
            <RangeSlider
              label={'Demand Utilization'}
              value={[
                filters.minDemandUtilization || availableFilters.minDemandUtilization,
                filters.maxDemandUtilization || availableFilters.maxDemandUtilization,
              ]}
              onChange={(value) => handleDoubleFilterChange('minDemandUtilization', 'maxDemandUtilization', value)}
              min={availableFilters.minDemandUtilization}
              max={availableFilters.maxDemandUtilization}
              step={1}
              percentageFormatter
            />
          </Grid>
        ) : (
          ''
        )}
        <Grid item className={classes.toggle}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={Boolean(filters.showOnlyStaleItems)}
                onChange={() => handleFilterChange('showOnlyStaleItems', Boolean(!filters.showOnlyStaleItems))}
                name="show-only-stale-data"
                color="primary"
              />
            }
            data-tut={'reactour__stale-data'}
            label="Show only stale data"
          />
        </Grid>
      </Grid>
      <Grid container direction={'row'} justifyContent={'center'} className={classes.clearFilterContainer}>
        <Button
          data-tut={'reactour__clear-filters'}
          variant="contained"
          color="primary"
          onClick={clearFilters}
          disabled={(filters.supplierIds?.length ?? 0) == 0}
        >
          Clear Filters
        </Button>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteNoOptions: {
      color: theme.palette.text.primary,
    },
    inputLabel: {
      padding: theme.spacing(0.5, 0.5, 0),
    },
    clearFilterContainer: {
      marginTop: theme.spacing(2.5),
      display: 'flex',
    },
    filterWrapper: {
      '& .MuiAutocomplete-noOptions': {
        color: theme.palette.text.primary,
      },
      margin: theme.spacing(0, 1, 0, 0),
      '& .MuiFormLabel-root': {
        color: theme.palette.secondary.main,
      },
    },
    inputField: {
      width: '6.25rem',
    },
    switch: {
      '& .MuiFormControlLabel-label': {
        lineHeight: '1',
      },
    },
    toggle: {
      margin: theme.spacing(0, 1),
    },
  }),
);
