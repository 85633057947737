import { routerActions } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { showToastAction } from '../features/globalSlice';
import { store } from '../store';
import { ApiClient } from './generated/ApiClientGenerated';

const wrapFetch = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
  if (typeof window !== 'undefined') {
    try {
      const response = await window.fetch(url, init);
      if (response.status === 401) {
        const redirectUrl = encodeURIComponent(location.pathname + location.search);
        store.dispatch(routerActions.push(`/login?redirectUrl=${redirectUrl}`));
      }
      return response;
    } catch (e) {
      return e;
    }
  } else {
    throw 'no window object exists';
  }
};

export const apiClient =
  typeof window !== 'undefined' && window?.location && !window?.location?.href?.match('localhost')
    ? new ApiClient(window.location.origin, { fetch: wrapFetch })
    : new ApiClient(process.env.REACT_APP_API_BASE_URL, { fetch: wrapFetch });

/**
 * Helper to call our API from redux-saga and handle errors in one place as necessary
 */
export function* apiCall<Fn extends (this: ApiClient, ...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
): Generator<any> {
  try {
    return yield call<ApiClient, Fn>([apiClient, fn], ...args);
  } catch (e) {
    if (e.status === 403) {
      yield put(
        showToastAction({
          message: 'Permission denied',
          severity: 'warning',
          title: 'Unauthorized',
          body: e.detail,
          subtitle: 'Test',
        }),
      );
      return {};
    } else {
      throw e;
    }
  }
}
