import { createStyles, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Tour, { ReactourStep } from 'reactour';
import { useAppData } from '../../services/hooks';
import { setExpandedRowIdsAction, setIsTourOpenAction } from './detailSlice';

export const Walkthrough: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isTourOpen = useAppData((r) => r.detail.isTourOpen);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const bodyScrollLock = require('body-scroll-lock');
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  const closeTour = () => {
    dispatch(setIsTourOpenAction(false));
  };

  const steps: ReactourStep[] = [
    {
      selector: '[data-tut=reactour__filter-Module]',
      content:
        'You can filter by part numbers or use the sliders and toggles to narrow data according to certain aspects.',
    },
    {
      selector: '[data-tut=reactour__blank-capacity]',
      content: 'To easily see only the parts that have a blank capacity, click this toggle.',
    },
    {
      selector: '[data-tut=reactour__stale-data]',
      content:
        'To see all parts with capacity data that has not been updated in the prior three months, click this toggle.',
    },
    {
      selector: '[data-tut=reactour__sort]',
      content:
        'To sort a column by ascending or descending, click the column name, then click the up or down arrow that appears.',
    },
    {
      selector: '[data-tut=reactour__download-Button]',
      content: 'Click Download to receive a CSV file containing planning information for research purposes only.',
    },
    {
      selector: '[data-tut=reactour__tooltip]',
      content:
        'For columns where the purpose may not be immediately clear, you can hover over the column name to reveal rollover text containing a description.',
    },
    {
      selector: '[data-tut=reactour__inline-Edit]',
      content() {
        return (
          <div>
            <Typography>
              If you enter data into this column, it will override the capacity for just the week specified in the{' '}
              <strong>Due Date</strong> column.
            </Typography>
          </div>
        );
      },
    },
    {
      selector: '[data-tut=reactour__add-Note]',
      content() {
        return (
          <div>
            <Typography>
              To add contextual information for Polaris, click the arrow to the left of the row to reveal the{' '}
              <strong>Add Note</strong> button. Enter your information then click <strong>Save</strong>. (If there is
              already an existing note, click the pencil icon to edit it.)
            </Typography>
          </div>
        );
      },
      action: () => {
        dispatch(setExpandedRowIdsAction([0]));
      },
    },
    {
      selector: '[data-tut=reactour__last-Updated]',
      content: 'Information about the last time the row was updated is displayed here.',
    },
    {
      content:
        'For line items nearing the demand utilization threshold, a visual indicator will appear, turning the line yellow (for approaching threshold) or red (for exceeding threshold).',
      action: () => {
        dispatch(setExpandedRowIdsAction([]));
      },
    },
    {
      selector: '[data-tut=reactour__help-Menu]',
      content:
        'If at any time you require assistance, either with how to use the tool or questions about the data you are entering, use this button to be led to the appropriate help.',
    },
  ];

  return (
    <Tour
      className={classes.tour}
      badgeContent={(curr: number, tot: number) => `${curr} of ${tot}`}
      steps={steps}
      rounded={5}
      isOpen={isTourOpen}
      onRequestClose={closeTour}
      accentColor={'#004e97'}
      prevButton={<ArrowBackIcon aria-label={'go to previous step'} />}
      nextButton={<ArrowForwardIcon aria-label={'go to next step'} />}
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    tour: {
      minWidth: '25.25rem',
    },
  }),
);
