import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Tour, { ReactourStep } from 'reactour';
import { useAppData } from '../../services/hooks';
import { isDashboardTourOpen } from './dashboardSlice';

export const DashboardWalkthrough: FC = () => {
  const dispatch = useDispatch();
  const isTourOpen = useAppData((r) => r.dashboard.isDashboardTourOpen);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const bodyScrollLock = require('body-scroll-lock');
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;

  const closeTour = () => {
    dispatch(isDashboardTourOpen(false));
  };

  const steps: ReactourStep[] = [
    {
      selector: '[data-tut=reactour__initial-step]',
      content:
        'When you first log in, the dashboard tab shows you a summary of the total number of parts and indicates any parts that require your attention.',
    },
    {
      selector: '[data-tut=reactour__dashboardTab]',
      content: '"Dashboard" is bolded to indicate that this is the tab you are currently on.',
    },
    {
      selector: '[data-tut=reactour__partDetailTab]',
      content:
        'Lists all part numbers with rolling demand over the next 12 months. 	Capacity data can be entered directly into this tab or through a file upload process.',
    },
    {
      selector: '[data-tut=reactour__planningDetailTab]',
      content:
        'Shows Polaris weekly demand (this info is pulled from the Polaris planning supplement). Capacity is calculated on this tab based on your capacity input on the Part Detail tab.',
    },
    {
      selector: '[data-tut=reactour__badge]',
      content:
        'An exclamation point in the upper right corner of a metric icon indicates that there is an action for you to perform.',
    },
    {
      selector: '[data-tut=reactour__exceeding-threshold-icon]',
      content: 'Clicking on a metrics icon will open a new page with filters already pre-populated.',
    },
  ];

  return (
    <Tour
      badgeContent={(curr: number, tot: number) => `${curr} of ${tot}`}
      steps={steps}
      rounded={5}
      isOpen={isTourOpen}
      onRequestClose={closeTour}
      accentColor={'#004e97'}
      prevButton={<ArrowBackIcon aria-label={'go to previous step'} />}
      nextButton={<ArrowForwardIcon aria-label={'go to next step'} />}
      onAfterOpen={disableBodyScroll}
      onBeforeClose={enableBodyScroll}
    />
  );
};
