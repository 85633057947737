import { Button, createStyles, CssBaseline, makeStyles, Snackbar, ThemeProvider, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { AppRoutes } from './AppRoutes';
import { appTheme } from './AppTheme';
import { Footer } from './components/global/footer';
import { Header } from './components/global/header';
import { SnackbarDialog as Dialog } from './components/reusable/dialog';
import { LoginPage } from './features/auth/loginPage';
import { hideDialogAction, hideToastAction, showDialogAction } from './features/globalSlice';
import { useAppData } from './services/hooks';

export const App: FC = () => {
  const classes = useStyles();
  const toast = useAppData((r) => r.global.toast);
  const dialog = useAppData((r) => r.global.dialog);
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(showDialogAction());
  };

  const closeDialog = () => {
    dispatch(hideDialogAction());
  };

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <GlobalCss />
      <Snackbar
        open={toast.isVisible}
        onClose={() => dispatch(hideToastAction())}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <Alert
          severity={toast.type}
          action={
            dialog.body ? (
              <>
                <Button onClick={openDialog} color="inherit" size="small">
                  LEARN MORE
                </Button>
                <Dialog
                  title={dialog.title}
                  subtitle={dialog.subtitle}
                  body={dialog.body}
                  open={dialog.isVisible}
                  onClose={closeDialog}
                />
              </>
            ) : null
          }
        >
          {toast.message}
        </Alert>
      </Snackbar>
      <div className={classes.pageContainer}>
        <div className={classes.contentWrap}>
          <Header />
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <AppRoutes />
          </Switch>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

const GlobalCss = withStyles({
  '@global': {
    'html, body, #root': {
      margin: 0,
      padding: 0,
      minHeight: '100vh',
    },
    '@page': {
      size: 'A4 landscape',
    },
  },
})(() => null);

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      maxWidth: '1440px',
      margin: 'auto',
    },
    contentWrap: {
      flex: 1,
    },
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    [`@media print`]: {
      wrapper: {
        display: 'none',
      },
    },
  }),
);
