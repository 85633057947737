import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FileParameter,
  IPartDetail,
  IPartDetailFilterset,
  ListSortDirection,
  PartDetail,
  PartDetailSortOption,
} from '../../services/generated/ApiClientGenerated';
import { Loadable, PaginatableLoadable } from '../../services/shared/baseTypes';
import { Sorting } from '../detail/detailSlice';

export type MitigationMeasures =
  | 'Safety Stock'
  | 'Build Ahead'
  | 'Additional Temporary Labor'
  | 'Increased Leadtime'
  | 'Additional Tooling';

export type KeyConstraints = 'Shared Resources' | 'Labor' | 'Seasonal' | 'Material Shortage';

export type PartDetailDownloadType = {
  supplierId: string;
};

export type PartDetailState = {
  availableFilters: IPartDetailFilterset;
  partDetails: PaginatableLoadable<IPartDetail>;
  expandedRowIds: (string | number)[] | undefined;
  filters: IPartDetailFilterset;
  mitigationMeasures: MitigationMeasures[];
  keyConstraints: KeyConstraints[];
  file: Loadable<FileParameter[]>;
  isPartDetailTourOpen: boolean;
  fileDownload: {
    loading: boolean;
    loaded: boolean;
  };
  pageSizes: number[];
};

export type UpdatePartDetailType = {
  supplierId: string;
  partDetailId: string;
  body: IPartDetail;
};

export const partDetailSlice = createSlice({
  name: 'partDetail',
  initialState: {
    availableFilters: {},
    isPartDetailTourOpen: false,
    partDetails: {
      loading: false,
      loaded: false,
      value: [],
      pageSize: 25,
      totalCount: 0,
    },
    pageSizes: [5, 10, 15, 50, 100],
    expandedRowIds: [],
    file: {
      loading: false,
      loaded: false,
      value: [],
    },
    fileDownload: {
      loading: false,
      loaded: false,
    },
    filters: {
      sortBy: PartDetailSortOption.Undefined,
      sortDirection: ListSortDirection.Ascending,
      pageSize: 10,
      page: 1,
      supplierIds: [],
      partNumber: [],
      mitigationMeasures: [],
      keyConstraints: [],
      minAnnualCapacity: undefined,
      maxAnnualCapacity: undefined,
    },
    mitigationMeasures: [
      'Safety Stock',
      'Build Ahead',
      'Additional Temporary Labor',
      'Increased Leadtime',
      'Additional Tooling',
    ],
    keyConstraints: ['Shared Resources', 'Labor', 'Seasonal', 'Material Shortage'],
  } as PartDetailState,
  reducers: {
    getPartDetailsAction: (state) => {
      state.partDetails.loading = true;
      state.partDetails.loaded = false;
    },
    loadPartDetailFiltersFromUrlAction: () => {},
    successGetPartDetailsAction: (state, action: PayloadAction<PartDetail[]>) => {
      state.partDetails = {
        ...state.partDetails,
        loading: false,
        loaded: true,
        value: action.payload,
      };
    },
    setExpandedPartDetailRowIdsAction: (state, action: PayloadAction<(string | number)[]>) => {
      state.expandedRowIds = action.payload;
    },
    updatePartDetailsAction: (state, action: PayloadAction<UpdatePartDetailType>) => {
      state.partDetails.value = state.partDetails.value.map((row, index) => {
        if (
          row.baseSupplierId === action.payload.body.baseSupplierId &&
          row.partNumber === action.payload.body.partNumber
        ) {
          return action.payload.body;
        } else {
          return row;
        }
      });
    },
    setPartDetailSortAction: (state, action: PayloadAction<Sorting[]>) => {
      if (action.payload.length === 0) {
        state.filters.sortBy = PartDetailSortOption.Undefined;
        state.filters.sortDirection = ListSortDirection.Descending;
      } else {
        const sortOption = action.payload[0].columnName;
        state.filters.sortBy = sortOption.replace(/^./, (m) => m.toUpperCase()) as PartDetailSortOption;
        state.filters.sortDirection =
          action.payload[0].direction === 'asc' ? ListSortDirection.Ascending : ListSortDirection.Descending;
      }
    },
    getAvailablePartDetailFiltersAction: (state) => {},
    successGetPartDetailFiltersAction: (state, action: PayloadAction<IPartDetailFilterset>) => {
      state.availableFilters = action.payload;
    },
    setPartDetailsFiltersAction: (
      state,
      action: PayloadAction<{
        key: keyof IPartDetailFilterset;
        value: IPartDetailFilterset[keyof IPartDetailFilterset];
      }>,
    ) => {
      (state.filters as any)[action.payload.key] = action.payload.value;
    },
    clearPartDetailFiltersAction: (state) => {
      state.filters = {
        sortBy: PartDetailSortOption.Undefined,
        sortDirection: ListSortDirection.Ascending,
        pageSize: 10,
        page: 1,
        supplierIds: state.filters.supplierIds,
        partNumber: [],
        mitigationMeasures: [],
        keyConstraints: [],
        minAnnualCapacity: undefined,
        maxAnnualCapacity: undefined,
      } as IPartDetailFilterset;
    },
    uploadPartDetailCSVAction: (state, action: PayloadAction<FileParameter>) => {
      state.file.loading = true;
      state.file.loaded = false;
    },
    successUploadPartDetailCSVAction: (state) => {
      state.file.loading = false;
      state.file.loaded = true;
    },
    failedUploadPartDetailCSVAction: (state) => {
      state.file.loading = false;
      state.file.loaded = true;
    },
    downloadPartDetailCSVAction: (state, action: PayloadAction<PartDetailDownloadType>) => {
      state.fileDownload.loading = true;
      state.fileDownload.loaded = false;
    },
    successDownloadPartDetailCSVAction: (state) => {
      state.fileDownload.loading = false;
      state.fileDownload.loaded = true;
    },
    setPartDetailPageAction: (state, action: PayloadAction<number>) => {
      state.partDetails.loading = true;
      state.partDetails.loaded = false;
      state.filters.page = action.payload;
    },
    setPartDetailPageSizeAction: (state, action: PayloadAction<number>) => {
      state.partDetails.loading = true;
      state.partDetails.loaded = false;
      state.filters.pageSize = action.payload;
    },
    setPartDetailPaginationSuccessAction: (state, action: PayloadAction<number>) => {
      state.partDetails.totalCount = action.payload;
    },
    isPartDetailTourOpen: (state, action: PayloadAction<boolean>) => {
      state.isPartDetailTourOpen = action.payload;
    },
  },
});

export const {
  clearPartDetailFiltersAction,
  downloadPartDetailCSVAction,
  failedUploadPartDetailCSVAction,
  getAvailablePartDetailFiltersAction,
  getPartDetailsAction,
  setExpandedPartDetailRowIdsAction,
  setPartDetailPageAction,
  setPartDetailPageSizeAction,
  setPartDetailPaginationSuccessAction,
  setPartDetailSortAction,
  setPartDetailsFiltersAction,
  successDownloadPartDetailCSVAction,
  successGetPartDetailFiltersAction,
  successGetPartDetailsAction,
  successUploadPartDetailCSVAction,
  updatePartDetailsAction,
  uploadPartDetailCSVAction,
  isPartDetailTourOpen,
  loadPartDetailFiltersFromUrlAction,
} = partDetailSlice.actions;
