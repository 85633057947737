import { Button, createStyles, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SupplierCapacityHeader } from '../../components/dashboard/supplierCapacityHeader';
import { CircularProgress } from '../../components/reusable/circularProgress';
import { UploadFileButton } from '../../components/reusable/uploadFileButton';
import { FileParameter } from '../../services/generated/ApiClientGenerated';
import { useAppData } from '../../services/hooks';
import { downloadCsvAction, initializeAvailableFiltersAction, uploadCSVAction } from './detailSlice';
import { FilterModule } from './filterModule';
import { SupplierCapacityGrid } from './supplierCapacityGrid';
import { SpeedDialComponent as SpeedDial } from '../../components/reusable/speedDial';
import { Walkthrough } from './walkthrough';

export const DetailPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useAppData((s) => s.user);
  const supplierData = useAppData((r) => r.detail.supplier);
  const filters = useAppData((r) => r.detail.filters);
  const isFileLoading = useAppData((r) => r.detail.file.loading);
  const isDownloadLoading = useAppData((r) => r.detail.fileDownload.loading);
  const header = 'Supplier Demand & Capacity Planning';

  useEffect(() => {
    dispatch(initializeAvailableFiltersAction(user.supplierIds));
  }, []);

  const onClickDownload = () => {
    dispatch(
      downloadCsvAction({
        supplierId: filters.supplierIds![0],
      }),
    );
  };

  const onClickUpload = (file: FileParameter) => {
    dispatch(uploadCSVAction(file));
  };

  return (
    <>
      <Walkthrough />
      <Grid container direction={'column'}>
        <Grid item className={classes.headerButtonContainer}>
          <Grid item className={classes.gridHeader}>
            <SupplierCapacityHeader header={header} />
          </Grid>
          <Grid item className={classes.buttonWrapper}>
            <Grid item className={classes.buttonContainer}>
              <Button
                className={classes.button}
                data-tut={'reactour__download-Button'}
                variant="contained"
                color="primary"
                disabled={(filters.supplierIds?.length ?? 0) == 0}
                onClick={onClickDownload}
              >
                {isDownloadLoading ? <CircularProgress color={'inherit'} size={'1.5rem'} /> : 'Download'}
              </Button>
            </Grid>
            <Grid item>
              <IconButton
                data-tut={'reactour__print-Pdf-Button'}
                aria-label={'print'}
                color={'primary'}
                onClick={() => window.print()}
              >
                <PrintIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction={'row'}>
        <Grid className={classes.filterContainer} item xs={12} md={3} lg={2}>
          <FilterModule />
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          {supplierData.loading ? (
            <Grid container direction={'row'} className={classes.loadingContainer} justifyContent={'center'}>
              <Grid item className={classes.circularProgress}>
                <CircularProgress color={'primary'} />
              </Grid>
              <Grid item>
                <SupplierCapacityGrid supplierData={supplierData.value} />
              </Grid>
            </Grid>
          ) : (
            <SupplierCapacityGrid supplierData={supplierData.value} />
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-end'} className={classes.linkContainer}>
        <SpeedDial className={classes.fab} planningDetail />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      minWidth: '7.188rem',
    },
    buttonContainer: {
      padding: theme.spacing(0, 0.5),
    },
    buttonWrapper: {
      alignItems: 'center',
      display: 'flex',
    },
    headerButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
    },
    linkContainer: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: 'inherit',
    },
    loadingIconContainer: {
      textAlign: 'center',
    },
    circularProgress: {
      zIndex: 9,
      position: 'absolute',
      margin: theme.spacing(12, 0),
      display: 'flex',
    },
    gridHeader: {
      padding: theme.spacing(0),
    },
    filterContainer: {
      marginBottom: theme.spacing(2),
    },
    [`@media print`]: {
      buttonWrapper: {
        display: 'none',
      },
      filterContainer: {
        display: 'none',
      },
    },
  }),
);
