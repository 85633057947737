import { Box, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { RouterLocation } from 'connected-react-router';
import { LocationState } from 'history';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SupplierCapacityHeader } from '../../components/dashboard/supplierCapacityHeader';
import { CircularProgress } from '../../components/reusable/circularProgress';
import { useAppData } from '../../services/hooks';
import { RootState } from '../../store';
import { AutocompleteComponent as Autocomplete } from '../../components/reusable/autocomplete';
import { clearFiltersAction, loadFiltersFromUrlAction } from '../detail/detailSlice';
import { DashboardWalkthrough as Walkthrough } from './walkthrough';
import { ActivityFeed } from './activityFeed';
import { DashboardInfo, getDashboardDataAction } from './dashboardSlice';
import { Metrics } from './metrics';
import { SpeedDialComponent as SpeedDial } from '../../components/reusable/speedDial';

export const DashboardPage: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = useAppData((r) => (r.user.roles.length >= 1 ? r.user.roles[0] : undefined));
  const locationState: RouterLocation<LocationState> = useAppData((r: RootState) => r.router.location);
  const dashboardData = useAppData<DashboardInfo>((r) => r.dashboard.dashboardData);
  const loading = useAppData((r) => r.dashboard.dashboardData.loading);
  const supplierId = useAppData((r) =>
    r.detail.filters.supplierIds.length >= 1 ? r.detail.filters.supplierIds[0] : undefined,
  );
  const supplierData = useAppData((r) => r.detail.supplier);
  const filters = useAppData((r) => r.detail.filters);
  const params = new URLSearchParams(locationState.search);
  const header = 'Supplier Demand & Capacity Planning';

  useEffect(() => {
    dispatch(getDashboardDataAction(supplierId));
  }, [filters]);

  // clear filters to ensure Activity Feed displays 10 most recently updated items without filter
  useEffect(() => {
    dispatch(clearFiltersAction());
  }, []);

  useEffect(() => {
    dispatch(loadFiltersFromUrlAction());
  }, [params.entries]);

  return (
    <>
      <Walkthrough />
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item>
          <Box className={classes.link}>
            <SupplierCapacityHeader header={header} />
          </Box>
        </Grid>
        <Grid item xs={3} className={classes.supplierSelectContainer}>
          <Autocomplete role={role} supplierId={filters.supplierIds ? filters.supplierIds[0] : undefined} />
        </Grid>
      </Grid>

      <Grid container direction={'row'} spacing={2} justifyContent={'center'}>
        {dashboardData.numberOfItemsNeedingCapacityLoaded === 0 && (
          <Grid item xs={12}>
            <Paper className={classes.moduleContainer}>
              <Typography className={classes.moduleHeader} variant="h6" component="h2" align={'center'}>
                Congratulations!
              </Typography>
              <Box>
                Congratulations! You’ve entered capacity for all your parts. We thank you for providing this valuable
                information to the Polaris team.
              </Box>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.moduleContainer}>
            <Metrics dashboardData={dashboardData} loading={loading} supplierId={supplierId} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.moduleContainer}>
            {supplierData.loading ? (
              <Grid item className={classes.loadingContainer}>
                <Box className={classes.circularProgress}>
                  <CircularProgress color={'primary'} />
                </Box>
                <Box>
                  <ActivityFeed supplierData={supplierData.value} supplierId={supplierId} />
                </Box>
              </Grid>
            ) : (
              <ActivityFeed supplierData={supplierData.value} supplierId={supplierId} />
            )}
          </Paper>
        </Grid>
      </Grid>

      <Grid container justifyContent={'flex-end'} className={classes.linkContainer}>
        <SpeedDial className={classes.fab} dashboard />
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
      '& .MuiLink-root:hover': {
        textDecoration: 'none',
      },
    },
    moduleHeader: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      fontFamily: ['Barlow Semi Condensed', 'sans-serif'].join(','),
      backgroundColor: theme.palette.primary.light,
      borderRadius: '0.5rem 0.5rem 0 0',
      marginBottom: theme.spacing(2),
    },
    circularProgress: {
      zIndex: 9,
      position: 'absolute',
      margin: theme.spacing(12, 0),
      display: 'flex',
    },
    header: {
      margin: theme.spacing(4, 0),
    },
    loadingContainer: {
      width: 'inherit',
      display: 'flex',
      justifyContent: 'center',
    },
    link: {
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
    },
    linkContainer: {
      marginTop: theme.spacing(1),
    },
    moduleContainer: {
      padding: theme.spacing(1, 2),
    },
    supplierSelectContainer: {
      margin: theme.spacing(1, 0),
    },
  }),
);
