import { Button, Container, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import * as React from 'react';
import { useLocation } from 'react-router';

export const LoginPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const redirectUrl = encodeURIComponent(new URLSearchParams(location.search)?.get('redirectUrl') ?? '');

  return (
    <Container maxWidth={'xs'}>
      <Paper className={classes.paper}>
        <Grid container direction={'column'} spacing={4}>
          <Grid item>
            <Typography variant={'h4'} component={'h1'} className={classes.heading}>
              Please login
            </Typography>
          </Grid>
          <Grid item>
            <Button
              aria-label={'Supplier Login'}
              variant={'contained'}
              color={'primary'}
              fullWidth
              href={`/auth/covisint?redirectUrl=${redirectUrl}`}
            >
              Supplier Login
            </Button>
          </Grid>
          <Grid item>
            <Button
              aria-label={'Polaris Login'}
              variant={'contained'}
              color={'secondary'}
              fullWidth
              href={`/auth/azure?redirectUrl=${redirectUrl}`}
            >
              Polaris Login
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      textAlign: 'center',
    },
    paper: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(5),
    },
  }),
);
