import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { DataTypeProvider, DataTypeProviderProps } from '@devexpress/dx-react-grid';

const MitigationFormatter: FC<DataTypeProvider.ValueFormatterProps> = ({ value, row }) => {
  return <Typography>{row.mitigationMeasures.length > 0 ? 'YES' : 'NO'} </Typography>;
};
export const MitigationTypeProvider: FC<DataTypeProviderProps> = (props) => (
  <DataTypeProvider formatterComponent={MitigationFormatter} {...props} />
);
