import { Box, createStyles, Grid, lighten, Link, LinkProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink, matchPath } from 'react-router-dom';
import { logoutAction } from '../../features/users/userSlice';

export const Nav: FC = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logoutAction());
  };

  interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
  }

  const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

  const activePage = (pathname: string, pageName: string) => {
    if (matchPath(pathname, pageName)?.isExact) {
      return classes.activeBreadcrumb;
    }
    return;
  };

  return (
    <div className={classes.wrapper}>
      <Grid container justifyContent="space-between">
        <Grid container item xs={12} justifyContent="space-between">
          <Grid container item xs={9} className={classes.navLeft} alignItems="center">
            <Box
              data-tut="reactour__dashboardTab"
              className={clsx(classes.tabWrapper, {
                [classes.activeTabWrapper]: activePage(pathname, '/dashboard'),
              })}
            >
              <LinkRouter className={activePage(pathname, '/dashboard')} color="inherit" to={`/dashboard/`}>
                Dashboard
              </LinkRouter>
            </Box>
            <Box
              data-tut="reactour__partDetailTab"
              className={clsx(classes.tabWrapper, {
                [classes.activeTabWrapper]: activePage(pathname, '/part-details'),
              })}
            >
              <LinkRouter className={activePage(pathname, '/part-details')} color="inherit" to={`/part-details/`}>
                Part Detail
              </LinkRouter>
            </Box>
            <Box
              data-tut="reactour__planningDetailTab"
              className={clsx(classes.tabWrapper, {
                [classes.activeTabWrapper]: activePage(pathname, '/planning-detail'),
              })}
            >
              <LinkRouter className={activePage(pathname, '/planning-detail')} color="inherit" to={`/planning-detail/`}>
                Planning Detail
              </LinkRouter>
            </Box>
          </Grid>
          <Grid xs={3} className={classes.navLeft} item container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Link className={classes.logoutText} onClick={onLogout} data-tut={'reactour__logout'}>
                Logout
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      padding: theme.spacing(0, 4),
    },
    [`@media print`]: {
      wrapper: {
        display: 'none',
      },
    },
    tabWrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
    },
    activeTabWrapper: {
      backgroundColor: lighten(theme.palette.primary.main, 0.25),
    },
    activeBreadcrumb: {
      fontWeight: theme.typography.fontWeightBold,
    },
    navLeft: {
      minHeight: '2.25rem',
      margin: theme.spacing(0, 0),
      '& .MuiTypography-colorPrimary': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    navRight: {
      textAlign: 'right',
      paddingRight: '4rem',
    },
    linksContainer: {
      display: 'flex',
      justifySelf: 'flex-end',
      marginBottom: theme.spacing(3),
    },
    logoutText: {
      justifySelf: 'flex-end',
      fontWeight: 'bold',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);
